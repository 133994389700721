import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Input,
  Col,
  Label,
  Form,
  FormGroup,
  Container,
  Row,
  Card,
  CardTitle,
  CardText,
  Nav,
  CardImg,
  CardBody,
  CardHeader,
  CardColumns,
  CardGroup,
  CardSubtitle,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import Axios from 'axios'
import endpoint from '../../../config/config-api'
import { Gallery } from 'react-grid-gallery'

import Swal from 'sweetalert2'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
const axios = Axios.create({ withCredentials: true })
const ModalGallery = (props) => {
  const [images, setImages] = useState(props.imageGallery)
  const hasSelected = images.some((image) => image.isSelected)

  const [index, setIndex] = useState(-1)

  const currentImage = images[index]
  const nextIndex = (index + 1) % images.length
  const nextImage = images[nextIndex] || currentImage
  const prevIndex = (index + images.length - 1) % images.length
  const prevImage = images[prevIndex] || currentImage

  const handleClick = (index, item) => setIndex(index)
  const handleClose = () => setIndex(-1)
  const handleMovePrev = () => setIndex(prevIndex)
  const handleMoveNext = () => setIndex(nextIndex)

  const handleSelect = (index) => {
    const nextImages = images.map((image, i) =>
      i === index ? { ...image, isSelected: !image.isSelected } : image,
    )
    setImages(nextImages)
  }

  const fecthData = async () => {
    const response = await axios.get(
      `${endpoint.apiTest}/v1.1/job/cms/internship/get`,
    )
    let arr = []
    if (response.status === 200) {
      const resData = response.data.data

      resData.map((image) => {
        arr.push({
          src: image.url,
          width: image.width,
          height: image.height,
          id: image._id,
        })
      })

      setImages(arr)
    }
  }

  const handleSelectAllClick = () => {
    const nextImages = images.map((image) => ({
      ...image,
      isSelected: !hasSelected,
    }))
    setImages(nextImages)
  }

  const handleDelete = () => {
 

    openWarning()
  }
  const deleteData = async (id) => {
    const response = await axios.delete(
      `${endpoint.apiTest}/v1.1/job/cms/internship/delete/${id}`,
    )
    console.log(response)
    if (response.status === 200) {
      fecthData()
      props.fecthData()
    }
  }

  const openWarning = (id) => {
    Swal.fire({
      title: '<strong><p>ยืนยันการลบ</p></strong>',
      icon: 'warning',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Submit',
      confirmButtonColor: '#1cc88a',
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        images.map((image) => {
          if (image.isSelected) {
            deleteData(image.id)
          }
        })
      }
    })
  }
  return (
    <div>
      {!!currentImage && (
        /* @ts-ignore */

        <Lightbox
          reactModalStyle={{ overlay: { zIndex: 9999 } }}
          mainSrc={currentImage.src}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.src}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.src}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
      <div className="p-t-1 p-b-1">
        <button onClick={handleSelectAllClick}>
          {hasSelected ? 'Clear selection' : 'Select all'}
        </button>

        {hasSelected && <button onClick={handleDelete}>Delete selected</button>}
      </div>
      <Gallery images={images} onClick={handleClick} onSelect={handleSelect} />
    </div>
  )
}
export default ModalGallery
