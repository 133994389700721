import { lazyLoad } from '@react-page/editor';
import createPlugin from './createPlugin';
import ImageHtmlRenderer from './Renderer/ImageHtmlRenderer';

const ImageControls = lazyLoad(() => import('./reactPage/imageControls'));
const imagePlugin = (settings) =>
  createPlugin({
    Renderer: ImageHtmlRenderer,
    Controls: ImageControls,
    ...settings,
  });
const image = imagePlugin();
export default image;
export { imagePlugin };