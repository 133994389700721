import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Input,
  Col,
  Label,
  Form,
  FormGroup,
  Container,
  Row,
  Card,
  CardTitle,
  CardText,
  Nav,
  CardImg,
  CardBody,
  CardHeader,
  CardColumns,
  CardGroup,
  CardSubtitle,
} from 'reactstrap'
import Axios from 'axios'
import endpoint from '../../../config/config-api'
import Swal from 'sweetalert2'

const ReactBsTable = require('react-bootstrap-table')
const BootstrapTable = ReactBsTable.BootstrapTable
const TableHeaderColumn = ReactBsTable.TableHeaderColumn
const axios = Axios.create({ withCredentials: true })

const TestimonialSection = ({ props }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    fecthData()
  }, [])

  const fecthData = async () => {
    const response = await axios.get(
      `${endpoint.apiTest}/v1.1/job/cms/testimonial/get`,
    )
    if (response.status === 200) {
      const resData = response.data.data
      setData(resData)
    }
  }

  const rowindex = (cell, row, enumObject, index) => {
    return <i>{index + 1}</i>
  }

  const descriptionFormatter = (cell, row) => {
    return (
      <div>
        <CardText dangerouslySetInnerHTML={{__html: row.description}} />
      </div>
    )
  }
  const titleFormatter = (cell, row) => {
    return (
      <div>
        <CardText>{row.title}</CardText>
      </div>
    )
  }

  const editFormatter = (cell, row) => {
    return (
      <a
        onClick={() =>
          props.history.push(`/EditHomePage/TestimonialForm/${row._id}`)
        }
        style={{ color: '#4e73df', cursor: 'pointer' }}
      >
        แก้ไข
      </a>
    )
  }

  const imageFormatter = (cell, row) => {
    return <img alt="pano" src={ process.env.REACT_APP_WEB_MEDIA_PATH +  row.imageSrc} width="auto" height="100px" />
  }

  const deletebutton = (cell) => {
    return (
      <Button
        onClick={() => openWarning(cell)}
        className="btn btn-success btn-circle btn-sm"
      >
        <i className="fas fa-user-times"></i>
      </Button>
    )
  }


  const deleteData = async (id) => {
    const result = await axios.delete(
      `${endpoint.apiTest}/v1.1/job/cms/testimonial/delete/${id}`,
    )
    if (result.status === 200) {
      fecthData()
    }
  }


  const openWarning = (id) => {
    Swal.fire({
      title: '<strong><p>ยืนยันการลบ</p></strong>',
      icon: 'warning',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Submit',
      confirmButtonColor: '#1cc88a',
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData(id)
      }
    })
  }

  const options = {
    page: 1, // which page you want to show as default
    sizePerPage: 5, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    sizePerPageList: [], // show dropDowsn select
    prePage: 'Prev', // Previous page button text
    nextPage: 'Next', // Next page button text
 }

 
  return (
    <Row>
      <div className="card-body">
        <Card>
          <CardHeader>Testimonial</CardHeader>
          <Nav>
            <div className="mb-6" style={{ margin: '20px' }}>
              <div className="  py-3">
                <Button
                  onClick={() =>
                    props.history.push('/EditHomePage/TestimonialForm')
                  }
                  // href={'/panorama/form'}
                  color="primary"
                  className="custom-rigth btn btn-primary btn-sm"
                >
                  เพิ่มข้อมูลส่วน Testimonial
                </Button>
              </div>
            </div>
          </Nav>
          <CardBody>
            <BootstrapTable
              data={data}
              pagination={true}
              options={options}
              className="table table-striped"
            >
              <TableHeaderColumn
                dataField="_id"
                dataFormat={rowindex}
                width="20"
                isKey
              >
                Index
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="imageSrc"
                dataFormat={imageFormatter}
                width="50"
              >
                Image
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="title"
                dataFormat={titleFormatter}
                width="100"
              >
                ชื่อ - ตำแหน่ง
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="title"
                dataFormat={descriptionFormatter}
                width="150"
              >
                บทสัมภาษณ์
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="_id"
                width="50"
                dataFormat={editFormatter}
              >
                แก้ไข
              </TableHeaderColumn>

              <TableHeaderColumn
                dataField="_id"
                width="50"
                dataFormat={deletebutton}
              >
                Delete
              </TableHeaderColumn>
            </BootstrapTable>
          </CardBody>
        </Card>
      </div>
    </Row>
  )
}
export default TestimonialSection
