
import React, { Fragment } from "react"

export const FileList = ({ files, onDelete }) => {
	return (
		<Fragment>
			<ul >
            {files.map((list) => (
              <div  key={list._id} className='row'>
                <li className='file-list' onClick={() =>{ window.open(list.path, '_blank')  }}>{list.filename}</li>
                <span className='delete-file' onClick={() => onDelete(list._id)} >x</span>
              </div>
             
            ))}
          </ul>
		</Fragment>
	)
}
