import React, { useRef, useEffect, useState } from 'react'
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import {
  Alert,
  Button,
  Input,
  Col,
  Label,
  Form,
  FormGroup,
  Container,
  Row,
} from 'reactstrap'
import endpoint from '../../config/config-api'
import Axios from 'axios'
import Swal from 'sweetalert2'
const axios = Axios.create({ withCredentials: true })
const FormPanorama = (props) => {
  const [data, setData] = useState({
    title: '',
    description: '',
    imageSrc: undefined,
    file: undefined,
    active: false,
    typeFile: 'video',
  })

  const [validate, setValidate] = useState(undefined)
  const [id] = useState(props.match.params.id)

  useEffect(() => {
    fetchData()
  }, [id])

  const fetchData = async () => {
    if (id) {
      const response = await axios.get(
        `${endpoint.apiTest}/v1.1/job/cms/panorama/${id}`,
      )
      if (response.status === 200) {
        const newData = response.data.data
        setData(newData)
      }
    } else {
      setData(data)
    }
  }

  const setValue = (name) => (event) => {
    switch (name) {
      case 'title':
        setData({
          ...data,
          title: event.target.value,
        })

        break

      case 'description':
        setData({
          ...data,
          description: event.target.value,
        })

        break

      case 'typeFile':
        setData({
          ...data,
          typeFile: event.target.value,
        })

        break

      case 'file':
        console.log(event.target.files[0])

        let { type, size } =
          event.target.files[0] !== undefined && event.target.files[0]
        if (['video/mp4'].includes(type)) {
          if (data.typeFile === 'video' || event.target.value === 'video') {
            data.typeFile = 'video'
            setData({
              ...data,
              imageSrc: event.target.files[0].name,
              file: event.target.files[0],
            })
            setValidate({
              ...validate,
              file: '',
            })
          } else {
            setValidate({
              ...validate,
              file: 'กรุณาแนบไฟล์ให้ตรงกัน',
            })
          }
        } else if (['image/jpg', 'image/jpeg', 'image/png'].includes(type)) {
          if (data.typeFile === 'image' || event.target.value === 'image') {
            data.typeFile = 'image'
            setData({
              ...data,
              imageSrc: event.target.files[0].name,
              file: event.target.files[0],
            })
            setValidate({
              ...validate,
              file: '',
            })
          } else {
            setValidate({
              ...validate,
              file: 'กรุณาแนบไฟล์ให้ตรงกัน',
            })
          }
        } else {
          setValidate({
            ...validate,
            file: 'กรุณาแนบไฟล์เป็น .jpg .jpeg .png และ .mp4   เท่านั้น',
          })
        }
        break

      default:
        break
    }
  }

  const onCheckValidate = (req) => {
    if (!req.imageSrc) {
      setValidate({
        file: !req.imageSrc && 'กรุณาเลือก',
      })
      return true
    } else {
      setValidate(undefined)
      return false
    }
  }

  const handleSubmit = async () => {
    const result = onCheckValidate(data)

    if (!result) {
      const formData = new FormData()
      const arr = [
        'title',
        'description',
        'active',
        'imageSrc',
        'file',
        'typeFile',
      ]

      for (let value of arr) {
        formData.append(value, data[value])
      }

      if (id) {
        const result = await axios.put(
          `${endpoint.apiTest}/v1.1/job/cms/panorama/${id}?upload=panorama`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )

        if (result.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'บันทึกข้อมูลสำเร็จ',
          }).then((result) => {
            if (result.isConfirmed) {
              props.history.push('/panorama')
            }
          })
        }
      } else {
        const result = await axios.post(
          `${endpoint.apiTest}/v1.1/job/cms/panorama?upload=panorama`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )

        if (result.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'บันทึกข้อมูลสำเร็จ',
          }).then((result) => {
            if (result.isConfirmed) {
              props.history.push('/panorama')
            }
          })
        }
      }
    }
  }

  return (
    <Container>
      <Form noValidate onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="exampleAddress">Title</Label>
              <Input
                type="text"
                name="title"
                id="title"
                className={
                  validate && validate.title
                    ? 'is-invalid form-control'
                    : 'form-control'
                }
                autoComplete="off"
                value={data.title}
                onChange={setValue('title')}
              />
              {validate && validate.title && (
                <Alert color="danger">{validate.title}</Alert>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="exampleAddress">Description</Label>
              <Input
                type="textarea"
                name="description"
                id="description"
                className={
                  validate && validate.description
                    ? 'is-invalid form-control'
                    : 'form-control'
                }
                value={data.description}
                onChange={setValue('description')}
              />
              {validate && validate.description && (
                <Alert color="danger">{validate.description}</Alert>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="exampleAddress">ชนิด สื่อพาโนรามา</Label>
              <Input
                type="select"
                name="typeFile"
                id="typeFile"
                className={
                  validate && validate.typeFile
                    ? 'is-invalid form-control'
                    : 'form-control'
                }
                value={data.typeFile}
                onChange={setValue('typeFile')}
              >
                <option value={'video'}>วีดีโอ</option>
                <option value={'image'}>ภาพ</option>
              </Input>

              {validate && validate.typeFile && (
                <Alert color="danger">{validate.typeFile}</Alert>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={8}>
            <FormGroup>
              <Label for="exampleAddress">
                Filename: {data ? data.imageSrc : `Example file input`}
              </Label>
              <Input
                type="file"
                name="image"
                id="image"
                accept="image/jpg, image/jpeg, image/png, video/mp4"
                onChange={setValue('file')}
                style={{ cursor: 'pointer' }}
              />
              {validate && validate.file && (
                <Alert color="danger">{validate.file}</Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        {data.typeFile === 'video' ? (
          <p>ไฟล์วีดีโอ ขนาดไม่เกิน 5 mb นามสกุลไฟล์ .mp4 </p>
        ) : (
          <p>รูปพาโน ขนาด 1400 x 600 px นามสกุลไฟล์ .jpg, .png </p>
        )}
        <Row>
          <Col xs="6" sm="4">
            <FormGroup tag="fieldset">
              Active
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="active"
                    checked={data.active}
                    onChange={() => setData({ ...data, active: true })}
                  />
                  เปิด
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="active"
                    checked={!data.active}
                    onChange={() => setData({ ...data, active: false })}
                  />
                  ปิด
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>
        <div></div>
        <Row>
          <div className="btn">
            <Button onClick={() => handleSubmit()}>บันทึก</Button>
          </div>
          {console.log(props.history)}
          <div className="btn">
            <Button onClick={() => props.history.push('/panorama')}>
              ย้อนกลับ
            </Button>
          </div>
        </Row>
      </Form>
    </Container>
  )
}

export default FormPanorama
