export const interview = (data) => {
    return ` <html lang="en">
    <head>
            <!-- Required meta tags -->
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        </head>
    <body style="margin: 0; padding:0;">
            <div style="background: #f2f2f2; height: auto;">
                <table style="max-width: 550px; margin: auto;border-collapse: collapse; background: #ffffff;">
                    <tbody>
                        <tr>
                            <td style="padding: 20px; padding-bottom: 0;">
                                <h2 style="margin: 0; color: #00B400; font-size: 18px; font-weight:bold;font-family: Arial, Helvetica, sans-serif;border-bottom: 1px solid #D1D1D1; padding-bottom: 10px; margin-bottom: 10px;">Thairath Group :: เรียนเชิญสัมภาษณ์งานและขอแจ้งรายละเอียดการนัดหมายสัมภาษณ์ </h2>
                                <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">เรียนคุณ <i style="color:#00B400; font-style: inherit;">${data.name}</i></p>
                                <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">Thairath Group ขอเรียนเชิญสัมภาษณ์กับทางบริษัท รายละเอียดการสัมภาษณ์ ดังนี้</p>
                                <br/>
                                <p style="font-size: 14px; font-weight:bold;font-family: Arial, Helvetica, sans-serif;border-bottom: 1px solid #D1D1D1; padding-bottom: 13px;margin: 0; margin-bottom: 10px;"><strong>กำหนดการสัมภาษณ์งาน</strong></p>
    
                                <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">วันที่นัดสัมภาษณ์ : ${data.dateToSend}</p>
                                <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">ตำแหน่ง : ${data.position}</p>
                                <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">สถานที่ : สำนักงานใหญ่ไทยรัฐ อาคาร 12 ชั้น 5 เลขที่ 1 ถนนวิภาวดี-รังสิต แขวงจอมพล เขตจตุจักร กรุงเทพมหานคร 10900 <a href="https://www.google.co.th/maps/place/%E0%B8%AB%E0%B8%99%E0%B8%B1%E0%B8%87%E0%B8%AA%E0%B8%B7%E0%B8%AD%E0%B8%9E%E0%B8%B4%E0%B8%A1%E0%B8%9E%E0%B9%8C%E0%B9%84%E0%B8%97%E0%B8%A2%E0%B8%A3%E0%B8%B1%E0%B8%90+(%E0%B8%9A%E0%B8%A3%E0%B8%B4%E0%B8%A9%E0%B8%B1%E0%B8%97+%E0%B8%A7%E0%B8%B1%E0%B8%8A%E0%B8%A3%E0%B8%9E%E0%B8%A5+%E0%B8%88%E0%B8%B3%E0%B8%81%E0%B8%B1%E0%B8%94)/@13.8047918,100.5578341,18z/data=!3m1!4b1!4m8!1m2!2m1!1z4LmE4LiX4Lii4Lij4Lix4LiQ!3m4!1s0x0:0x4d68cb99c0a15e91!8m2!3d13.8047893!4d100.5588973">Click to Google Map</a></p>
                                <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">ติดต่อ : 02-127-1000 ต่อ 1073 (คุณอรรถวัชร์)</p>
                                <br/>
                                <br/>
    
    <p style="font-size: 14px; font-weight:bold;font-family: Arial, Helvetica, sans-serif;border-bottom: 1px solid #D1D1D1; padding-bottom: 13px;margin: 0; margin-bottom: 10px;"><strong>เอกสารที่ต้องเตรียมมาในวันที่สัมภาษณ์ </strong></p>
    
                                <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">1. Resume</p>
                                <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">2. สำเนาทะเบียนบ้าน 1 ฉบับ </p>
                                <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">3. สำเนาบัตรประชาชน 1 ฉบับ </p>
                                <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">4. สำเนาวุฒิการศึกษา 1 ชุด </p>
                                <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">5. Portfolio (ถ้ามี)</p>
                                <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">6. Certificate (ถ้ามี)</p>
                                <br/>
                                <br/>
    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;"> หากต้องการข้อมูลเพิ่มเติม สามารถติดต่อสอบถามได้ที่เบอร์ 02-127-1000 ต่อ 1073
    </p>
    
    <br/>                            
    
    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">ขอแสดงความนับถือ </p>
    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">ฝ่ายทรัพยากรบุคคล </p>
    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">Thairath Group</p>
    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">02-127-1000 ต่อ 1073</p>
    <br/>        <br/>       
                            </td>
                        </tr>
    
                        <tr>
                            <td style="background: #00B400; padding: 10px;vertical-align: middle;">
                                <a href="https://www.thairath.co.th/home" style="display: inline-block; margin-right: 10px;vertical-align: middle;"><img style="max-width: 100%;" src="https://www.thairath.co.th/assets/member/tr-logo.png"/></a>
                                <a href="http://www.facebook.com/Thairath" style="display: inline-block; margin-right: 10px;text-decoration: none;vertical-align: middle;"><img src="https://www.thairath.co.th/assets/member/facebook-icon.png"/></a>
                                <a href="http://www.twitter.com/Thairath_News" style="display: inline-block; margin-right: 10px;text-decoration: none;vertical-align: middle;"><img src="https://www.thairath.co.th/assets/member/twitter-icon.png"/></a>
                                <a href="https://www.youtube.com/user/thairathonline" style="display: inline-block; margin-right: 10px;text-decoration: none;vertical-align: middle;"><img src="https://www.thairath.co.th/assets/member/youtube-icon.png"/></a>
                                <a href="https://www.thairath.co.th/faq/" style="display: inline-block; margin-right: 10px;text-decoration: none;vertical-align: middle; font-size: 12px; font-family: Arial, Helvetica, sans-serif; color: white;">FAQ</a>
                                <a href="https://www.thairath.co.th/assets/thairath-privacy-policy-2018-02.pdf" style="display: inline-block; margin-right: 10px;text-decoration: none;vertical-align: middle; font-size: 12px; font-family: Arial, Helvetica, sans-serif; color: white;">นโยบายส่วนตัว</a>
                                <a href="https://www.thairath.co.th/assets/thairath-terms-condition-2018-02.pdf" style="display: inline-block; text-decoration: none;vertical-align: middle; font-size: 12px; font-family: Arial, Helvetica, sans-serif; color: white;">เงื่อนไขข้อตกลง</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
    </body>
    </html>`
} 