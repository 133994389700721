import React, { useState, useEffect, Children } from 'react'
import { useParams } from 'react-router-dom'
import Axios from 'axios'
import { Button, Input, Row } from 'reactstrap'
import endpoint from '../../../config/config-api'
import Modal from 'react-modal'
import DateTimePicker from 'react-datetime-picker'
import globalFunction from '../globalFunction'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'
import { Editor } from 'react-draft-wysiwyg'
import Swal from 'sweetalert2'
import { contract, interview, reject } from '../../../helper/formEmail'
import draftToHtml from 'draftjs-to-html'
import { ReactMultiEmail } from 'react-multi-email'
import { familyStatus, headerEmail } from '../../../helper'
import { Fragment } from 'react'

const axios = Axios.create({ withCredentials: true })

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 500,
    border: '5px solid #000',
    padding: '30px',
  },
  overlay: { background: 'rgba(0,0,0, 0.75)' },
}

const Profile = () => {
  let { id } = useParams()
  var subtitle
  const [dateValue, onChangeDate] = useState(new Date())
  const [image, setImage] = useState('')
  const [nameAndLastName, setStateNameAndLastName] = useState('-')
  const [position, setStatePosition] = useState()
  const [salary, setStateSalary] = useState('-')
  const [startDate, setStateStartDate] = useState('-')
  const [nickname, setStateNickname] = useState('-')
  const [gender, setStateGender] = useState('-')
  const [age, setStateAge] = useState('-')
  const [nationality, setStateNationality] = useState('-')
  const [religion, setStateReligion] = useState('-')
  const [height, setStateHeight] = useState('-')
  const [weight, setStateWeight] = useState('-')
  const [birthdate, setStateBirthdate] = useState('-')
  const [placeOfBirth, setStatePlaceOfBirth] = useState('-')
  const [scar, setStateScare] = useState('-')
  const [bloodType, setStateBloodType] = useState('-')
  const [numberIdCard, setStateNumberIdCard] = useState('-')
  const [PlaceOfIdCard, setStatePlaceOfIdCard] = useState('-')
  const [dateOfIdCard, setStateDateOfIdCard] = useState('-')
  const [expireIdCard, setStateExpireIdCard] = useState('loading..')
  const [nowAddress, setStateNowAddress] = useState('-')
  const [phone, setStatePhone] = useState('-')
  const [oldAddress, setStateOldAddress] = useState('-')
  const [homePhone, setStateHomePhone] = useState('-')
  const [email, setStateEmail] = useState('-')

  const [fatherName, setStateFatherName] = useState('-')
  const [fatherJob, setStateFatherJob] = useState('-')
  const [fatherStatus, setStateFatherStatus] = useState('-')

  const [motherName, setStateMotherName] = useState('-')
  const [motherJob, setStateMotherJob] = useState('-')
  const [motherStatus, setStateMotherStatus] = useState('-')
  const [status, setStateStatus] = useState('-')

  const [girlfriendName, setStateGirlfriendName] = useState('-')
  const [girlfriendLastName, setStateGirlfriendLastName] = useState('-')
  const [girlfriendJob, setStateGirlfriendJob] = useState('-')
  const [girlfriendWork, setStateGirlfriendWork] = useState('-')

  const [kiddata, setKiddata] = useState([])
  const [military, setStateMilitary] = useState('-')
  const [userId, setuserId] = useState('NO')
  const [modalIsOpen, setIsOpen] = useState(false)
  const [printStatus, setPrintStatus] = useState('notPrint')
  const [token, setToken] = useState()
  const [kidStatus, setKidStatus] = useState(false)
  const [emailType, setType] = useState()

  const [editerText, setEditerText] = useState(EditorState.createEmpty())
  const [emails, setEmails] = useState([])

  const [headerMail, setHeaderMail] = useState('')
  const [contactEmail, setContactEmail] = useState([])
  const [toMail, setToMail] = useState()

  useEffect(() => {
    fetchData()
  }, [])

  const closeModal = () => {
    setIsOpen(false)
    setuserId('')
    setToMail('')
  }

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#000'
  }

  const openModal = (type) => {
    const dataProfile = {
      name: nameAndLastName,
      position: position,
      url:
        type === 'contract'
          ? `${endpoint.webUrl}/job/application?token=${token}`
          : `${endpoint.webUrl}/job/recruit`,
      dateToSend: `xx/xx/xxxx xx:xx`,
    }

    const contentBlockhtmljobdetail = htmlToDraft(
      responseEmailType(type, dataProfile),
    )

    const contentStatedetail = ContentState.createFromBlockArray(
      contentBlockhtmljobdetail.contentBlocks,
    )

    const editorStatedetail = EditorState.createWithContent(contentStatedetail)

    setEditerText(editorStatedetail)
    setIsOpen(true)
    setHeaderMail(headerEmail(type))
    setType(type)
    setuserId(id)
  }

  const responseEmailType = (type, dataProfile) => {
    switch (type) {
      case 'interview':
        return interview(dataProfile)
      case 'contract':
        return contract(dataProfile)
      case 'reject':
        return reject(dataProfile)
      default:
        break
    }
  }

  const openWaringSendMail = () => {
    Swal.fire({
      title: '<strong><p>ยืนยันการส่งเมล</p></strong>',
      icon: 'warning',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Send',
      confirmButtonColor: '#1cc88a',
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        sendEmail()
      }
    })
  }

  const sendEmail = async () => {
    const {
      data: { candidateData },
    } = await axios.get(`${endpoint.apiTest}/v1.1/job/cms/candidate/${id}`)

    if (emailType === 'interview') {
      const newObj = {
        _id: id,
        token: token,
        name: nameAndLastName,
        email: email,
        position: candidateData.position.position1.name,
        formEmail: draftToHtml(convertToRaw(editerText.getCurrentContent())),
        sendEmail: emails,
        headerMail: headerMail,
        toMail: toMail
      }

      const response = await axios.post(
        `${endpoint.apiTest}/v1.1/job/cms/candidate/sendInterViewCandidate`,
        newObj,
      )
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          title: response.data.message,
        })
        setIsOpen(false)
        setuserId('')
        setToMail('')
      } else {
        Swal.fire({
          icon: 'error',
          title: response.data.message,
        })
      }
    } else if (emailType === 'contract') {
      const newObj = {
        _id: id,
        token: token,
        name: nameAndLastName,
        email: email,
        position: candidateData.position.position1.name,
        formEmail: draftToHtml(convertToRaw(editerText.getCurrentContent())),
        sendEmail: emails,
        headerMail: headerMail,
        toMail: toMail
      }

      const response = await axios.post(
        `${endpoint.apiTest}/v1.1/job/cms/candidate/sendSignContract`,
        newObj,
      )
      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          title: response.data.message,
        })
        setIsOpen(false)
        setuserId('')
        setToMail('')
      } else {
        Swal.fire({
          icon: 'error',
          title: response.data.message,
        })
      }
    } else if (emailType === 'reject') {
      sendEmailReject()
    }
  }

  const sendEmailReject = async () => {
    const {
      data: { candidateData },
    } = await axios.get(`${endpoint.apiTest}/v1.1/job/cms/candidate/${id}`)
    const newObj = {
      _id: id,
      token: token,
      name: nameAndLastName,
      email: email,
      position: candidateData.position.position1.name,
      formEmail: draftToHtml(convertToRaw(editerText.getCurrentContent())),
      sendEmail: emails,
      headerMail: headerMail,
      toMail: toMail
    }

    const response = await axios.post(
      `${endpoint.apiTest}/v1.1/job/cms/candidate/sendRejectCandidate`,
      newObj,
    )
    if (response.data.status === 200) {
      Swal.fire({
        icon: 'success',
        title: response.data.message,
      })
      setIsOpen(false)
      setuserId('')
      setToMail('')
    } else {
      Swal.fire({
        icon: 'error',
        title: response.data.message,
      })
    }
  }

  const fetchData = async () => {
    const {
      data: { candidateData },
    } = await axios.get(`${endpoint.apiTest}/v1.1/job/cms/candidate/${id}`)

    const { data : {  response} } = await axios.get(`${endpoint.apiTest}/v1.1/job/cms/contactMail/all`)
    response.unshift({  contactName: 'เลือกสังกัด' })
    setContactEmail(response)

    setToken(candidateData.key)
    setImage(
      candidateData.information.general.image.url
        ? candidateData.information.general.image.url
        : 'https://eitrawmaterials.eu/wp-content/uploads/2016/09/person-icon.png',
    )
    setStateNameAndLastName(
      candidateData.information.general.firstname +
        ' ' +
        candidateData.information.general.lastname,
    )
    setStatePosition(
      candidateData.position.position1
        ? candidateData.position.position1.name
        : '' + candidateData.position.position2
        ? ' , ' + candidateData.position.position2.name
        : '' + candidateData.position.position3
        ? ' , ' + candidateData.position.position3.name
        : '',
    )
    setStateSalary(candidateData.position.salary)
    setStateStartDate(
      ('0' + new Date(candidateData.position.startdate).getDate()).slice(-2) +
        '/' +
        (
          '0' +
          (new Date(candidateData.position.startdate).getMonth() + 1)
        ).slice(-2) +
        '/' +
        (new Date(candidateData.position.startdate).getFullYear() + 543),
    )

    if (!candidateData.information.personal) {
      setStateGender(' - ')
      setStateAge(' - ')
      setStateNationality(' - ')
      setStateReligion(' - ')
      setStateHeight(' - ')
      setStateWeight(' - ')
      setStateBirthdate(' - ')
      setStateNowAddress(' - ')
      setStateStatus(' - ')
    } else {
      setStateGender(
        candidateData.information.personal.sex === 'male' ? 'ชาย' : 'หญิง',
      )
      setStateAge(candidateData.information.personal.age)
      setStateNationality(candidateData.information.personal.nationality)
      setStateReligion(candidateData.information.personal.religion)
      setStateHeight(candidateData.information.personal.height)
      setStateWeight(candidateData.information.personal.weight)
      setStateBirthdate(
        (
          '0' + new Date(candidateData.information.personal.birthdate).getDate()
        ).slice(-2) +
          '/' +
          (
            '0' +
            (new Date(candidateData.information.personal.birthdate).getMonth() +
              1)
          ).slice(-2) +
          '/' +
          (new Date(
            candidateData.information.personal.birthdate,
          ).getFullYear() +
            543),
      )
      setStateNowAddress(
        candidateData.information.personal.address.address +
          ' ' +
          candidateData.information.personal.address.tumbon +
          ' ' +
          candidateData.information.personal.address.amphoe +
          ' ' +
          candidateData.information.personal.address.province +
          ' ' +
          candidateData.information.personal.address.postcode,
      )
      setStateStatus(familyStatus(candidateData.information.personal.status))
    }

    setStateNumberIdCard(
      candidateData.information.cardInfo !== undefined
        ? candidateData.information.cardInfo.cardId
        : ' - ',
    )
    setStatePlaceOfIdCard(
      candidateData.information.cardInfo !== undefined
        ? candidateData.information.cardInfo.cardAddress
        : ' - ',
    )
    setStateDateOfIdCard(
      candidateData.information.cardInfo !== undefined
        ? (
            '0' +
            new Date(candidateData.information.cardInfo.cardStartDate).getDate()
          ).slice(-2) +
            '/' +
            (
              '0' +
              (new Date(
                candidateData.information.cardInfo.cardStartDate,
              ).getMonth() +
                1)
            ).slice(-2) +
            '/' +
            (new Date(
              candidateData.information.cardInfo.cardStartDate,
            ).getFullYear() +
              543)
        : '-',
    )
    setStateExpireIdCard(
      candidateData.information.cardInfo !== undefined
        ? (
            '0' +
            new Date(
              candidateData.information.cardInfo.cardExpireDate,
            ).getDate()
          ).slice(-2) +
            '/' +
            (
              '0' +
              (new Date(
                candidateData.information.cardInfo.cardExpireDate,
              ).getMonth() +
                1)
            ).slice(-2) +
            '/' +
            (new Date(
              candidateData.information.cardInfo.cardExpireDate,
            ).getFullYear() +
              543)
        : '-',
    )

    setStatePhone(candidateData.information.general.phone)
    setStateEmail(candidateData.information.general.email)

    if (candidateData.information.family.father.fatherTitle) {
      setStateFatherName(
        candidateData.information.family.father.fatherTitle +
          ' ' +
          candidateData.information.family.father.fatherName +
          ' ' +
          candidateData.information.family.father.fatherSurname,
      )
      setStateFatherJob(
        candidateData.information.family.father.fatherOccupation,
      )
      setStateFatherStatus(
        candidateData.information.family.father.fatherStatus.label,
      )
    } else {
      setStateFatherName(' - ')
      setStateFatherJob(' - ')
      setStateFatherStatus(' - ')
    }

    if (candidateData.information.family.mother.motherTitle) {
      setStateMotherName(
        candidateData.information.family.mother.motherTitle +
          ' ' +
          candidateData.information.family.mother.motherName +
          ' ' +
          candidateData.information.family.mother.motherSurname,
      )
      setStateMotherJob(
        candidateData.information.family.mother.motherOccupation,
      )
      setStateMotherStatus(
        candidateData.information.family.mother.motherStatus.label,
      )
    } else {
      setStateMotherName(' - ')
      setStateMotherJob(' - ')
      setStateMotherStatus(' - ')
    }

    setKidStatus(candidateData.information.children.isChildren)

    setKiddata(
      candidateData.information.children !== undefined
        ? candidateData.information.children.childrens
        : {},
    )

    // setStateOldAddress(formdata1.form2.address2)
    // setStateMilitary(formdata1.form2.military)

    // if (formdata2 !== undefined&&formdata2 !==null) {

    if (
      !candidateData.information.marital ||
      !candidateData.information.marital.maritalTitle
    ) {
      setStateGirlfriendName(' - ')
      setStateGirlfriendJob(' - ')
      setStateGirlfriendWork(' - ')
    } else {
      setStateGirlfriendName(
        candidateData.information.marital.maritalTitle +
          ' ' +
          candidateData.information.marital.maritalName +
          ' ' +
          candidateData.information.marital.maritalSurname,
      )
      setStateGirlfriendJob(candidateData.information.marital.maritalCareer)

      const { maritalCompany } = candidateData.information.marital

      const companyAddress = maritalCompany && maritalCompany.address
      const companyAmphoe = maritalCompany && ' เขต ' + maritalCompany.amphoe
      const companyTumbon = maritalCompany && ' แขวง ' + maritalCompany.tumbon
      const companyProvince =
        maritalCompany && ' จังหวัด ' + maritalCompany.province
      const companyPostcode = maritalCompany && ' ' + maritalCompany.postcode

      setStateGirlfriendWork(
        companyAddress +
          companyAmphoe +
          companyTumbon +
          companyProvince +
          companyPostcode || '',
      )
    }

    //   setStateKidsAmount(formdata2.form6.child_num)
    // }
    setPrintStatus(candidateData.print_status)
  }

  const onChangePrintStatus = () => {
    const status = document.getElementById('status').value
    setPrintStatus(status)
  }

  const onClickStatus = () => {
    const status = document.getElementById('status').value

    const linkApi = `${endpoint.apiTest}/v1.1/job/cms/candidate/savePrintStatus`
    axios({
      method: 'post',
      url: linkApi,
      data: {
        _id: id,
        status: status,
      },
    })
      .then(function (response) {
        if (response.data.status === 200) {
          Swal.fire({
            icon: 'success',
            title: response.data.message,
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: response.data.message,
          })
        }
      })
      .catch(function (error) {
        console.log(error)
      })
  }

  const formatHeaderMail = (type) => {
    switch (type) {
      case 'contract':
        return 'นัดเซ็นสัญญา'
      case 'interview':
        return 'วันนัดสัมภาษณ์'
      case 'reject':
        return 'ปฏิเสธข้อเสนองาน'
      default:
        break
    }
  }

  const EditJobDetail = (jobdetail) => {
    setEditerText(jobdetail)
  }

  return (
    <>
      <div className="table-style">
        <table>
          <thead>
            <tr style={{color:"black"}}>
              <th colSpan="3">ประวัติส่วนตัว</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="profile-img" width="150px" rowSpan="6">
                <div>
                  <img style={{ height: 250, width: 250 }} src={image || ''} />
                </div>
              </td>
            </tr>

            <tr>
              <td width="250px" style={{color:"black"}}>
                <h5>
                  <strong>ชื่อ-สกุล :</strong>
                </h5>
              </td>
              <td style={{color:"black"}}>
                <h5>{nameAndLastName}</h5>
              </td>
            </tr>
            <tr>
              <td width="250px" style={{color:"black"}} >
                <h5> 
                  <strong>สมัครงานในตำแหน่ง :</strong>
                </h5>
              </td>
              <td style={{color:"black"}}>{position}</td>
            </tr>
            <tr>
              <td width="250px" style={{color:"black"}}>
                <h5>
                  <strong>เงินเดือนที่ต้องการ :</strong>
                </h5>
              </td>
              <td style={{color:"black"}}>{salary} บาท / เดือน</td>
            </tr>
            <tr>
              <td width="250px" style={{color:"black"}}>
                <h5>
                  <strong>วันที่สามารถเริ่มปฏิบัติงานได้ :</strong>
                </h5>
              </td>
              <td style={{color:"black"}}>{startDate}</td>
            </tr>
            {/* <tr>
              <td width="250px" style={{color:"black"}}>
                <h5>
                  <strong>สถานะการสมัครงาน :</strong>
                </h5>
              </td>
              <td>
                <div className="inline-box">
                  <Input
                    type="select"
                    name="select"
                    id="status"
                    value={printStatus}
                    style={{ height: 35 }}
                    onChange={() => onChangePrintStatus()}
                  >
                    <option value="notPrint">ยังไม่ได้พิมพ์ใบสมัคร</option>
                    <option value="print">พิมพ์ใบสมัครแล้ว</option>
                  </Input>
                  <Button
                    onClick={() => onClickStatus()}
                    color="success"
                    className="custom-rigth btn btn-success btn-sm"
                  >
                    บันทึก
                  </Button>
                </div>
              </td>
            </tr> */}
          </tbody>
        </table>
        <table>
          <tbody>
            <tr>
              <td width="25%" style={{color:"black"}}>
                <h5>
                  <strong>เพศ :</strong> {gender}
                </h5>
              </td>
              <td width="25%" style={{color:"black"}}>
                <h5>
                  <strong>อายุ :</strong> {age} ปี
                </h5>
              </td>
              <td width="25%" style={{color:"black"}}>
                <h5>
                  <strong>สัญชาติ :</strong> {nationality}
                </h5>
              </td>
              <td width="25%" style={{color:"black"}}>
                <h5>
                  <strong>ศาสนา :</strong> {religion}
                </h5>
              </td>
            </tr>
            <tr>
              <td style={{color:"black"}}>
                <h5>
                  <strong>ส่วนสูง :</strong> {height} เซนติเมตร
                </h5>
              </td>
              <td style={{color:"black"}}>
                <h5>
                  <strong>น้ำหนัก :</strong> {weight} กก.
                </h5>
              </td>
              <td  style={{color:"black"}}>
                <h5>
                  <strong>เกิดวันที่ :</strong> {birthdate}
                </h5>
              </td>
            </tr>

            <tr>
              <td colSpan="2" style={{color:"black"}}>
                <h5>
                  <strong>เลขที่บัตรประจำตัวประชาชน :</strong> {numberIdCard}
                </h5>
              </td>
              <td colSpan="2" style={{color:"black"}}>
                <h5>
                  <strong>สถานที่ออกบัตร :</strong> {PlaceOfIdCard}
                </h5>
              </td>
            </tr>
            <tr>
              <td colSpan="2" style={{color:"black"}}>
                <h5>
                  <strong>วันที่ออกบัตร :</strong> {dateOfIdCard}
                </h5>
              </td>
              <td colSpan="2" style={{color:"black"}}>
                <h5>
                  <strong>บัตรหมดอายุ :</strong> {expireIdCard}
                </h5>
              </td>
            </tr>
            <tr>
              <td colSpan="4" style={{color:"black"}}>
                <h5>
                  <strong>ที่อยู่ปัจจุบัน :</strong> {nowAddress}
                </h5>
              </td>
            </tr>
            <tr>
              <td colSpan="2" style={{color:"black"}}>
                <h5>
                  <strong>โทรศัพท์มือถือ :</strong> {phone}
                </h5>
              </td>
              <td colSpan="2" style={{color:"black"}}>
                <h5>
                  <strong>E-mail :</strong> {email}
                </h5>
              </td>
            </tr>
            {fatherName !== false ? (
              <tr>
                <td colSpan="2" style={{color:"black"}}>
                  <h5>
                    <strong>ชื่อบิดา :</strong> {fatherName}
                  </h5>
                </td>
                <td style={{color:"black"}}>
                  <h5>
                    <strong>อาชีพ :</strong> {fatherJob}
                  </h5>
                </td>
                <td style={{color:"black"}}>
                  <h5>
                    <strong>สถานะ :</strong> {fatherStatus}
                  </h5>
                </td>
              </tr>
            ) : (
              ''
            )}

            {motherName !== false ? (
              <tr>
                <td colSpan="2" style={{color:"black"}}>
                  <h5>
                    <strong>ชื่อมารดา :</strong> {motherName}
                  </h5>
                </td>
                <td style={{color:"black"}}>
                  <h5>
                    <strong>อาชีพ :</strong> {motherJob}
                  </h5>
                </td>
                <td style={{color:"black"}}>
                  <h5>
                    <strong>สถานะ :</strong> {motherStatus}
                  </h5>
                </td>
              </tr>
            ) : (
              ''
            )}
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th colSpan="2" style={{color:"black"}}>สถานะภาพทางครอบครัว</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{color:"black"}}>
                <h5>
                  <strong>สถานะ :</strong> {status}
                </h5>
              </td>
              <td style={{color:"black"}}>
                <h5>
                  <strong>ชื่อคู่สมรส :</strong> {girlfriendName}
                </h5>
              </td>
            </tr>
            <tr>
              <td style={{color:"black"}}>
                <h5>
                  <strong>อาชีพ :</strong> {girlfriendJob}
                </h5>
              </td>
              <td style={{color:"black"}}>
                <h5>
                  <strong>สถานที่ทำงานและที่ตั้ง :</strong> {girlfriendWork}
                </h5>
              </td>
            </tr>
          </tbody>
        </table>

        {kidStatus === true ? (
          <table>
            <thead>
              <tr>
                <th colSpan="4" style={{color:"black"}}>รายละเอียดบุตร</th>
              </tr>
            </thead>
            <tbody>
              {kiddata !== undefined &&
                kiddata.map((items, index) => (
                  <Fragment key={index}>
                    <tr>
                      <td colSpan="2" style={{color:"black"}}>
                        <h5>
                          <strong>ชื่อ-นามสกุล :</strong>{' '}
                          {items.childTitle +
                            ' ' +
                            items.childName +
                            ' ' +
                            items.childSurname}
                        </h5>
                      </td>
                      <td colSpan="2" style={{color:"black"}}>
                        <h5>
                          <strong>อายุ :</strong> {items.childAge || '-'}
                        </h5>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" style={{color:"black"}}>
                        <h5>
                          <strong>บัตรประชาชน :</strong>{' '}
                          {items.childCardId || '-'}
                        </h5>
                      </td>
                      <td colSpan="2" style={{color:"black"}}>
                        <h5>
                          <strong>การศึกษา :</strong>{' '}
                          {items.childEducation || '-'}
                        </h5>
                      </td>
                    </tr>
                  </Fragment>
                ))}
            </tbody>
          </table>
        ) : (
          ''
        )}

        {/* <table>
          <thead>
            <tr>
              <th colSpan="6">ส่งอีเมล</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="2">
                <h5>
                  <strong>นัดเซ็นสัญญา :</strong>{' '}
                  <button
                    className="btn btn-success"
                    onClick={() => openModal('contract')}
                  >
                    คลิก
                  </button>
                </h5>
              </td>
              <td colSpan="2">
                <h5>
                  <strong>ปฏิเสธข้อเสนองาน :</strong>{' '}
                  <button
                    className="btn btn-danger"
                    onClick={() => openModal('reject')}
                  >
                    คลิก
                  </button>
                </h5>
              </td>
              <td colSpan="2">
                <h5>
                  <strong>วันนัดสัมภาษณ์:</strong>{' '}
                  <button
                    className="btn btn-warning"
                    onClick={() => openModal('interview')}
                  >
                    คลิก
                  </button>
                </h5>
              </td>
            </tr>
          </tbody>
        </table> */}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
        ariaHideApp={false}
      >
        <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
          {formatHeaderMail(emailType)}
        </h2>
        <div>
          <div className=" py-3 printer">
            <p className="m-2">อีเมลสังกัด : </p>
            <Input
              type="select"
              name="select"
              id="exampleSelect"
              onChange={event =>{ setToMail(event.target.value !== 'เลือกสังกัด' && event.target.value)}}
            >
              {contactEmail.map((items, key) => (
                <option key={key} value={!items._id ? undefined : items._id}>
                  {items.contactName}
                </option>
              ))}
            </Input>
          </div>

         {toMail && <div className=" py-3 printer">
            <p className="m-2">หัวข้อส่งให้สังกัด : </p>
            <Input
              type="text"
              name="header"
              id="exampleHeader"
              value={headerMail}
              onChange={event => setHeaderMail(event.target.value)}
            />
          </div>}
        </div>

        <div>
          <div className=" py-3 printer">
            <p className="m-2">CC.Email : </p>
            <ReactMultiEmail
              placeholder=""
              emails={emails}
              onChange={(event) => setEmails(event)}
              getLabel={(email, index, removeEmail) => {
                return (
                  <div data-tag key={index}>
                    {email}
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                      ×
                    </span>
                  </div>
                )
              }}
            />
          </div>
        </div>
        <br />
        <Editor
          editorState={editerText}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          onEditorStateChange={EditJobDetail}
        />
        {/* box */}
        <br />
        {/* Submit */}
        <div className="row">
          <div className="col-2">
            <button
              className="btn btn-success btn-icon-split"
              onClick={openWaringSendMail}
            >
              <span className="icon text-white-50">
                <i className="fas fa-check"></i>
              </span>
              <span className="text">Send</span>
            </button>
          </div>
          <div className="col-2">
            <button
              className="btn btn-danger btn-icon-split"
              onClick={closeModal}
            >
              <span className="icon text-white-50">
                <i className="fas fa-times"></i>
              </span>
              <span className="text">Cancel</span>
            </button>
          </div>
        </div>
        {/* Submit */}
      </Modal>
    </>
  )
}
export default Profile
