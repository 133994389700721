import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import Swal from 'sweetalert2'

import endpoint from '../../config/config-api'
import {
  Alert,
  Button,
  Input,
  Col,
  Label,
  Form,
  FormGroup,
  Container,
  Row,
  Card,
  CardTitle,
  CardText,
  Nav,
  CardImg,
  CardBody,
  CardHeader,
} from 'reactstrap'
const axios = Axios.create({ withCredentials: true })
const ReactBsTable = require('react-bootstrap-table')
const BootstrapTable = ReactBsTable.BootstrapTable
const TableHeaderColumn = ReactBsTable.TableHeaderColumn

export const List = ({ props }) => {
  const [data, setData] = useState([])
  const [pickData, setPickData] = useState([])
  const [panoramaFormat, setFormat] = useState('image')
  const [stateOpenPano, setStateOpenPano] = useState('')

  useEffect(() => {
    fetchData()
    fetchPickData()
  }, [])

  const fetchData = async () => {
    const response = await axios.get(
      `${endpoint.apiTest}/v1.1/job/cms/panorama/all?v=` + Math.random() * 1000,
    )

    if (response.status === 200) {
      const resData = response.data.data
      setData(resData)
    }
  }
  const fetchPickData = async () => {
    const response = await axios.get(
      `${endpoint.apiTest}/v1.1/job/cms/panorama/pickAll?v=` +
        Math.random() * 1000,
    )

    if (response.status === 200) {
      const resData = response.data.data
      setStateOpenPano(resData[0]?.panoramaId)
      setPickData(resData)
    }
  }

  const rowindex = (cell, row, enumObject, index) => {
    return <i>{index + 1}</i>
  }

  const titleFormatter = (cell, row) => {
    return (
      <a
        onClick={() => props.history.push(`/panorama/form/${row._id}`)}
        style={{ color: '#4e73df', cursor: 'pointer' }}
      >
        {cell}
      </a>
    )
  }

  const selectFormatter = (cell, row) => {
    return (
      <Button
        color={stateOpenPano === row._id ? 'success' : 'warning'}
        onClick={() => isConfirmedPano(row)}
      >
        {stateOpenPano === row._id ? 'เลือกแล้ว' : 'เลือกพาโนราม่า'}
      </Button>
    )
  }

  const typeFormatter = (cell, row) => {
    return row.typeFile === 'video' ? 'วีดีโอ' : 'รูปภาพ'
  }

  const imageFormatter = (cell, row) => {

    return row.typeFile === 'video' ? (
      <Card>
        <video controls>
          <source src={process.env.REACT_APP_WEB_MEDIA_PATH + cell} type="video/mp4" />
        </video>
      </Card>
    ) : (
      <CardImg src={process.env.REACT_APP_WEB_MEDIA_PATH + cell}></CardImg>
    )
  }

  const activeFormatter = (cell, row) => {
    return cell ? 'Yes' : 'No'
  }

  const deleteData = async (id) => {
    const result = await axios.delete(
      `${endpoint.apiTest}/v1.1/job/cms/panorama/${id}`,
    )
    if (result.status === 200) {
      fetchData()
    }
  }

  const pickPanoData = async (row) => {
    const dataPick = row

    const result = await axios.post(
      `${endpoint.apiTest}/v1.1/job/cms/panorama/pick`,
      dataPick,
    )
    if (result.status === 200) {
      fetchPickData()
    }
  }

  const deletebutton = (cell) => {

    return (
      <Button
        onClick={() => openWarning(cell)}
        className="btn btn-success btn-circle btn-sm"
      >
        <i className="fas fa-user-times"></i>
      </Button>
    )
  }

  const isConfirmedPano = (row) => {
    Swal.fire({
      title: '<strong><p>ยืนยันการเลือกพาโนราม่า</p></strong>',
      icon: 'primary',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Submit',
      confirmButtonColor: '#1cc88a',
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        setStateOpenPano(row._id)
        pickPanoData(row)
      }
    })
  }

  const openWarning = (id) => {
    if(id === stateOpenPano)  {
      return (
        Swal.fire({
          title: '<strong><p>ไม่สามารถลบ รายการที่เลือกอยู่ได้</p></strong>',
          icon: 'warning',
          // showCancelButton: true,
          // focusConfirm: false,
          // confirmButtonText: 'Submit',
          // confirmButtonColor: '#1cc88a',
          // cancelButtonText: 'Cancel',
          // cancelButtonColor: '#d33',
        })
      )
    }

    Swal.fire({
      title: '<strong><p>ยืนยันการลบ</p></strong>',
      icon: 'warning',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Submit',
      confirmButtonColor: '#1cc88a',
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData(id)
      }
    })
  }

  const selectEdit = (cell) => {
    return (
      <Link
        to={`/panorama/form/${cell}`}
        className="btn btn-success btn-circle btn-sm"
      >
        <i className="fas fa-edit"></i>
      </Link>
    )
  }
  

  const options = {
    page: 1, // which page you want to show as default
    sizePerPage: 5, // which size per page you want to locate as default
    pageStartIndex: 1, // where to start counting the pages
    sizePerPageList: [], // show dropDowsn select
    prePage: 'Prev', // Previous page button text
    nextPage: 'Next', // Next page button text
 }


  return (
    <div>
      <Nav>
        <div className="mb-6" style={{ margin: '20px' }}>
          <div className="  py-3">
            <Button
              onClick={() => props.history.push('panorama/form')}
              // href={'/panorama/form'}
              color="primary"
              className="custom-rigth btn btn-primary btn-sm"
            >
              เพิ่ม panorama
            </Button>
          </div>
        </div>
      </Nav>
      <Row>
        <Col sm="3">
          <div className="card-body">
            <Card>
              <CardHeader>Pick</CardHeader>
              {pickData[0] && <CardBody>
                {pickData[0] && pickData[0].typeFile === 'video' ? (
                  <Card>
                    <video controls>
                      <source
                        src={process.env.REACT_APP_WEB_MEDIA_PATH +   pickData[0]?.imageSrc}
                        type="video/mp4"
                      />
                    </video>
                  </Card>
                ) : (
                  <CardImg src={process.env.REACT_APP_WEB_MEDIA_PATH +  pickData[0]?.imageSrc}></CardImg>
                )}
                <CardTitle>{pickData[0] && pickData[0].title}</CardTitle>
              </CardBody>}
            </Card>
          </div>
        </Col>

        <Col sm="9">
          <div className="card-body">
            <Card>
              <CardHeader>List Panorama</CardHeader>
              <CardBody>
                <BootstrapTable
                  data={data}
                  pagination={true}
                  options={options}
                  className="table table-striped"
                >
                  <TableHeaderColumn
                    dataField="_id"
                    dataFormat={rowindex}
                    width="70"
                    isKey
                  >
                    Index
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="select"
                    dataFormat={selectFormatter}
                    width="150"
                  >
                    เลือกพาโนราม่า
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="title"
                    dataFormat={titleFormatter}
                    width="150"
                  >
                    Title
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="imageSrc"
                    dataFormat={imageFormatter}
                    width="400"
                  >
                    Image
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="imageSrc"
                    dataFormat={typeFormatter}
                    width="100"
                  >
                    Type
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="active"
                    dataFormat={activeFormatter}
                    width="100"
                  >
                    Active
                  </TableHeaderColumn>

                  <TableHeaderColumn dataField="description" width="200">
                    Description
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    dataField="_id"
                    width="70"
                    dataFormat={selectEdit}
                  >
                    แก้ไข
                  </TableHeaderColumn>

                  <TableHeaderColumn
                    dataField="_id"
                    width="100"
                    dataFormat={deletebutton}
                  >
                    Delete
                  </TableHeaderColumn>
                </BootstrapTable>
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  )
}
