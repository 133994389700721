import React, { useState, useEffect, useRef } from 'react'
import {
    Col,
    Row,
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Container,
  } from 'reactstrap'
import Axios from 'axios'
import Swal from 'sweetalert2'
import User from '../../lib/admin'
import endpoint from '../../config/config-api'
var ReactBsTable = require('react-bootstrap-table')
const axios = Axios.create({withCredentials: true})


const ChangePassword = () => {

    const [userData , setUserDate] = useState({})
    const [oldPassword, SetOldPassword] = useState("")
    const [newPassword, SetNewPassword] = useState("")
    const [confirmNewPassword, SetConfirmNewPassword] = useState("")

    useEffect(() => {
        setUserDate(User.getCookieData())
    },[])

    const OnSubmit = async  () => {
        Swal.fire({
            title: 'เปลี่ยนรหัสผ่าน?',
            text: "คุณต้องการที่จะเปลี่ยนรหัสผ่าน!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ใช่',
            cancelButtonText:"ยกเลิก"
          }).then((result) => {
            if (result.isConfirmed) {
                if(oldPassword ===''){
                    SetOldPassword(true)
                }
                if(newPassword ===''){
                    SetNewPassword(true)
                }
                if(confirmNewPassword===''){
                    SetConfirmNewPassword(true)
                }
                if(newPassword !== confirmNewPassword){
                    Swal.fire({
                        icon: 'error',
                        title: 'รหัสผ่านผิดพลาด...',
                        text: 'กรุณากรอกรหัสผ่านให้เหมือนกัน!',
                      })
                }else{
                    try {
                        const linkApi = `${endpoint.apiTest}/v1.1/job/cms/changePassword`
                        axios({
                            method: 'post',
                            url: linkApi,
                            data: {
                        username: userData.data.username,
                        oldPassword: oldPassword,
                        newPassword: confirmNewPassword
                        },
                        }).then(function (response) {
                            if(response.data.status === 200){
                                Swal.fire({
                                    icon: 'success',
                                    title: response.data.message,
                                })
                                setTimeout(function(){
                                    window.location.href = "/"
                                }, 1000);
                              }else{
                                Swal.fire({
                                    icon: 'error',
                                    title: response.data.message,
                                  })
                              }
                        }).catch(function (error) {
                            console.log(error)
                        })
                    } catch (e) {
                        console.log(`request failed: ${e}`)
                    }
                }
            }
          })
}
  
  return (
    <>
    <Container>
      <Form>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label>รหัสผ่านเดิม</Label>
              <Input type="password" 
                placeholder="กรุณากรอกรหัสผ่านเดิม"
                className={
                    oldPassword=== true
                      ? 'is-invalid form-control'
                      : 'form-control'
                  }
                onChange={(e) => SetOldPassword(e.target.value)} />

                {oldPassword === true && (
                <span className="invalid-feedback">กรุณากรอกรหัสผ่านเดิม</span>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>รหัสผ่านใหม่</Label>
              <Input type="password" 
               className={
                newPassword=== true
                  ? 'is-invalid form-control'
                  : 'form-control'
              }
                placeholder="กรุณากรอกรหัสผ่านใหม่"
                onChange={(e) => SetNewPassword(e.target.value)} />

              {newPassword === true && (
                <span className="invalid-feedback">กรุณากรอกรหัสผ่านใหม่</span>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>ยืนยันรหัสผ่านใหม่</Label>
              <Input type="password" 
                placeholder="กรุณากรอกรหัสผ่านใหม่อีกครั้ง"
                className={
                    confirmNewPassword=== true
                      ? 'is-invalid form-control'
                      : 'form-control'
                  }
                onChange={(e) => SetConfirmNewPassword(e.target.value)} />

              {confirmNewPassword === true && (
                <span className="invalid-feedback">กรุณากรอกรหัสผ่านใหม่อีกครั้ง</span>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Button onClick={() => OnSubmit()}>บันทึก</Button>
      </Form>
    </Container>
  </>
  )
}
export default ChangePassword
