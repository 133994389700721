import React, { PureComponent } from 'react'
import { Row, Col } from 'reactstrap'

import ProfileNav from '../printPage/profile'
import EducationNav from '../printPage/education'
import WorkHistoryNav from '../printPage/workHistory'
import OtherNav from '../printPage/other'
import AttachmentNav from '../printPage/attachment'
import { Fragment } from 'react'

export class PrintPage extends PureComponent {
  render() {
    return (
      <>
       <div className="page-break">
          <div className="">
            <ProfileNav />
          </div>
        
          <div className="">
            <EducationNav />
          </div>

          <div className="">
            <WorkHistoryNav />
          </div>

          <div className="">
            <OtherNav />
          </div>
          </div>
      </>
       
       
    )
  }
}
