import dateFormat from "dateformat"

dateFormat.i18n = {
	dayNames: ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัส", "ศุกร์", "เสาร์", "อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์"],
	monthNames: [
		"ม.ค.",
		"ก.พ.",
		"มี.ค.",
		"เม.ย.",
		"พ.ค.",
		"มิ.ย",
		"ก.ค.",
		"ส.ค.",
		"ก.ย.",
		"ต.ค.",
		"พ.ย.",
		"ธ.ค",
		"มกราคม",
		"กุมภาพันธ์",
		"มีนาคม",
		"เมษายน",
		"พฤษภาคม",
		"มิถุนายน",
		"กรกฎาคม",
		"สิงหาคม",
		"กันยายน",
		"ตุลาคม",
		"พฤศจิกายน",
		"ธันวาคม",
	],
	timeNames: ["a", "p", "am", "pm", "A", "P", "AM", "PM"],
}

class DateUtil { }

DateUtil.convertEnToTh = en => {
	const yearFormat = +dateFormat(en, "yy") + 543
	const dateData = dateFormat(en, "d mmm ") + yearFormat.toString().substring(1)

	return dateData
}
DateUtil.convertEnToThFullYear = en => {
	const yearFormat = +dateFormat(en, "yyyy") + 543
	const dateData = dateFormat(en, "d mmm ") + yearFormat.toString()

	return dateData
}
DateUtil.convertEnToTh2 = en => {

	const dateData = dateFormat(en, "d mmm ")

	return dateData
}

DateUtil.convertEnToThFullDate = en => {
	const yearFormat = +dateFormat(en, "yyyy") + 543
	const months = dateFormat(en, "mmmm")
	const day = dateFormat(en, "ddd")
	const dateData = "วัน" + day + "ที่ " + dateFormat(en, "d") + " " + months + " " + yearFormat.toString() + " เวลา " + dateFormat(en, "HH:MM") + "  น."
	return dateData
}
DateUtil.convertEnToThFullDate2 = en => {
	const yearFormat = +dateFormat(en, "yyyy") + 543
	const months = dateFormat(en, "mmmm")
	const dateData = "วันที่ " + dateFormat(en, "d") + " " + months + " " + yearFormat.toString()
	return dateData
}

DateUtil.tsToDate = timestamp => {
	let tempTs = 0
	if (timestamp === undefined) {
		tempTs = Date.now()
	} else {
		tempTs = timestamp
	}

	const ts = new Date(tempTs)
	const date = ts.getDate()
	const month = ts.getMonth()
	const year = ts.getFullYear()
	return year + "-" + (month + 1) + "-" + (date - 1)
}
DateUtil.Datenormal = timestamp => {
	let tempTs = 0
	if (timestamp === undefined) {
		tempTs = Date.now()
	} else {
		tempTs = timestamp
	}

	const ts = new Date(tempTs)
	
	const date = ts.getDate()
	const month = ts.getMonth()
	const year = ts.getFullYear()
	console.log(month)
	return date  + "/" + (month + 1)   + "/" + (year+543) 
}

DateUtil.DatenormalTime = timestamp => {
	let tempTs = 0
	if (timestamp === undefined) {
		tempTs = Date.now()
	} else {
		tempTs = timestamp
	}

	const ts = new Date(tempTs)
	
	const date = ts.getDate()
	const month = ts.getMonth()
	const year = ts.getFullYear()
	console.log(month)
	return date  + "/" + (month + 1)   + "/" + (year+543) + " เวลา " + dateFormat(ts, "HH:MM") + "  น."
}

DateUtil.getThaiMonth = shorten => {
	if (shorten) {
		return dateFormat.i18n.monthNames.slice(0, 12)
	} else {
		return dateFormat.i18n.monthNames.slice(12)
	}
}

export default DateUtil
