import styled from "styled-components"

export const SC_UPLOAD_FILE = styled.div`
	position: relative;
	background: white;
	border: 1px solid ${(props) => (props.error ? "#dc3545" : props.disabled ? "#d1d3e2" : "#d1d3e2")};
	border-radius: 5px;
	font-size: 16px;
	color: ${props => props.disabled ? '#d1d3e2' : '#858796'};
	padding: 10px;
	height: 36px;
	pointer-events: ${props => props.disabled && 'none'};
	span {
		position: absolute;
		font-size: 16px;
		font-family: "cloudsemibold";
		color: ${props => props.disabled ? '#d1d3e2' : '##4e73df'};
		width: 80px;
		height: 100%;
		right: 0;
		top: 0;
		display: grid;
		border-radius: 0 5px 5px 0;
		border-left: 1px solid ${props => props.disabled ? '#d1d3e2' : '#d1d3e2'};
		align-items: center;
		justify-content: center;
		transition: all 0.5s;
	}
	input {
		border: none;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		opacity: 0;
		cursor: pointer;
		z-index: 2;
	}
	&:hover {
		span {
			transition: all 0.5s;
			background: #4e73df;
			color: white;
		}
	}
	.form-control {
		box-shadow: none;
		&:focus {
			border-color: ${props => props.disabled ? '#d1d3e2' : '#4e73df'};
		}
	}
	.form-control.is-invalid ~ .invalid-feedback,
	.form-control.is-invalid ~ .invalid-tooltip,
	.was-validated .form-control:invalid ~ .invalid-feedback,
	.was-validated .form-control:invalid ~ .invalid-tooltip {
		position: absolute;
		bottom: -8px;
		background: white;
		padding: 0 7px;
		width: max-content;
		left: 10px;
	}
	.is-invalid {
		background: none;
	}
	.form-control.is-invalid {
		box-shadow: none;
	}
	label {
		cursor: pointer;
		top: -2px;
		left: 0;
		font-size: 16px;
		padding: 0;
		pointer-events: none;
		position: relative;
		width: 80%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	@media (max-width: 767px) {
		font-size: 14px;
		label {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: block;
			width: 140px;
		}
	}
`
