import React, { useState, useRef, useEffect } from 'react'

import {
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from 'reactstrap'
import classnames from 'classnames'

import ProfileNav from './candidateData/profile'
import EducationNav from './candidateData/education'
import WorkHistoryNav from './candidateData/workHistory'
import OtherNav from './candidateData/other'
import AttachmentNav from './candidateData/attachment'
import ReactToPrint from 'react-to-print'
import { PrintPage } from './widget/printPage'
import Swal from 'sweetalert2'
import Axios from 'axios'
import { useParams } from 'react-router-dom'
import endpoint from '../../config/config-api'


const axios = Axios.create({withCredentials: true})

const Show = (props) => {
  const { id } = useParams()
  const componentRef = useRef()
  const [activeTab, setActiveTab] = useState('1')
  const [data, setData] =useState()
  const [prohrStatus , setProhrStatus] = useState(false)

  useEffect(() => {
    fetchData()
  },[])

  const fetchData = async () => {
    const { data : {candidateData} } = await axios.get(`${endpoint.apiTest}/v1.1/job/cms/candidate/${id}`)
    if(candidateData.step_status === "ส่ง ProHR แล้ว"){
     setProhrStatus(true)
    }
    setData(candidateData)

  }

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const sendToProHrs = () => {
    const { step_status } = data

    if(step_status === "สมบูรณ์"){
        
        axios({
          method: 'post',
          url: `${endpoint.apiTest}/v1.1/job/cms/prohrs`,
          data: data,
        }).then((response) => {
          const { status } = response.data
          if (status === 200) {
            Swal.fire({
              icon: 'success',
              title: 'ส่งเรียบร้อย',
            })

            axios({
              method: 'post',
              url: `${endpoint.apiTest}/v1.1/job/cms/candidate/saveStepStatus`,
              data: {
                _id: id,
                step_status: "ส่ง ProHR แล้ว",
              },
            })
              .then(function (response) {
                if (response.data.status === 200) {
                  setProhrStatus(true)
                }
              })
              .catch(function (error) {
                console.log(error)
              })
          }else {
            Swal.fire({
              icon: 'error',
              title: 'ไม่สามารถส่งข้อมูลได้',
            })

          }
        })
    }else{
      Swal.fire({
        icon: 'error',
        title: 'ไม่สามารถส่งข้อมูลได้',
        text: 'สถานะกรอกข้อมูลของผู้สมัครยังไม่ "สมบูรณ์"',
      })
    }
   
  }


  const onSetIsPrint = e => {

    Swal.fire({
      title: 'เปลี่ยนสถานะการพิมพ์',
      text: "คุณต้องการเปลี่ยนสถานะการพิมพ์หรือไม่ ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'ต้องการเปลี่ยน'
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: 'post',
          url: `${endpoint.apiTest}/v1.1/job/cms/candidate/savePrintStatus`,
          data: {
            _id: id,
            status: "พิมพ์ใบสมัครแล้ว",
          },
        })
          .then(function (response) {
            if (response.data.status === 200) {
              Swal.fire({
                icon: 'success',
                title: response.data.message,
              })
             window.location.reload()
            } else {
              Swal.fire({
                icon: 'error',
                title: response.data.message,
              })
            }
          })
          .catch(function (error) {
            console.log(error)
          })
      }
    })

  } 
  

  return (
    <>
      <div className="card shadow mb-4">
        <div className="page-print">
          <PrintPage ref={componentRef} />
        </div>
        <div className="card-header py-3 card-printer">
          <h6 className="m-0 font-weight-bold text-primary">ข้อมูลผู้สมัคร</h6>
          <div>
            <Button className="mr-3" onClick={() => sendToProHrs()} disabled={prohrStatus}>
              Import to ProHR
            </Button>
            <ReactToPrint
              onAfterPrint={ () => onSetIsPrint()}
              trigger={() => {
                return <Button  color="success">พิมพ์</Button>
              }}
              content={() => componentRef.current}
              copyStyles={true}
            />
          </div>
        </div>
        <div className="card-body">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  toggle('1')
                }}
              >
                ประวัติส่วนตัว
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  toggle('2')
                }}
              >
                การศึกษา
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '3' })}
                onClick={() => {
                  toggle('3')
                }}
              >
                ประวัติการทำงาน
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '4' })}
                onClick={() => {
                  toggle('4')
                }}
              >
                อื่นๆ
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '5' })}
                onClick={() => {
                  toggle('5')
                }}
              >
                เอกสาร/Attachment
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <ProfileNav />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <EducationNav />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row>
                <Col sm="12">
                  <WorkHistoryNav />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="4">
              <Row>
                <Col sm="12">
                  <OtherNav />
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="5">
              <Row>
                <Col sm="12">
                  <AttachmentNav />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
        <div className="card-header py-3 printer">
          <Button onClick={() => props.history.push('/data')}>ย้อนกลับ</Button>
        </div>
      </div>
    </>
  )
}
export default Show
