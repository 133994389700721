export const typeEmail =  [
	{
		label: 'นัดสัมภาษณ์',
		value: 'interview'
	},
	{
		label: 'นัดเซ็นสัญญา',
		value: 'contract'
	},
	{
		label: 'ปฏิเสธข้อเสนองาน',
		value: 'reject'
	},
	{
		label: 'ส่งบุคลากร',
		value: 'organization'
	},

]
  