import React, { useState, useEffect } from 'react'

import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Container,
  Alert,
  Row,
  Col,
} from 'reactstrap'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import htmlToText from 'html-to-text'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import Axios from 'axios'
import endpoint from '../../config/config-api'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import Swal from 'sweetalert2'
import { useParams } from 'react-router-dom'
const axios = Axios.create({withCredentials: true})
const EditJob = (props) => {
  const { id } = useParams()

  const [jobdetail, Setjobdetail] = useState(EditorState.createEmpty())
  const [ability, Setjobability] = useState(EditorState.createEmpty())

  const [jobname, Setjobname] = useState('')

  const [status, Setstatus] = useState('')

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [salary, Setsalary] = useState('')
  const [quantity, Setquantity] = useState('')
  const [configBussiness, setConfigBussiness] = useState([])
  const [configContract, setConfigContract] = useState([])
  const [configEducation, setConfigEducation] = useState([])
  const [education, Seteducation] = useState({
    label: '',
    value: '',
  })
  const [contract, Setcontract] = useState({
    label: '',
    value: '',
  })
  const [business, Setbusiness] = useState({
    label: '',
    value: '',
  })
  const [openstatus, Setopenstatus] = useState()
  const [closestatus, Setclosestatus] = useState()
  const [department, Setdepartment] = useState('') //a---
  const [jobdutystatus, Setjobdutystatus] = useState(true)
  const [jobabilitystatus, Setjobabilitystatus] = useState(true)


  const EditJobDetail = (jobdetail) => {
    Setjobdetail(jobdetail)
    let datatext = draftToHtml(convertToRaw(jobdetail.getCurrentContent()))
    if (htmlToText.fromString(datatext) !== '') {
      Setjobdutystatus(true)
    } else {
      Setjobdutystatus(false)
    }
  }

  const EditorJobAbility = (ability) => {
    Setjobability(ability)
    let datatext = draftToHtml(convertToRaw(ability.getCurrentContent()))
    if (htmlToText.fromString(datatext) !== '') {
      Setjobabilitystatus(true)
    } else {
      Setjobabilitystatus(false)
    }
  }
  useEffect(() => {
    fetchdata()
  }, [])

  const fetchdata = async () => {
    const data = await axios.get(
      `${endpoint.apiTest}/v1.1/job/cms/jobAnnouncement/${id}`,
    )
    const configObj = data.data.config.joblist
    setConfigBussiness(configObj.business)
    setConfigContract(configObj.contract)
    setConfigEducation(configObj.education)

    const { department } = data.data.departmentlist
    Setdepartment(department)
    const {
      business,
      contract,
      education,
      job_closedate,
      job_duty,
      job_limit,
      salary,
      job_opendate,
      job_requirement,
      job_status,
      job_title,
    } = data.data.results
    console.log('job_status', job_status)
    if (job_status === '1') {
      Setopenstatus(true)
      Setstatus(1)
    } else if (job_status === '2') {
      Setclosestatus(true)
      Setstatus(2)
    }
    Seteducation(education)
    Setjobname(job_title)
    Setbusiness(business)
    setEndDate(new Date(job_closedate))
    setStartDate(new Date(job_opendate))

    Setcontract(contract)
    Setsalary(salary)
    Setquantity(job_limit)

    const htmljobdetail = job_duty
    const contentBlockhtmljobdetail = htmlToDraft(htmljobdetail)
    const htmlability = job_requirement
    const contentBlockhtmlability = htmlToDraft(htmlability)
    if (contentBlockhtmljobdetail) {
      const contentStatedetail = ContentState.createFromBlockArray(
        contentBlockhtmljobdetail.contentBlocks,
      )
      const editorStatedetail = EditorState.createWithContent(
        contentStatedetail,
      )
      Setjobdetail(editorStatedetail)
    }
    if (contentBlockhtmlability) {
      const contentStateability = ContentState.createFromBlockArray(
        contentBlockhtmlability.contentBlocks,
      )
      const editorStateability = EditorState.createWithContent(
        contentStateability,
      )
      Setjobability(editorStateability)
    }
  }
  const Updateform = async () => {
    const htmlcheckjobduty = draftToHtml(
      convertToRaw(jobdetail.getCurrentContent()),
    )
    const htmlcheckjobability = draftToHtml(
      convertToRaw(ability.getCurrentContent()),
    )

    const jobdutytext = htmlToText.fromString(htmlcheckjobduty)
    const jobabilitytext = htmlToText.fromString(htmlcheckjobability)
    if (
      status !== '' &&
      jobname !== '' &&
      quantity !== '' &&
      // contract !== '' &&
      salary !== '' &&
      jobdutytext !== '' &&
      jobabilitytext !== ''
    ) {
      const payload = {
        job_title: jobname,
        business: business,
        education: education,
        contract: contract,
        job_status: status,
        job_closedate: endDate,
        job_opendate: startDate,

        job_duty: draftToHtml(convertToRaw(jobdetail.getCurrentContent())),
        job_requirement: draftToHtml(convertToRaw(ability.getCurrentContent())),
        job_limit: quantity,
        salary: salary,
      }

      const linkApi = `${endpoint.apiTest}/v1.1/job/cms/jobAnnouncement/update/${id}`

      axios({
        method: 'put',
        url: linkApi,
        data: payload,
      })
        .then(
          function (response) {
            if (response) {
              Swal.fire({
                icon: 'success',
                title: 'แก้ไขข้อมูลสำเร็จ',
              }).then((result) => {
                if (result.isConfirmed) {
                  props.history.push('/announcement')
                }
              })
            }
          }.bind(this),
        )

        .catch(function (error) {
          console.log(error)
        })
    }
  }

  return (
    <Container>
      <Form>
        <Row>
          <Col xs="6" sm="4">
            <FormGroup>
              <Label for="exampleSelect">สายงาน</Label>
              <Input
                type="select"
                name="select"
                value={business.value}
                id="exampleSelect"
                onChange={(e) => {
                  const obj = configBussiness.find(
                    (f) => f.value === e.target.value,
                  )
                  Setbusiness({
                    label: obj.label,
                    value: obj.value,
                  })
                }}
              >
                {Object.values(configBussiness).map((items, key) => (
                  <option key={key} value={items.value}>
                    {items.label}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="exampleText">ตำแหน่ง</Label>
              <Input
                type="text"
                name="text"
                id="exampleText"
                className={
                  jobname === '' ? 'is-invalid form-control' : 'form-control'
                }
                value={jobname}
                onChange={(e) => Setjobname(e.target.value)}
              />
              {jobname === '' && (
                <Alert color="danger">กรุณากรอกชื่อตำแหน่ง</Alert>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="exampleText">การศึกษา</Label>

              <Input
                type="select"
                name="select"
                value={education.value}
                id="exampleSelect"
                onChange={(e) => {
                  const obj = configEducation.find(
                    (f) => f.value === e.target.value,
                  )
                  Seteducation({
                    label: obj.label,
                    value: obj.value,
                  })
                }}
              >
                {Object.values(configEducation).map((items, key) => (
                  <option key={key} value={items.value}>
                    {items.label}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="exampleText">ประเภทการจ้าง</Label>

              <Input
                type="select"
                name="select"
                id="exampleSelect"
                value={contract.value}
                className={
                  contract === '' ? 'is-invalid form-control' : 'form-control'
                }
                onChange={(e) => {
                  const obj = configContract.find(
                    (f) => f.value === e.target.value,
                  )
                  Setcontract({
                    label: obj.label,
                    value: obj.value,
                  })
                }}
              >
                {Object.values(configContract).map((items, key) => (
                  <option key={key} value={items.value}>
                    {items.label}
                  </option>
                ))}
              </Input>
              {contract === '' && (
                // <span className="invalid-feedback">กรุณากรอกประเภทการจ้าง</span>
                <Alert color="danger">กรุณากรอกประเภทการจ้าง</Alert>
              )}
            </FormGroup>
            <FormGroup tag="fieldset">
              สถานะ
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="radio1"
                    defaultChecked={openstatus}
                    onChange={() => Setstatus(1)}
                  />{' '}
                  เปิด
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="radio1"
                    defaultChecked={closestatus}
                    onChange={() => Setstatus(2)}
                  />{' '}
                  ปิด
                </Label>
              </FormGroup>
              {status === '' && <Alert color="danger">กรุณาเลือกสถานะ</Alert>}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="6">
            รายละเอียดงาน
            <Editor
              editorState={jobdetail}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={EditJobDetail}
            />
            {jobdutystatus === false && (
              <Alert color="danger">กรุณาเขียนรายละเอียดงาน</Alert>
            )}
            ความสามารถที่ต้องการ
            <Editor
              editorState={ability}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={EditorJobAbility}
            />
            {jobabilitystatus === false && (
              <Alert color="danger">กรุณาเขียนความสามารถที่ต้องการ</Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs="6" sm="4">
            เริ่มประกาศ
            <FormGroup>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                showTimeSelect
                startDate={startDate}
                dateFormat="dd/MM/yyyy HH:mm:ss"
                endDate={endDate}
              />
            </FormGroup>
            สิ้นสุดประกาศ
            <FormGroup>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                showTimeSelect
                startDate={startDate}
                dateFormat="dd/MM/yyyy HH:mm:ss"
                endDate={endDate}
                minDate={startDate}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleText">จำนวนที่รับ(คน)</Label>
              <Input
                type="number"
                name="text"
                min={0}
                className={
                  quantity === '' ? 'is-invalid form-control' : 'form-control'
                }
                id="exampleText"
                value={quantity}
                onChange={(e) => Setquantity(e.target.value)}
              />
              {quantity === '' && (
                // <span className="invalid-feedback">กรุณากรอกจำนวนที่รับ</span>
                <Alert color="danger">กรุณากรอกจำนวนที่รับ</Alert>
              )}
            </FormGroup>
            <FormGroup>
              <Label for="exampleText">เงินเดือน(บาท)</Label>
              <Input
                type="number"
                name="text"
                id="exampleText"
                min={0}
                className={
                  salary === '' ? 'is-invalid form-control' : 'form-control'
                }
                value={salary}
                onChange={(e) => Setsalary(e.target.value)}
              />
              {salary === '' && (
                // <span className="invalid-feedback">กรุณากรอกเงินเดือนที่ต้องการ</span>
                <Alert color="danger">กรุณากรอกเงินเดือนที่ต้องการ</Alert>
              )}
            </FormGroup>
            <div className="btn">
              <Button onClick={() => Updateform()}>Submit</Button>
            </div>
            <div className="btn">
              <Button onClick={() => props.history.push('/announcement')}>
                ย้อนกลับ
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default EditJob
