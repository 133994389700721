import React, { useRef, useEffect, useState } from 'react'
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import {
  Alert,
  Button,
  Input,
  Col,
  Label,
  Form,
  FormGroup,
  Container,
  Row,
} from 'reactstrap'
import Swal from 'sweetalert2'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { typeEmail, headerEmail } from '../../helper'
import htmlToDraft from 'html-to-draftjs'
import {
  contract,
  interview,
  organization,
  reject,
} from '../../helper/formEmail'
import draftToHtml from 'draftjs-to-html'
import { templateMailController } from './Service'
import { Upload } from '../../component/Upload'
import { FileList } from '../../component/FileList'

const FormTemplateMail = (props) => {
  const [data, setData] = useState({
    title: '',
    header: headerEmail('interview'),
    type: 'interview',
    lang: 'TH',
    default: false,
    htmlBody: EditorState.createEmpty(),
    files: [],
  })
  const [error, setError] = useState('')

  const [fileDel, setFileDel] = useState([])

  const [myFile, setMyFile] = useState({})

  const [validate, setValidate] = useState(undefined)
  const [id] = useState(props.match.params.id)

  useEffect(() => {
    fetchData()
  }, [id])

  const fetchData = async () => {
    if (id) {
      const response = await templateMailController().getById(id)
      if (response.data.status === 200) {
        const { htmlBody } = response.data.result

        setData({
          ...response.data.result,
          htmlBody: FormatSetEditor({ htmlBody }),
        })
      }
    } else {
      const editor = FormatSetEditor({})

      setData({
        ...data,
        htmlBody: editor,
      })
    }
  }

  const FormatSetEditor = (data) => {
    const { type = 'interview', htmlBody } = data
    const dataProfile = {
      name: '{{name}}',
      position: '{{position}}',
      url: '{{url}}',
      dateToSend: `{{date}}`,
    }

    //create content editor

    const contentBlockhtml = htmlToDraft(
      htmlBody ? htmlBody : responseEmailType(type, dataProfile),
    )

    const contentState = ContentState.createFromBlockArray(
      contentBlockhtml.contentBlocks,
    )

    const editorStateTemplate = EditorState.createWithContent(contentState)

    return editorStateTemplate
  }

  const responseEmailType = (type, dataProfile) => {
    switch (type) {
      case 'interview':
        return interview(dataProfile)
      case 'contract':
        return contract(dataProfile)
      case 'reject':
        return reject(dataProfile)
      case 'organization':
        return organization(dataProfile)

      default:
        break
    }
  }

  const EditJobDetail = (editor) => {
    setData({
      ...data,
      htmlBody: editor,
    })
  }

  const setValue = (name) => (event) => {
    switch (name) {
      case 'type':
        console.log(event.target.value)
        setData({
          ...data,
          type: event.target.value,
          header: headerEmail(event.target.value),
          htmlBody: FormatSetEditor({ type: event.target.value }),
        })

        break

      case 'title':
        setData({
          ...data,
          title: event.target.value,
        })

        break

      case 'header':
        setData({
          ...data,
          header: event.target.value,
        })

        break

      case 'language':
        setData({
          ...data,
          lang: event.target.value,
        })

        break

      default:
        break
    }
  }

  const onCheckValidate = (req) => {
    if (!req.imageSrc) {
      setValidate({
        file: !req.imageSrc && 'กรุณาเลือก',
      })
      return true
    } else {
      setValidate(undefined)
      return false
    }
  }

  const handleSubmit = async () => {
    const newData = {
      ...data,
      title: data.title || 'เริ่มต้น(default)',
      htmlBody: draftToHtml(convertToRaw(data.htmlBody.getCurrentContent())),
      fileDel: fileDel,
    }
    if (id) {
      const response = await templateMailController().updateById(id, newData)

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'บันทึกข้อมูลสำเร็จ',
        }).then((result) => {
          if (result.isConfirmed) {
            props.history.push('/template')
          }
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: response.data.message,
        })
      }
    } else {
      const response = await templateMailController().create(newData)

      if (response.data.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'บันทึกข้อมูลสำเร็จ',
        }).then((result) => {
          if (result.isConfirmed) {
            props.history.push('/template')
          }
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: response.data.message,
        })
      }
    }
  }

  const onUpload = (event) => {
    const { files } = event.target
    if (files.length > 0) {
      let { type, size } = files[0]
      console.log(files[0])
      setMyFile(files[0])
      setError('')
    }
  }

  const sendUpload = async () => {
    const file = myFile
    const formData = new FormData()
    const fileName = file.name.split(' ').join('_') 
    formData.append('file', file, fileName)
    const target_file = formData
    const response = await templateMailController().uploadFile(
      target_file,
      'email',
    )

    if (response.status === 200) {
      let file = response.data.data
      file.filename = fileName
      const newData = {
        ...data,
        files: [...data.files, file],
      }

      setData(newData)
      setMyFile({})
    } else {
      setError(response.data.message)
    }
  }

  const onDeleteFile = async (id) => {
    const file = data.files.filter((list) => {
      return list._id !== id
    })
    const fileDels = data.files.filter((list) => {
      return list._id === id
    })
    setData({
      ...data,
      files: file,
    })

    setFileDel([...fileDel, ...fileDels])
  }

  return (
    <Container>
      <Form noValidate onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="exampleAddress">Type</Label>
              <Input
                type="select"
                name="type"
                id="type"
                className={
                  validate && validate.type
                    ? 'is-invalid form-control'
                    : 'form-control'
                }
                autoComplete="off"
                value={data.type}
                onChange={setValue('type')}
              >
                {typeEmail.map((list, index) => (
                  <option key={index} value={list.value}>
                    {list.label}
                  </option>
                ))}
              </Input>
              {validate && validate.type && (
                <Alert color="danger">{validate.type}</Alert>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="exampleAddress">Title</Label>
              <Input
                type="text"
                name="title"
                id="title"
                className={
                  validate && validate.title
                    ? 'is-invalid form-control'
                    : 'form-control'
                }
                autoComplete="off"
                value={data.title}
                onChange={setValue('title')}
              />
              {validate && validate.title && (
                <Alert color="danger">{validate.title}</Alert>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="exampleAddress">Language</Label>
              <Input
                type="select"
                name="title"
                id="title"
                className={
                  validate && validate.title
                    ? 'is-invalid form-control'
                    : 'form-control'
                }
                autoComplete="off"
                value={data.lang}
                onChange={setValue('language')}
              >
                {['TH', 'EN'].map((list, index) => (
                  <option key={index} value={list}>
                    {list}
                  </option>
                ))}
              </Input>
              {validate && validate.title && (
                <Alert color="danger">{validate.title}</Alert>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col xs="6" sm="4">
            <FormGroup tag="fieldset">
              Default
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="default"
                    checked={data.default}
                    onChange={() => setData({ ...data, default: true })}
                  />
                  เปิด
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="default"
                    checked={!data.default}
                    onChange={() => setData({ ...data, default: false })}
                  />
                  ปิด
                </Label>
              </FormGroup>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="exampleAddress">Header</Label>
              <Input
                type="text"
                name="title"
                id="title"
                className={
                  validate && validate.header
                    ? 'is-invalid form-control'
                    : 'form-control'
                }
                autoComplete="off"
                value={data.header}
                onChange={setValue('header')}
              />
              {validate && validate.title && (
                <Alert color="danger">{validate.header}</Alert>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            Body
            <Editor
              editorState={data.htmlBody}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={EditJobDetail}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={6}>
            <Upload
              id="upload"
              name="upload_file"
              value={(myFile || '').url ? 'Browse...' : myFile.name}
              onChange={onUpload}
              onClick={() => sendUpload()}
            />
          </Col>
        </Row>
        {!error && <p>ไฟล์มีขนาดไม่เกิน 5 MB</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <br />
        <Row>
          <FileList files={data.files} onDelete={onDeleteFile} />
        </Row>
        <br />
        <Row>
          <div className="btn">
            <Button onClick={() => handleSubmit()}>บันทึก</Button>
          </div>
          <div className="btn">
            <Button onClick={() => props.history.push('/template')}>
              ย้อนกลับ
            </Button>
          </div>
        </Row>
      </Form>
    </Container>
  )
}

export default FormTemplateMail
