const arrthai = {
  alive: 'มีชีวิต',
  death: 'ถึงแก่กรรม',
  male: 'ชาย',
  female: 'หญิง',
  unknown:"ไม่ทราบข้อมูล",
  seperate: 'แยกกันอยู่',
  married: 'สมรส',
  exempt: 'ได้รับการยกเว้น',
  deferment: 'ผัดผ่อน',
  served: 'ผ่านการรับราชการทหาร',
  excellent: 'ดีมาก',
  good: 'ดี',
  poor: 'พอใช้',
  divorce: 'หย่าร้าง',

  single:'โสด',
  widow:'หม้าย'

}
module.exports = Object.assign(arrthai)
