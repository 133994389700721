export const headerEmail = (type) => {
  switch (type) {
    case 'interview':
      return 'เรียนเชิญสัมภาษณ์งานและขอแจ้งรายละเอียดการนัดหมายสัมภาษณ์'
    case 'reject':
      return 'แจ้งผลการสัมภาษณ์ตำแหน่ง'
    case 'contract':
      return 'แจ้งผลการสัมภาษณ์ตำแหน่ง'
    case 'organization':
      return 'แจ้งพนักงานใหม่'
	  
    default:
      return null
  }
}
