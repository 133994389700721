import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import Swal from 'sweetalert2'
import { templateMailController } from './Service'

const axios = Axios.create({ withCredentials: true })
const ReactBsTable = require('react-bootstrap-table')
const BootstrapTable = ReactBsTable.BootstrapTable
const TableHeaderColumn = ReactBsTable.TableHeaderColumn

export const List = ({ props }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const response = await templateMailController().getAll()

    if (response.data.status === 200) {
      setData(response.data.result)
    }
  }

  const rowindex = (cell, row, enumObject, index) => {
    return <i>{index + 1}</i>
  }

  const titleFormatter = (cell, row) => {
    return (
      <a
        onClick={() => props.history.push(`/template/form/${row._id}`)}
        style={{ color: '#4e73df', cursor: 'pointer' }}
      >
        {cell}
      </a>
    )
  }

  const deleteData = async (id) => {
    const result = await templateMailController().deleteById(id)
    if (result.data.status === 200) {
      fetchData()
    }
  }

  const deletebutton = (cell, row) => {
    return !row.default ? (
      <Button
        onClick={() => openWarning(cell)}
        className="btn btn-success btn-circle btn-sm"
      >
        <i className="fas fa-user-times"></i>
      </Button>
    ) : (
      <></>
    )
  }

  const openWarning = (id) => {
    Swal.fire({
      title: '<strong><p>ยืนยันการลบ</p></strong>',
      icon: 'warning',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Submit',
      confirmButtonColor: '#1cc88a',
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData(id)
      }
    })
  }

  const formatDefault = (cell, row, enumObject, index) => {
    return cell ? 'เปิด' : 'ปิด'
  }

  const formatTypeToThai = (cell, row, enumObject, index) => {
    switch (cell) {
      case 'interview':
        return 'นัดสัมภาษณ์'
      case 'contract':
        return 'นัดเซ็นสัญญา'
      case 'organization': 
        return 'ส่งบุคลากร'
      case 'reject':
        return 'ปฏิเสธข้อเสนองาน'
      default:
        return '-'
    }
  }

  return (
    <div className="card-body">
      <Button
        onClick={() => props.history.push('template/form')}
        color="primary"
        className="custom-rigth btn btn-primary btn-sm"
      >
        เพิ่ม template
      </Button>

      <BootstrapTable
        data={data}
        pagination={true}
        // options={options}
        className="table table-striped"
      >
        <TableHeaderColumn
          dataField="_id"
          dataFormat={rowindex}
          width="70"
          isKey
        >
          Index
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="title"
          dataFormat={titleFormatter}
          width="300"
        >
          Title
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="type"
          width="200"
          dataFormat={formatTypeToThai}
        >
          Type
        </TableHeaderColumn>
        <TableHeaderColumn dataField="lang" width="200">
          Language
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="default"
          width="200"
          dataFormat={formatDefault}
        >
          Default
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="_id"
          width="100"
          dataFormat={deletebutton}
        >
          Delete
        </TableHeaderColumn>
      </BootstrapTable>
    </div>
  )
}
