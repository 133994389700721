export const familyStatus = (status) => {
    switch (status) {
		case "single":
			return "โสด"
		case "married":
			return "สมรส"
		case "divorce":
			return "หย่า"
		case "seperate":
			return "แยกกันอยู่"
		case "widow":
			return "ม่าย"
		default:
			return null
	}
}