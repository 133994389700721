import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Input,
  Col,
  Label,
  Form,
  FormGroup,
  Container,
  Row,
  Card,
  CardTitle,
  CardText,
  Nav,
  CardImg,
  CardBody,
  CardHeader,
  CardColumns,
  CardGroup,
  CardSubtitle,
  Modal,
  ModalHeader,
  ModalContent,
  ModalBody,
} from 'reactstrap'
import Axios from 'axios'
import endpoint from '../../../config/config-api'
import { Gallery } from 'react-grid-gallery'
import IntermshipForm from '../InternshipForm'
import ModalViewAllGallery from './modalViewAllGallery'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'

const ReactBsTable = require('react-bootstrap-table')
const BootstrapTable = ReactBsTable.BootstrapTable
const TableHeaderColumn = ReactBsTable.TableHeaderColumn
const axios = Axios.create({ withCredentials: true })
const InternshipSection = ({ props }) => {
  const [data, setData] = useState([])
  const [modal, setModal] = useState(false)
  const [modalViewAll, setModalViewAll] = useState(false)
  const [imageGallery, setImageGallery] = useState([])
  const [imageGalleryShow, setImageGalleryShow] = useState([])
  const toggle = () => { imageGallery.length >=20 ? alert('จำนวนภาพเกิน 20 ภาพ') : setModal(!modal)}
  const toggleViewAll = () => setModalViewAll(!modalViewAll)

  const [index, setIndex] = useState(-1)

  const currentImage = imageGalleryShow[index]
  const nextIndex = (index + 1) % imageGalleryShow.length
  const nextImage = imageGalleryShow[nextIndex] || currentImage
  const prevIndex =
    (index + imageGalleryShow.length - 1) % imageGalleryShow.length
  const prevImage = imageGalleryShow[prevIndex] || currentImage

  const handleClick = (index, item) => setIndex(index)
  const handleClose = () => setIndex(-1)
  const handleMovePrev = () => setIndex(prevIndex)
  const handleMoveNext = () => setIndex(nextIndex)

  useEffect(() => {
    fecthData()
 
  }, [])

  const fecthData = async () => {
    const response = await axios.get(
      `${endpoint.apiTest}/v1.1/job/cms/internship/get`,
    )
    let arr = []
    if (response.status === 200) {
      const resData = response.data.data
      setData(resData)
      resData.map((image) => {
        arr.push({
          src: image.url,
          width: image.width,
          height: image.height,
          id: image._id,
        })
      })

      setImageGallery(resData)
      setImageGalleryShow(arr)
    }
  }

  return (
    <Row>
      <div className="card-body">
        <Card>
          <CardHeader>Internship</CardHeader>
          <Nav>
            <div className="mb-6" style={{ margin: '20px' }}>
              <div className="  py-3">
                <Button
                  onClick={() => {
                    toggle()
                  }}
                  color="primary"
                  className="custom-rigth btn btn-primary btn-sm"
                >
                  เพิ่มรูปภาพ Internship
                </Button>
              </div>
            </div>
          </Nav>
          <CardBody>
            <CardHeader>แกลลอรี่</CardHeader>
            <Gallery
              images={imageGalleryShow.slice(0, 5)}
              onClick={handleClick}
            />

            <Row style={{ marginTop: 20 }}>
              <Button onClick={() => toggleViewAll()}>ดูทั้งหมด</Button>
            </Row>
          </CardBody>
        </Card>
      </div>
      <Modal isOpen={modalViewAll} toggle={toggleViewAll} fullscreen>
        <ModalBody style={{ width: 'auto' }}>
          <ModalViewAllGallery
            imageGallery={imageGalleryShow}
            fecthData={fecthData}
          />
        </ModalBody>
      </Modal>{' '}
      <Modal isOpen={modal} toggle={toggle} className={props.className}>
        <ModalBody style={{ width: 'auto' }}>
          <IntermshipForm imageGallery={imageGallery} />
        </ModalBody>
      </Modal>
      {!!currentImage && (
        /* @ts-ignore */

        <Lightbox
          reactModalStyle={{ overlay: { zIndex: 9999 } }}
          mainSrc={currentImage.src}
          mainSrcThumbnail={currentImage.src}
          nextSrc={nextImage.src}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.src}
          prevSrcThumbnail={prevImage.src}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
    </Row>
  )
}
export default InternshipSection
