import React, { Fragment } from "react"
import { SC_UPLOAD_FILE } from "./styles"
import { Button } from "react-bootstrap"

export const Upload = ({ label, name, key, value, onChange, onClick, disabled }) => {
	return (
		<Fragment>
			<div className="row">
				<div className="col-10">
					<SC_UPLOAD_FILE  disabled={disabled} >
						<input name={name} type="file" key={key} className="form-control" onChange={onChange} />
						<label htmlFor={name}>{value ? value : label}</label>
						<span>เลือก</span>
						{/* <ErrorMessage name={pathName} errors={errorUpload} className="invalid-feedback" /> */}
					</SC_UPLOAD_FILE>
				</div>
				<div className="col-2 row justify-content-end" style={{ padding: 0 }}>
					<Button className={`btn  ${disabled ? 'btn-disabled' : ''} `} onClick={onClick}>
						Upload
					</Button>
				</div>
			</div>
		</Fragment>
	)
}
