import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import endpoint from '../../../config/config-api'
import Axios from 'axios'
const axios = Axios.create({ withCredentials: true })

const WorkHistory = () => {
  let { id } = useParams()

  const [isExperience, setIsExperience] = useState(false)
  const [experience, setStateExperince] = useState([])
  const [referenceTitle, setStateReferenceTitle] = useState('')
  const [referenceName, setStateReferenceName] = useState('')
  const [referenceSurname, setStateReferenceSurname] = useState('')

  const [relatives, setRelatives] = useState(undefined)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const {
      data: { candidateData },
    } = await axios.get(`${endpoint.apiTest}/v1.1/job/cms/candidate/${id}`)
    setIsExperience(
      candidateData.experiences !== undefined
        ? candidateData.experiences.isExperience
        : false,
    )
    setStateExperince(
      candidateData.experiences !== undefined
        ? candidateData.experiences.works
        : {},
    )
    setStateReferenceTitle(
      candidateData.reference !== undefined
        ? candidateData.reference.referenceTitle
        : ' ',
    )
    setStateReferenceName(
      candidateData.reference !== undefined
        ? candidateData.reference.referenceName
        : ' ',
    )
    setStateReferenceSurname(
      candidateData.reference !== undefined
        ? candidateData.reference.referenceSurname
        : ' - ',
    )
    setRelatives(candidateData.information.relatives)
  }

  return (
    <>
      <div className="table-style">
        {isExperience === true ? (
          <table>
            <thead>
              <tr style={{color:"black"}}>
                <th colSpan="3">ประสบการณ์การทำงาน</th>
              </tr>
            </thead>
            <tbody>
              {experience !== undefined &&
                experience.map((items) => (
                  <>
                    <tr style={{color:"black"}}>
                      <td colSpan="2">
                        <h5>
                          <strong>ชื่อบริษัท : </strong>{' '}
                          {items.company ? items.company : ' - '}
                        </h5>
                      </td>
                      <td>
                        <h5>
                          <strong>ตำแหน่ง : </strong> {items.position}
                        </h5>
                      </td>
                    </tr>
                    <tr style={{color:"black"}}>
                      <td>
                        <h5>
                          <strong>เงินเดือน : </strong>{' '}
                          {items.salary ? items.salary : ' - '}
                        </h5>
                      </td>
                      <td colSpan="2">
                        <h5>
                          <strong>รายละเอียดงาน/ความรับผิดชอบ : </strong>{' '}
                          {items.responsibility ? items.responsibility : ' - '}
                        </h5>
                      </td>
                    </tr>
                    <tr style={{color:"black"}}>
                      <td>
                        <h5>
                          <strong>เหตุผลที่ออก : </strong>{' '}
                          {items.reason ? items.reason : ' - '}
                        </h5>
                      </td>
                      <td>
                        <h5>
                          <strong>จาก : </strong>
                          {items.startwork
                            ? new Date(items.startwork).getMonth() +
                              1 +
                              '/' +
                              new Date(items.startwork).getFullYear()
                            : ' - '}
                        </h5>
                      </td>
                      <td>
                        <h5>
                          <strong>ถึง : </strong>
                          {items.endwork
                            ? new Date(items.endwork).getMonth() +
                              1 +
                              '/' +
                              new Date(items.endwork).getFullYear()
                            : ' - '}
                        </h5>
                      </td>
                    </tr>
                  </>
                ))}
            </tbody>
          </table>
        ) : (
          ''
        )}

        <table>
          <thead>
            <tr style={{color:"black"}}>
              <th colSpan="3">บุคคลที่ติดต่อได้กรณีฉุกเฉิน</th>
            </tr>
          </thead>
          <tbody>
            <>
              {relatives &&
                relatives.map((item) => (
                  <>
                    <tr style={{color:"black"}}>
                      <td width="33%">
                        <h5>
                          <strong>ความสัมพันธ์ : </strong> {item.relativePerson}
                        </h5>
                      </td>
                      <td width="33%">
                        <h5>
                          <strong>ชื่อ-สกุล : </strong>
                          {(item.relativeTitle || '') +
                            ' ' +
                            (item.relativeName || '') +
                            ' ' +
                            (item.relativeSurname || '')}
                        </h5>
                      </td>
                      <td width="33%">
                        <h5>
                          <strong>เบอร์ติดต่อ : </strong>
                          {item.relativePhone}
                        </h5>
                      </td>
                      {/* <td width="50%"><h5><strong>ความสัมพันธ์ : </strong> {items.relation}</h5></td> */}
                    </tr>
                    <tr style={{color:"black"}}>
                      <td colSpan={3}>
                        <h5>
                          <strong>ที่อยู่ : </strong>
                          {item.relativeAddress &&
                            item.relativeAddress.address +
                              ' ' +
                              item.relativeAddress.tumbon +
                              ' ' +
                              item.relativeAddress.amphoe +
                              ' ' +
                              item.relativeAddress.province +
                              ' ' +
                              item.relativeAddress.postcode}
                        </h5>
                      </td>
                    </tr>
                  </>
                ))}

              {/* <tr>
                                        <td><h5><strong>ที่อยู่ : </strong> {items.address1+ " " +items.district.value+" "+items.subdistrict.value+" "+items.province.value+" "+items.zipcode }</h5></td>
                                        <td><h5><strong>โทรศัพท์ : </strong> {items.phone}</h5></td>
                                </tr> */}
            </>
          </tbody>
        </table>
        <table>
          <thead>
            <tr style={{color:"black"}}>
              <th colSpan="3">คนรู้จักทำงานที่ไทยรัฐ</th>
            </tr>
          </thead>
          <tbody>
            <td width="50%" style={{color:"black"}}>
              <h5>
                <strong>ชื่อ : </strong>{' '}
                {referenceTitle + ' ' + referenceName + ' ' + referenceSurname}
              </h5>
            </td>
          </tbody>
        </table>
      </div>
    </>
  )
}
export default WorkHistory
