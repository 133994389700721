import cookie from "react-cookies"
import Axios from "axios"
import endpoint from '../config/config-api'

const CMS_JOB_COOKIE = "cms-job"
const CMS_JOB_COOKIE_TOKEN = "cms-job-token"

export default {
	/**
	 * axios wrappers
	 */
	_errorMsg: "",
	_post: async function (path, body) {
		return this._axios(path, "post", body)
	},
	_get: async function (path) {
		return this._axios(path)
	},
	_delete: async function (path, body) {
		return this._axios(path, "delete", body)
	},
	_axios: async function (path, method, body) {
		const options = {
			method,
			data: body,
			url: `${endpoint.apiTest}/${path}`,
			withCredentials: true,
		}
		const { data } = await Axios(options)
		if (data.status === 200) {
			return data
		} else {
			console.log(`User lib error ${path}: ${data.message}`)
			this._errorMsg = data.message
			return data
		}
	},

	/**
	 * API functions
	 */

	// login
	login: async function (username, password, remember) {
		const { data } = await this._post("admin/login", { username, password, remember })
		if (data && data._id) {
			this.saveCookie(data)
			return true
		} else {
			return false
		}
	},
	// logout
	logout: async function () {
		this._post("/admin/logout", {})
		this.deleteCookie()
		return true
	},
	/**
	 * Cookie functions
	 */

	// delete cookie
	deleteCookie: function () {
		cookie.remove(CMS_JOB_COOKIE, { path: "/" })
		cookie.remove(CMS_JOB_COOKIE_TOKEN, { path: "/" })
	},

	// save cookie
	saveCookie: function (data) {
		if (data) {
			const options = { path: "/" }
			if (data.expires) {
				options.expires = new Date(data.expires)
			}
			const token = data.data.token
			delete data.data.token
			cookie.save(CMS_JOB_COOKIE, data, options)
			cookie.save(CMS_JOB_COOKIE_TOKEN,token, options)
		}
	},

	// get cookie
	getCookieData: function () {
		return cookie.load(CMS_JOB_COOKIE) || false
	},

	// restore redirect page for after login success
	getRedirect: function () {
		const redirect = cookie.load("redirect_uri")
		if (redirect) {
			cookie.remove("redirect_uri", { path: "/" })
			const _redirect = redirect

			// protect redirect loop
			if (_redirect.indexOf("/login") !== -1 || _redirect.indexOf("/check-login") !== -1) {
				return "/"
			} else {
				return _redirect
			}
		} else {
			return "/"
		}
	},
	// save lastest page when user visit before they go to login page
	// or save current page when user click login popup
	saveRedirect: function () {
		const redirect_cookie = cookie.load("redirect_uri")
		if (redirect_cookie && redirect_cookie !== "Lw==") {
			return
		}

		const urlParams = new URLSearchParams(window.location.search)
		let redirect = "/"

		const ignoreRedirectPath = ["/admin/login"]

		// regular redirect url
		if (urlParams.get("redirect")) {
			redirect = urlParams.get("redirect")

			// base64 encode redirect url
		} else if (urlParams.get("redirect_encode")) {
			redirect = urlParams
		} else if (ignoreRedirectPath.includes(window.location.pathname)) {
			redirect = "/"
		} else {
			redirect = window.location.pathname
		}

		cookie.save("redirect_uri", redirect, {
			maxAge: 60 * 10,
			path: "/",
		})
	},


}
