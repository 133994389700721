import React, { Fragment } from 'react'
import { Search } from './Search'
import { List } from './List'

const Panorama = (props) => {
  return (
    <div className="card shadow mb-4">
      <div className="card-header py-3">
        <h6 className="m-0 font-weight-bold text-primary">panorama</h6>
      </div>
      <br />
      <List props={props} />
    </div>
  )
}

export default Panorama
