import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import Swal from 'sweetalert2'
import endpoint from '../../config/config-api'

const axios = Axios.create({withCredentials: true})
const ReactBsTable = require('react-bootstrap-table')
const BootstrapTable = ReactBsTable.BootstrapTable
const TableHeaderColumn = ReactBsTable.TableHeaderColumn

export const List = ({ props }) => {
  const [data, setData] = useState([])
  // const [page, setPage] = useState(1)

  useEffect(() => {
    fetchData()
    localStorage.removeItem('page-index')
  }, [])

  const fetchData = async () => {
    const response = await axios.get(
      `${endpoint.apiTest}/v1.1/job/cms/contactMail/all?v=` +
      Math.random() * 1000,
    )
    if (response.status === 200) {
      const resData = response.data.response
      setData(resData)
    }
  }

  const rowindex = (cell, row, enumObject, index) => {
    const page = localStorage.getItem('page-index') || 1
    return <i>{(index + 1) + ((page - 1)*10)}</i>
  }

  const formatMails = (cell) => {
      const newMails = cell.map((list, index) => 
        <li key={index}>{list}</li>
        )
    return <ul >{newMails}</ul>
  }


  const deleteData = async (id) => {
    const result = await axios.delete(
      `${endpoint.apiTest}/v1.1/job/cms/contactMail/${id}`,
    )
    if (result.status === 200) {
      fetchData()
    }
  }

  const deletebutton = (cell) => {
    return (
      <Button
        onClick={() => openWarning(cell)}
        className="btn btn-success btn-circle btn-sm"
      >
        <i className="fas fa-user-times"></i>
      </Button>
    )
  }

  const openWarning = (id) => {
    Swal.fire({
      title: '<strong><p>ยืนยันการลบ</p></strong>',
      icon: 'warning',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Submit',
      confirmButtonColor: '#1cc88a',
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData(id)
      }
    })
  }

  const selectEdit = (cell) => {
    return (
      <Link
        to={`/contact/form/${cell}`}
        className="btn btn-success btn-circle btn-sm"
      >
        <i className="fas fa-edit"></i>
      </Link>
    )
  }

  const onPageChange = (page, sizePerPage) =>{
  localStorage.setItem('page-index',page)
    
  }


  return (
    <div className="card-body">
      <Button
        onClick={() => props.history.push('/contact/form')}
        color="primary"
        className="custom-rigth btn btn-primary btn-sm"
      >
        เพิ่มสังกัด
      </Button>

      <BootstrapTable
        data={data}
        pagination={true}
        options={{
          onPageChange : onPageChange
          
        }}
        className="table table-striped"
        
      >
        <TableHeaderColumn
          dataField="_id"
          dataFormat={rowindex}
          width="20"
          isKey
        >
          Index
        </TableHeaderColumn>

        <TableHeaderColumn dataField="contactName" width="100">
          ชื่อสังกัด
        </TableHeaderColumn>

        <TableHeaderColumn dataField="emails" dataFormat={formatMails} width="200">
          อีเมลภายในสังกัด
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="_id"
          width="70"
          dataFormat={selectEdit}
        >
          แก้ไข
        </TableHeaderColumn>

        <TableHeaderColumn dataField="_id" width="100" dataFormat={deletebutton}>
          Delete
        </TableHeaderColumn>
      </BootstrapTable>
    </div>
  )
}
