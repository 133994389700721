import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import endpoint from '../../../config/config-api'
import Axios from 'axios'
import arrthai from "../widget/thaiword"
const axios = Axios.create({withCredentials: true})


const Education = () => {
        let { id } = useParams()

        const [highschool , setStateHighSchool] = useState([])
        const [bechelor , setStateBechelor] = useState([])
        const [master , setStateMaster] = useState([])
        const [doctor , setStateDoctor] = useState([])
        const [training , setStateTraining] = useState([])
        const [language , setStateLangugae] = useState([])
       
        useEffect(() => {
                fetchData()
        },[])

        const fetchData = async () => {
             

                const { data : {candidateData} } = await axios.get(`${endpoint.apiTest}/v1.1/job/cms/candidate/${id}`)
                setStateHighSchool(candidateData.education.high_school !== undefined ? candidateData.education.high_school : {})
                setStateBechelor(candidateData.education.bechelor !== undefined ? candidateData.education.bechelor : {})
                setStateMaster(candidateData.education.master !== undefined ? candidateData.education.master : {})
                setStateDoctor(candidateData.education.doctor !== undefined ? candidateData.education.doctor : {})
                setStateTraining(candidateData.experiences.trainee !== undefined ? candidateData.experiences.trainee : {})
                setStateLangugae(candidateData.languages !== undefined ? candidateData.languages : {})
                
        }

      
	return (
	<>
        <div className="table-style">
                <table>
                        <thead>
                                <tr>
                                        <th colSpan="3" style={{color:"black"}}>การศึกษาระดับมัธยม</th>
                                </tr>
                        </thead>
                        <tbody>
                                        <tr style={{color:"black"}}>
                                                <td><h5><strong>ชื่อสถานศึกษา : {highschool.name !== undefined ? highschool.name : " - "}</strong></h5></td>
                                                <td><h5><strong>เริ่ม : {highschool.startSchoolYear !== undefined ? highschool.startSchoolYear : " - "}</strong></h5></td>
                                                <td><h5><strong>จบ : {highschool.endSchoolYear !== undefined ? highschool.endSchoolYear : " - "}</strong></h5></td>
                                        </tr>
                                        <tr style={{color:"black"}}>
                                                <td><h5><strong>ระยะเวลา :  {highschool.endSchoolYear !== undefined && highschool.startSchoolYear !== undefined ? (parseInt(highschool.endSchoolYear)) - (parseInt(highschool.startSchoolYear)) : " - "}  </strong></h5></td>
                                                <td><h5><strong>เกรดเฉลี่ย : {highschool.gpa !== undefined ? highschool.gpa : " - "}</strong></h5></td>
                                                <td><h5><strong>สาขา : {highschool.faculty !== undefined ? highschool.faculty : " - "}</strong></h5></td>
                                        </tr>
                        </tbody>
                        <thead>
                                <tr style={{color:"black"}}>
                                        <th colSpan="3">การศึกษาระดับปริญญาตรี</th>
                                </tr>
                        </thead>
                        <tbody>
                        <tr style={{color:"black"}}>
                                                <td><h5><strong>ชื่อสถานศึกษา : {bechelor.name !== undefined ? bechelor.name : " - "}</strong></h5></td>
                                                <td><h5><strong>เริ่ม : {bechelor.startSchoolYear !== undefined ? bechelor.startSchoolYear : " - "}</strong></h5></td>
                                                <td><h5><strong>จบ : {bechelor.endSchoolYear !== undefined ? bechelor.endSchoolYear : " - "}</strong></h5></td>
                        </tr>
                        <tr style={{color:"black"}}>
                                                <td><h5><strong>ระยะเวลา :  {bechelor.endSchoolYear !== undefined && bechelor.startSchoolYear !== undefined ? (parseInt(bechelor.endSchoolYear)) - (parseInt(bechelor.startSchoolYear)) : " - "}  </strong></h5></td>
                                                <td><h5><strong>เกรดเฉลี่ย : {bechelor.gpa !== undefined ? bechelor.gpa : " - "}</strong></h5></td>
                                                <td><h5><strong>สาขา : {bechelor.faculty !== undefined ? bechelor.faculty : " - "}</strong></h5></td>
                        </tr>
                        </tbody>
                        <thead>
                                <tr>
                                        <th style={{color:"black"}} colSpan="3">การศึกษาระดับปริญญาโท</th>
                                </tr>
                        </thead>
                        <tbody>
                        <tr style={{color:"black"}}>
                                <td><h5><strong>ชื่อสถานศึกษา : {master.name !== undefined ? master.name : " - "}</strong></h5></td>
                                <td><h5><strong>เริ่ม : {master.startSchoolYear !== undefined ? master.startSchoolYear : " - "}</strong></h5></td>
                                <td><h5><strong>จบ : {master.endSchoolYear !== undefined ? master.endSchoolYear : " - "}</strong></h5></td>
                        </tr>
                        <tr style={{color:"black"}}>
                                <td><h5><strong>ระยะเวลา :  {master.endSchoolYear !== undefined && master.startSchoolYear !== undefined ? (parseInt(master.endSchoolYear)) - (parseInt(master.startSchoolYear)) : " - "}  </strong></h5></td>
                                <td><h5><strong>เกรดเฉลี่ย : {master.gpa !== undefined ? master.gpa : " - "}</strong></h5></td>
                                <td><h5><strong>สาขา : {master.faculty !== undefined ? master.faculty : " - "}</strong></h5></td>
                        </tr>
                        </tbody>
                        <thead>
                                <tr>
                                        <th style={{color:"black"}} colSpan="3">การศึกษาระดับปริญญาเอก</th>
                                </tr>
                        </thead>
                        <tbody>
                        <tr style={{color:"black"}}>
                                <td><h5><strong>ชื่อสถานศึกษา : {doctor.name !== undefined ? doctor.name : " - "}</strong></h5></td>
                                <td><h5><strong>เริ่ม : {doctor.startSchoolYear !== undefined ? doctor.startSchoolYear : " - "}</strong></h5></td>
                                <td><h5><strong>จบ : {doctor.endSchoolYear !== undefined ? doctor.endSchoolYear : " - "}</strong></h5></td>
                        </tr>
                        <tr style={{color:"black"}}>
                                <td><h5><strong>ระยะเวลา :  {doctor.endSchoolYear !== undefined && doctor.startSchoolYear !== undefined ? (parseInt(doctor.endSchoolYear)) - (parseInt(doctor.startSchoolYear)) : " - "}  </strong></h5></td>
                                <td><h5><strong>เกรดเฉลี่ย : {doctor.gpa !== undefined ? doctor.gpa : " - "}</strong></h5></td>
                                <td><h5><strong>สาขา : {master.faculty !== undefined ? master.faculty : " - "}</strong></h5></td>
                        </tr>
                        </tbody>
                </table>
                <table>
                        <thead>
                                <tr style={{color:"black"}}>
                                        <th colSpan="5">ความสามารถทางภาษา</th>
                                </tr>
                        </thead>
                        <tbody>
                                 {language !== undefined && language.map((items, index) => (
                                        <tr  key={items._id} key={index} style={{color:"black"}}>
                                                <td>
                                                        <h5><strong>{items.lang}</strong></h5>
                                                </td>
                                                <td><h5><strong>พูด :</strong> {arrthai[items.speak]}</h5></td>
                                                <td><h5><strong>อ่าน :</strong> {arrthai[items.read]}</h5></td>
                                                <td><h5><strong>เขียน :</strong> {arrthai[items.write]}</h5></td>
                                                <td><h5><strong>เข้าใจ :</strong> {arrthai[items.understand]}</h5></td>
                                        </tr>
                                ))}
                        </tbody>
                </table>
        </div>
        </>
	)
}
export default Education
