import axios from 'axios'
import endpoint from '../../config/config-api'

export const templateMailController = () => {
  const url = `${endpoint.apiTest}/v1.1/job/cms`
  const config = {
    withCredentials: true,
  }

  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    return error.response;
  });

  return {
    getAll: (type = '') => {
      return axios.get(`${url}/templateMail/all?v=${Math.random() * 1000}&type=${type}`, config)
    },
    getById: (id) => {
      return axios.get(`${url}/templateMail/${id}`, config)
    },
    create: (body) => {
      return axios.post(`${url}/templateMail`, body, config)
    },
    updateById: (id, body) => {
      return axios.put(`${url}/templateMail/${id}`, body, config)
    },
    deleteById: (id) => {
      return axios.delete(`${url}/templateMail/${id}`, config)
    },
    uploadFile: (body, upload) => {
      return axios.post(`${url}/upload?upload=${upload}`, body, {
        ...config,
        headers: {
          'Content-Type': 'multipart/form-data; text/html; charset=utf-8',

        },
      })
    },
  }
}
