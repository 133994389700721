import React, { useState, useEffect, useRef } from 'react'
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { Link, withRouter } from 'react-router-dom'

import { Button, Input, Col, Label, FormGroup, Container ,Row} from 'reactstrap'
import Axios from 'axios'
import Swal from 'sweetalert2'
import endpoint from '../../config/config-api'
const axios = Axios.create({withCredentials: true})
var ReactBsTable = require('react-bootstrap-table')
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

const Departmentlist = (props) => {
  const [data, setData] = useState([])
  const [jobcompany,Setjobcompany]=useState('')
  const [jobdepartment,Setjobdepartment]=useState('')

  const inputRef = useRef(null)
  const options = { sizePerPage: 25 }
  const selectEdit = (cell) => {
    return (
      <Link
        to={`/department/edit/${cell}`}
        className="btn btn-success btn-circle btn-sm"
      >
        <i className="fas fa-edit"></i>
      </Link>
    )
  }
  const deletebutton = (cell) => {
    return (
      <Button
        onClick={() => openWarning(cell)}
        className="btn btn-success btn-circle btn-sm"
      >
        <i className="fas fa-user-times"></i>
      </Button>
    )
  }
  const rowindex = (cell, row, enumObject, index) => {
    return <i>{index + 1}</i>
  }



  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const data = await axios.get(
      `${endpoint.apiTest}/v1.1/job/cms/department/all?v=` + Math.random() * 1000,
    )

    setData(data.data.results.department)
  }

  const SearchApi = async() =>{
   const type =jobcompany === "ทั้งหมด"?"":jobcompany
    
   const obj = {
    department_type: type ,
    department_name:jobdepartment
   }

    const res = await axios.post(`${endpoint.apiTest}/v1.1/job/cms/department/searchByUser`,
    obj 
    )
 
    setData(res.data.results)
  

 }

  const deleteData = async (id) => {
    await axios.delete(`${endpoint.apiTest}/v1.1/job/cms/department/delete/${id}`)
    getData()
  }

  const openWarning = (id) => {
    Swal.fire({
      title: '<strong><p>ยืนยันการลบ</p></strong>',
      icon: 'warning',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Submit',
      confirmButtonColor: '#1cc88a',
      cancelButtonText: 'Cancel',
      cancelButtonColor: '#d33',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteData(id)
      }
    })
  }

  return (
    <>
        <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h6 className="m-0 font-weight-bold text-primary">
          รายการสายงาน
          </h6>
        </div>
        <Container>
          <Row>
            <Col xs="6" sm="4">
        <FormGroup>
        <Label for="exampleSelect">
          เลือกสายงาน</Label>
        <Input type="select" name="select" id="exampleSelect"  onChange={(e) => Setjobcompany(e.target.value)}>
          <option>ทั้งหมด</option>
          <option>บริษัท เทรนด์ วีจี3 จำกัด</option>
          <option>บริษัท ทริปเปิล วี บรอดคาสท์ จํากัด</option>
          <option>บริษัท วัชรพล จำกัด</option>
          <option>บริษัท ไทยรัฐ โลจิสติคส์ จำกัด</option>
        </Input>
      </FormGroup>
      
          <FormGroup>
            <Label for="exampleSearch">ค้นหาสายงาน</Label>
            <Input
              type="search"
              name="search"
              id="exampleSearch"
              onChange={(e) => Setjobdepartment(e.target.value)}
       
            />
          </FormGroup>
  
        <Button  onClick={()=>SearchApi()} outline color="success">ค้นหา</Button>{' '}
        </Col>
          </Row>
        </Container>
        <div className="card-body">
          <br />
      <Button
        onClick={() => props.history.push('/department/add')}
        color="primary"
        className="custom-rigth btn btn-primary btn-sm"
      >
        เพิ่มสายงาน
      </Button>{' '}

      <BootstrapTable
        data={data}
        pagination={true}
        options={options}
        className="table table-striped"
        exportCSV
        
      >
        <TableHeaderColumn dataField="_id" dataFormat={rowindex} width="70">
          ลำดับ
        </TableHeaderColumn>

        <TableHeaderColumn dataField="department_name" width="200">
          รายชื่อสายงาน
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="_id"
          width="70"
          isKey
          dataFormat={selectEdit}
        >
          แก้ไข
        </TableHeaderColumn>
        <TableHeaderColumn dataField="_id" width="70" dataFormat={deletebutton}>
          ลบ
        </TableHeaderColumn>
      </BootstrapTable>
      </div>
      </div>
    </>
  )
}
export default withRouter(Departmentlist) 
