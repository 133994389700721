import React , {useEffect , useState} from "react"
import { Link, useHistory } from "react-router-dom"
import User from '../../../src/lib/admin'
import endpoint from '../../config/config-api'
import Axios from 'axios'
import Swal from 'sweetalert2'
const axios = Axios.create({withCredentials: true})
const Topbar = () => {
	const history = useHistory()
	const [name , setName] = useState("")
	const [position , setPosition] = useState("")

	useEffect(() => {
		setTitle()
	},[])


	const setTitle = () => {
		const user = User.getCookieData()
		setName(user.data.firstname + " " + user.data.lastname)
		setPosition(user.data.position)
	}

	const logout =  async() => {
		const linkApi = `${endpoint.apiTest}/v1.1/job/cms/logout`
		axios({
			method: 'post',
			url: linkApi,
		}).then(function (response) {
			if(response.data.status === 200){
				Swal.fire({
					icon: 'success',
					title: response.data.message,
				})
				User.deleteCookie()
				history.push('/')
				window.location.reload()
			}
		}).catch(function (error) {
			console.log(error)
		})
		
	}
	
	return (
		<nav className="navbar navbar-expand navbar-light bg-gradient-primary2 topbar mb-4 static-top shadow">
			{/* Sidebar Toggle (Topbar) */}
			<button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
				<i className="fa fa-bars" />
			</button>

			{/* Topbar Navbar */}
			<ul className="navbar-nav ml-auto">
				{/* Nav Item - Search Dropdown (Visible Only XS) */}
				<li className="nav-item dropdown no-arrow d-sm-none">
					<a
						className="nav-link dropdown-toggle"
						href="/home"
						id="searchDropdown"
						role="button"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<i className="fas fa-search fa-fw" />
					</a>
					{/* Dropdown - Messages */}
					<div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in" aria-labelledby="searchDropdown">
						<form className="form-inline mr-auto w-100 navbar-search">
							<div className="input-group">
								<input
									type="text"
									className="form-control bg-light border-0 small"
									placeholder="Search for..."
									aria-label="Search"
									aria-describedby="basic-addon2"
								/>
								<div className="input-group-append">
									<button className="btn btn-primary" type="button">
										<i className="fas fa-search fa-sm" />
									</button>
								</div>
							</div>
						</form>
					</div>
				</li>

				<div className="topbar-divider d-none d-sm-block" />
				{/* Nav Item - User Information */}
				<li className="nav-item dropdown no-arrow">
					<a
						className="nav-link dropdown-toggle"
						href="/home"
						id="userDropdown"
						role="button"
						data-toggle="dropdown"
						aria-haspopup="true"
						aria-expanded="false"
					>
						<span className="mr-2 d-none d-lg-inline text-gray-100 small">
							{/* {user.firstname} {user.lastname} | {user.position} */}
							{name} | {position}
						</span>
						<img className="img-profile rounded-circle" src={process.env.PUBLIC_URL + "/avatar.jpg"} alt="" />
					</a>
					{/* Dropdown - User Information */}
					<div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
						<Link className="dropdown-item" to="/changePassword">
							<i className="fas fa-unlock-alt fa-sm fa-fw mr-2 text-gray-400" />
							เปลี่ยนรหัสผ่าน
						</Link>
						<div className="dropdown-divider" />
						<button className="dropdown-item"  onClick={logout}>
							<i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400" />
							Logout
						</button>
					</div>
				</li>
			</ul>
		</nav>
	)
}
export default Topbar
