import React from 'react';

// import { iconStyle } from './../common/styles';
import { lazyLoad } from '@react-page/editor';

const ImageIcon = lazyLoad(() => import('@material-ui/icons/Landscape'));

const styleIcon = {
  width: '100%',
  height: 'auto',
  padding: '0',
  color: '#aaa',
  textAlign: 'center',
  minWidth: 64,
  minHeight: 64,
  maxHeight: 256,
}

const ImageHtmlRenderer = (
  props
) => {
  const { data } = props;

  const src = data?.src;
  const openInNewWindow = data?.openInNewWindow;
  const image = (
    <img className="react-page-plugins-content-image" alt="" src={src} />
  );

  return src ? (
    <div>
      {data?.href ? (
        <a
          onClick={props.isEditMode ? (e) => e.preventDefault() : undefined}
          href={data?.href}
          target={openInNewWindow ? '_blank' : undefined}
          rel={openInNewWindow ? 'noreferrer noopener' : undefined}
        >
          {image}
        </a>
      ) : (
        image
      )}
    </div>
  ) : (
    <div>
      <div className="react-page-plugins-content-image-placeholder">
        <ImageIcon style={styleIcon} />
      </div>
    </div>
  );
};

export default ImageHtmlRenderer;