import React, { useState, useEffect, useRef } from 'react'
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { Link, useParams } from 'react-router-dom'
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
} from 'reactstrap'
import Axios from 'axios'
import Swal from 'sweetalert2'

import endpoint from '../../config/config-api'
const axios = Axios.create({withCredentials: true})
var ReactBsTable = require('react-bootstrap-table')
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

const Departmentedit = (props) => {
  const { id } = useParams()
  const [company, SetCompany] = useState('')
  const [department, SetDepartment] = useState('')

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const data = await axios.get(
      `${endpoint.apiTest}/v1.1/job/cms/department/${id}`,
    )
    const { department_type, department_name } = data.data.results
    SetCompany(department_type)
    SetDepartment(department_name)
  }
  const OnSubmit = async () => {
    if (company === '') {
      SetCompany('--เลือกสายงาน--')
    }
    if (department === '') {
      SetDepartment('')
    }

    if (company !== '--เลือกสายงาน--' && department !== '') {
      const payload = {
        department_type: company,
        department_name: department,
        label: department,
        value: department,
        checked: false,
      }
      const linkApi = `${endpoint.apiTest}/v1.1/job/cms/department/update/${id}`
      console.log(linkApi)
      axios({
        method: 'put',
        url: linkApi,
        data: payload,
      })
        .then(
          function (response) {
            if (response) {
              Swal.fire({
                icon: 'success',
                title: 'แก้ไขข้อมูลสำเร็จ',
              }).then((result) => {
                if (result.isConfirmed) {
                  props.history.push('/department')
                }
              })
            }
          }.bind(this),
        )

        .catch(function (error) {
          console.log(error)
        })
    }
  }

  return (
    <>
      <Container>
        <Form>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for="exampleAddress">เลือกสายงาน</Label>
                <Input
                  type="select"
                  name="select"
                  className={
                    company === '--เลือกสายงาน--'
                      ? 'is-invalid form-control'
                      : 'form-control'
                  }
                  id="exampleSelect"
                  value={company}
                  onChange={(e) => SetCompany(e.target.value)}
                >
                  <option> --เลือกสายงาน--</option>
                  <option>บริษัท เทรนด์ วีจี3 จำกัด</option>
                  <option>บริษัท ทริปเปิล วี บรอดคาสท์ จํากัด</option>
                  <option>บริษัท วัชรพล จำกัด</option>
                  <option>บริษัท ไทยรัฐ โลจิสติคส์ จำกัด</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="exampleAddress">ชื่อสายงาน</Label>
                <Input
                  type="text"
                  name="department"
                  id="department"
                  className={
                    department === ''
                      ? 'is-invalid form-control'
                      : 'form-control'
                  }
                  value={department}
                  onChange={(e) => SetDepartment(e.target.value)}
                />
                {department === '' && (
                  <span className="invalid-feedback">กรุณากรอกชื่อสายงาน</span>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Button onClick={() => OnSubmit()}>บันทึก</Button>
          <div className="btn">
            <Button onClick={() => props.history.push('/department')}>
              ย้อนกลับ
            </Button>
          </div>
        </Form>
      </Container>
    </>
  )
}
export default Departmentedit
