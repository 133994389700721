import Axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'reactstrap'
import Swal from 'sweetalert2'
import endpoint from '../../config/config-api'
import { staticPageController } from './Services'
import { v4 as uuidv4 } from "uuid"

const axios = Axios.create({withCredentials: true})
const ReactBsTable = require('react-bootstrap-table')
const BootstrapTable = ReactBsTable.BootstrapTable
const TableHeaderColumn = ReactBsTable.TableHeaderColumn

// https://react-page.github.io/

export const List = ({ props }) => {
  const [data, setData] = useState([])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const response = await staticPageController().getAll()
    if (response.status === 200) {
      const resData = response.data.data
      setData(resData)
    }
  }

  const rowindex = (cell, row, enumObject, index) => {
    return <i>{index + 1}</i>
  }

  const titleFormatter = (cell, row) => {
    return (
      <a
        onClick={() => props.history.push(`/joinus/form/${row._id}`)}
        style={{ color: '#4e73df', cursor: 'pointer' }}
      >
        {cell}
      </a>
    )
  }

  const activeFormatter = (cell, row) => {
    return cell ? 'Yes' : 'No'
  }

  const deleteData = async (id) => {
    const result = await axios.delete(
      `${endpoint.apiTest}/v1.1/job/cms/joinus/${id}`,
    )
    if (result.status === 200) {
      fetchData()
    }
  }

  const onPreview = (cell, row, enumObject, index) => {
    const token = uuidv4()
    return (
      !row.publish ? <a
        href={`${process.env.REACT_APP_WEB_URL}/${row.slag_name}?token=${token}`}
        target="_blank"
        className="btn btn-success btn-circle btn-sm"
      >
        <i className="fas fa-eye"></i>
      </a> : <p></p>
    )
  }

  const onPublish = (cell, row, enumObject, index) => {
    const token = uuidv4()
    return (
      row.publish ? <a
        href={`${process.env.REACT_APP_WEB_URL}/${row.slag_name}`}
        target="_blank"
        className="btn btn-success btn-circle btn-sm"
      >
        <i className="fas fa-eye"></i>
      </a> : <p></p>
    )
  }

  return (
    <div className="card-body">
      <Button onClick={() => props.history.push('joinus/form')} color="primary" className="custom-rigth btn btn-primary btn-sm" >เพิ่ม Static Page</Button>

      <BootstrapTable
        data={data}
        pagination={true}
        // options={options}
        className="table table-striped"
      >
        <TableHeaderColumn
          dataField="_id"
          dataFormat={rowindex}
          width="70"
          isKey
        >
          Index
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="slag_name"
          dataFormat={titleFormatter}
          width="200"
        >
          Title
        </TableHeaderColumn>
        
        {/* <TableHeaderColumn dataField="imageSrc" dataFormat={imageFormatter} width="500">Image</TableHeaderColumn> */}

        {/* <TableHeaderColumn dataField="url" width="200">Url</TableHeaderColumn> */}

        <TableHeaderColumn
          dataField="publish"
          dataFormat={activeFormatter}
          width="100"
        >
          Publish 
        </TableHeaderColumn>

        <TableHeaderColumn
          dataField="slag_name"
          width="70"
          dataFormat={onPreview}
        >
          Preview
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="slag_name"
          width="70"
          dataFormat={onPublish}
        >
          Url
        </TableHeaderColumn>
        {/* <TableHeaderColumn dataField="_id" width="100" dataFormat={deletebutton}>Delete</TableHeaderColumn> */}
      </BootstrapTable>
    </div>
  )
}
