import React, { useState, useEffect } from 'react'
import parse from 'html-react-parser'

import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Container,
  Alert,
  Col,
  Row,
} from 'reactstrap'
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import htmlToText from 'html-to-text'
import draftToHtml from 'draftjs-to-html'

import endpoint from '../../config/config-api'

import Axios from 'axios'

import DatePicker from 'react-datepicker'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'react-datepicker/dist/react-datepicker.css'

import Swal from 'sweetalert2'
const axios = Axios.create({
  withCredentials: true,
})
const Addjobdata = (props) => {
  const [jobdetail, Setjobdetail] = useState(EditorState.createEmpty())
  const [ability, Setjobability] = useState(EditorState.createEmpty())

  const [jobname, Setjobname] = useState(false)

  const [status, Setstatus] = useState(false)

  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const [salary, Setsalary] = useState(false)
  const [quantity, Setquantity] = useState(false)
  const [configBussiness, setConfigBussiness] = useState([])
  const [configContract, setConfigContract] = useState([])
  const [configEducation, setConfigEducation] = useState([])
  const [education, Seteducation] = useState({
    label: 'มัธยมศึกษาตอนต้น',
    value: 'secondary',
  })
  const [contract, Setcontract] = useState({
    label: 'พนักงานประจำ',
    value: 'fulltime',
  })
  const [business, Setbusiness] = useState({
    label: 'ทีวี',
    value: 'ทีวี',
  })

  const [jobdutystatus, Setjobdutystatus] = useState(true)
  const [jobabilitystatus, Setjobabilitystatus] = useState(true)

  const EditJobDetail = (jobdetail) => {
    Setjobdetail(jobdetail)
    let datatext = draftToHtml(convertToRaw(jobdetail.getCurrentContent()))
    if (htmlToText.fromString(datatext) !== '') {
      Setjobdutystatus(true)
    } else {
      Setjobdutystatus(false)
    }
  }

  const EditorJobAbility = (ability) => {
    Setjobability(ability)
    let datatext = draftToHtml(convertToRaw(ability.getCurrentContent()))
    if (htmlToText.fromString(datatext) !== '') {
      Setjobabilitystatus(true)
    } else {
      Setjobabilitystatus(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])
  const getData = async () => {
    const data = await axios.get(
      `${endpoint.apiTest}/v1.1/job/cms/jobAnnouncement/all`,
    )
    const configObj = data.data.results.config.joblist
    setConfigBussiness(configObj.business)
    setConfigContract(configObj.contract)
    setConfigEducation(configObj.education)
  }

  const OnSubmit = async () => {
    const htmlcheckjobduty = draftToHtml(
      convertToRaw(jobdetail.getCurrentContent()),
    )
    const htmlcheckjobability = draftToHtml(
      convertToRaw(ability.getCurrentContent()),
    )

    const jobdutytext = htmlToText.fromString(htmlcheckjobduty)
    const jobabilitytext = htmlToText.fromString(htmlcheckjobability)

    if (status === false) {
      Setstatus('')
    }
    if (jobname === false) {
      Setjobname('')
    }
    if (quantity === false) {
      Setquantity('')
    }
    // if (contract === false) {
    //   Setcontract('')
    // }
    if (salary === false) {
      Setsalary('')
    }
    if (jobdutytext === '') {
      Setjobdutystatus(false)
    }
    if (jobabilitytext === '') {
      Setjobabilitystatus(false)
    }

    if (
      status !== '' &&
      jobname !== '' &&
      quantity !== '' &&
      // contract !== '' &&
      salary !== '' &&
      jobdutytext !== '' &&
      jobabilitytext !== ''
    ) {
      if (
        status !== false &&
        jobname !== false &&
        quantity !== false &&
        // contract !== false &&
        salary !== false
      ) {
        const payload = {
          job_title: jobname,
          business: business,
          education: education,
          contract: contract,
          job_status: status,
          job_opendate: startDate,
          job_closedate: endDate,
          job_duty: draftToHtml(convertToRaw(jobdetail.getCurrentContent())),
          job_requirement: draftToHtml(
            convertToRaw(ability.getCurrentContent()),
          ),
          job_limit: quantity,
          salary: salary,
        }

        const linkApi = `${endpoint.apiTest}/v1.1/job/cms/jobAnnouncement/create`
        axios({
          method: 'post',
          url: linkApi,
          data: payload,
        })
          .then(
            function (response) {
              if (response) {
                Swal.fire({
                  icon: 'success',
                  title: 'บันทึกข้อมูลสำเร็จ',
                }).then((result) => {
                  if (result.isConfirmed) {
                    props.history.push('/announcement')
                  }
                })
              }
            }.bind(this),
          )

          .catch(function (error) {
            console.log(error)
          })
      }
    }
  }

  return (
    <Container>
      <Form>
        <Row>
          <Col xs="6" sm="4">
            <FormGroup>
              <Label for="exampleSelect">สายงาน</Label>
              <Input
                type="select"
                name="select"
                id="exampleSelect"
                onChange={(e) => {
                  const obj = configBussiness.find(
                    (f) => f.value === e.target.value,
                  )
                  Setbusiness({
                    label: obj.label,
                    value: obj.value,
                  })
                }}
              >
                {Object.values(configBussiness).map((items, key) => (
                  <option key={key} value={items.value}>
                    {items.label}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="6" sm="4">
            <FormGroup>
              <Label for="exampleText">ตำแหน่ง</Label>
              <Input
                type="text"
                name="text"
                id="exampleText"
                className={
                  jobname === '' ? 'is-invalid form-control' : 'form-control'
                }
                onChange={(e) => Setjobname(e.target.value)}
              />
              {jobname === '' && (
                // <span className="invalid-feedback">กรุณากรอกชื่อตำแหน่ง</span>
                <Alert color="danger">กรุณากรอกชื่อตำแหน่ง</Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="6" sm="4">
            <FormGroup>
              <Label for="exampleText">การศึกษา</Label>

              <Input
                type="select"
                name="select"
                id="exampleSelect"
                onChange={(e) => {
                  const obj = configEducation.find(
                    (f) => f.value === e.target.value,
                  )
                  Seteducation({
                    label: obj.label,
                    value: obj.value,
                  })
                }}
              >
                {Object.values(configEducation).map((items, key) => (
                  <option key={key} value={items.value}>
                    {items.label}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="6" sm="4">
            <FormGroup>
              <Label for="exampleText">ประเภทการจ้าง</Label>

              <Input
                type="select"
                name="select"
                id="exampleSelect"
                className={
                  contract === '' ? 'is-invalid form-control' : 'form-control'
                }
                onChange={(e) => {
                  const obj = configContract.find(
                    (f) => f.value === e.target.value,
                  )
                  Setcontract({
                    label: obj.label,
                    value: obj.value,
                  })
                }}
              >
                {Object.values(configContract).map((items, key) => (
                  <option key={key} value={items.value}>
                    {items.label}
                  </option>
                ))}
              </Input>
              {contract === '' && (
                // <span className="invalid-feedback">กรุณากรอกประเภทการจ้าง</span>
                <Alert color="danger">กรุณากรอกประเภทการจ้าง</Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="6" sm="4">
            <FormGroup tag="fieldset">
              สถานะ
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="radio1"
                    onClick={() => Setstatus(1)}
                  />{' '}
                  เปิด
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input
                    type="radio"
                    name="radio1"
                    onClick={() => Setstatus(2)}
                  />{' '}
                  ปิด
                </Label>
              </FormGroup>
              {status === '' && <Alert color="danger">กรุณาเลือกสถานะ</Alert>}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="8">
            รายละเอียดงาน
            <Editor
              editorState={jobdetail}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={EditJobDetail}
            />
            {jobdutystatus === false && (
              <Alert color="danger">กรุณาเขียนรายละเอียดงาน</Alert>
            )}
            ความสามารถที่ต้องการ
            <Editor
              editorState={ability}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={EditorJobAbility}
            />
            {jobabilitystatus === false && (
              <Alert color="danger">กรุณาเขียนความสามารถที่ต้องการ</Alert>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs="6" sm="4">
            เริ่มประกาศ
            <FormGroup>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                dateFormat="dd/MM/yyyy"
                endDate={endDate}
              />
            </FormGroup>
            สิ้นสุดประกาศ
            <FormGroup>
              <DatePicker
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                dateFormat="dd/MM/yyyy"
                endDate={endDate}
                minDate={startDate}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="6" sm="4">
            <FormGroup>
              <Label for="exampleText">จำนวนที่รับ(คน)</Label>
              <Input
                type="number"
                name="text"
                min={0}
                id="exampleText"
                className={
                  quantity === '' ? 'is-invalid form-control' : 'form-control'
                }
                onChange={(e) => Setquantity(e.target.value)}
              />
              {quantity === '' && (
                // <span className="invalid-feedback">กรุณากรอกจำนวนที่รับ</span>
                <Alert color="danger">กรุณากรอกจำนวนที่รับ</Alert>
              )}
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="6" sm="4">
            <FormGroup>
              <Label for="exampleText">เงินเดือน(บาท)</Label>
              <Input
                type="number"
                name="text"
                min={0}
                className={
                  salary === '' ? 'is-invalid form-control' : 'form-control'
                }
                id="exampleText"
                onChange={(e) => Setsalary(e.target.value)}
              />
              {salary === '' && (
                // <span className="invalid-feedback">กรุณากรอกเงินเดือนที่ต้องการ</span>
                <Alert color="danger">กรุณากรอกเงินเดือนที่ต้องการ</Alert>
              )}
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <div className="btn">
            <Button onClick={() => OnSubmit()}>Submit</Button>
          </div>
          <div className="btn">
            <Button onClick={() => props.history.push('/announcement')}>
              ย้อนกลับ
            </Button>
          </div>
        </Row>
      </Form>
    </Container>
  )
}

export default Addjobdata
