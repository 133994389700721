import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import endpoint from '../../../config/config-api'
import Axios from 'axios'
import resumeImg from '../../../assets/img/resume.png'
import porfolioImg from '../../../assets/img/portfolio.png'
import { Fragment } from "react"
const axios = Axios.create({withCredentials: true})

const Attachment = () => {

        let { id } = useParams()
        const [isAttachment , setStateIsAttachment] = useState(false)
        const [resume , setStateResume] = useState([])
        const [portfolio , setStatePortfolio] = useState([])

        useEffect(() => {
                fetchData()
        },[])

        const fetchData = async () => {
             

                const { data : {candidateData} } = await axios.get(`${endpoint.apiTest}/v1.1/job/cms/candidate/${id}`)
                setStateIsAttachment(candidateData.attachment !== undefined ? candidateData.attachment.isAttachment : false)
                setStateResume(candidateData.attachment !== undefined ? candidateData.attachment.resume :{})
                setStatePortfolio(candidateData.attachment !== undefined ? candidateData.attachment.portfolio :{})
        }

        const onDownloadFile = url => {
                window.location = url
        }

   
	return (
	<>
                {isAttachment === true ? 
                        <div className="table-style">
                        <table>
                        <thead>
                                <tr>
                                        <th colSpan="5">Resume</th>
                                </tr>
                        </thead>
                        <tbody>
                                {resume !== undefined && resume.map((items, index) => (
                                        <Fragment key={index}>
                                        <tr>
                                                <td><h5>{items.name}</h5></td>
                                                <img style={{ height: 150, width: 150 }} onClick={() => onDownloadFile(items.url)} src={resumeImg} />
                                        </tr>
                                        </Fragment>
                                ))}
                        </tbody>
                        </table>
                        <table>
                        <thead>
                                <tr>
                                        <th colSpan="5">Portfolio</th>
                                </tr>
                        </thead>
                        <tbody>
                                {portfolio !== undefined && portfolio.map((items, index) => (
                                        <Fragment key={index}>
                                        <tr>
                                                <td><h5>{items.name}</h5></td>
                                                <img style={{ height: 150, width: 150 }} onClick={() => onDownloadFile(items.url)} src={porfolioImg} />
                                        </tr>
                                        </Fragment>
                                ))}
                        </tbody>
                        </table>
                </div>
                :
                <div className="table-style">
                <table>
                <thead>
                        <tr>
                                <th colSpan="12">ไม่มีเอกสารที่แนบมา</th>
                        </tr>
                </thead>
                </table>
                
        </div>
                }
                
                
        </>
	)
}
export default Attachment
