import React from 'react'
import { Button } from 'reactstrap'



const Home = () => {
  console.log('Home', process.env.REACT_APP_API_URL)
  
  return (
    <>
      {/* <Button href={"/form"}color="primary" >
        +
      </Button>{' '}
    */}
      
    </>
  )
}

export default Home
