import React, { useEffect, useState } from "react"
import { Button, Form, Col } from "react-bootstrap"
import { useFormik } from "formik"
import User from '../../lib/admin'
import endpoint from '../../config/config-api'
import axios from 'axios'
import Swal from 'sweetalert2'
import { useHistory } from "react-router-dom"

const Login = () => {
	const history = useHistory()
	//const [isLoading, setLoading] = useState(false)
	// const [loginResult, setLoginResult] = useState(null)
	useEffect(() => {
		User.saveRedirect()
	}, [])

    const formik = useFormik({
		initialValues: {
			username: "",
			password: "",
			remember: false,
		},
		onSubmit: async (values) => {
			try {
				const linkApi = `${endpoint.apiTest}/v1.1/job/cms/login`
				axios({
					method: 'post',
					url: linkApi,
					data: {
						username : 	values.username,
						password : values.password,
						remember : values.remember
					},
					withCredentials:true
				}).then(function (response) {
					if(response.data.status === 200){
						User.saveCookie(response.data)

						history.push('/data')
						window.location.reload()
					
					  }else{
						Swal.fire({
							icon: 'error',
							title: response.data.message,
						})
					}
				}).catch(function (error) {
					console.log(error)
				})
			} catch (e) {
				console.log(`request failed: ${e}`)
			}
		},
	})

	return (
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-xl-10 col-lg-12 col-md-9">
					<div class="card o-hidden border-0 shadow-lg mt-10">
						<div class="card-body p-0">
							<div class="row">
								<div class="col-lg-6 d-none d-lg-block bg-login-image"></div>
								<div class="col-lg-6">
									<div class="p-5">
										<div class="text-center">
											<h1 class="h4 text-gray-900 mb-4">CMS-JOB-V5</h1>
										</div>

										<Form onSubmit={formik.handleSubmit}>
											{/* <p className="text-center">
												<lable className="text-danger">TEST</lable>
											</p> */}
											<Form.Row>
												<Form.Group as={Col}>
													<Form.Control
														placeholder="username"
														id="username"
														name="username"
														onChange={formik.handleChange}
														value={formik.values.username}
														className="form-control form-control-user"
													/>
												</Form.Group>
											</Form.Row>
											<Form.Row>
												<Form.Group as={Col}>
													<Form.Control
														type="password"
														placeholder="password"
														id="password"
														name="password"
														onChange={formik.handleChange}
														value={formik.values.password}
														className="form-control form-control-user"
													/>
												</Form.Group>
											</Form.Row>
											<Button variant="primary" type="submit" className="btn btn-primary btn-user btn-block">
												Login
											</Button>
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default Login
