export const contract = (data) => {
    return  `
    <html lang="en">
        <head>
                <!-- Required meta tags -->
                <meta charset="utf-8">
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
            </head>
        <body style="margin: 0; padding:0;">
                <div style="background: #f2f2f2; height: auto;">
                    <table style="max-width: 550px; margin: auto;border-collapse: collapse; background: #ffffff;">
                        <tbody>
                            <tr>
                                <td style="padding: 20px; padding-bottom: 0;">
                                    <h2 style="margin: 0; color: #00B400; font-size: 18px; font-weight:bold;font-family: Arial, Helvetica, sans-serif;border-bottom: 1px solid #D1D1D1; padding-bottom: 10px; margin-bottom: 10px;">Thairath Group :: ตรวจสุขภาพก่อนเริ่มงานและนัดหมายเซ็นสัญญา </h2>
                                    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">เรียนคุณ <i style="color:#00B400; font-style: inherit;">${data.name}</i></p>
                                    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">Thairath Group ขอแสดงความยินดีและจะแจ้งให้ทราบว่า ท่านเป็นผู้สมัครที่ผ่านการพิจารณาในตำแหน่ง ${data.position} โดยจะมีการนัดหมาย และรายละเอียดดังต่อไปนี้</p>
                                    <br/>
                                    <p style="font-size: 14px; font-weight:bold;font-family: Arial, Helvetica, sans-serif;border-bottom: 1px solid #D1D1D1; padding-bottom: 13px;margin: 0; margin-bottom: 10px;"><strong>1.กรอกข้อมูลส่วนตัว</strong></p>
                                    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">กรุณากรอกข้อมูลส่วนตัว ที่ <a href="${data.url}">Link</a>  นี้ก่อนวันเซ็นสัญญา</p>
                                    <br/>
                                    <p style="font-size: 14px; font-weight:bold;font-family: Arial, Helvetica, sans-serif;border-bottom: 1px solid #D1D1D1; padding-bottom: 13px;margin: 0; margin-bottom: 10px;"><strong>2. ข้อมูลเบื้องต้นสำหรับการเตรียมตัวตรวจสุขภาพ </strong></p>
                                    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">- นำเอกสารส่งตัวตามไฟล์แนบไปยื่นกับเจ้าหน้าที่ โรงพยาบาลเปาโลเกษตร (เท่านั้น) <a href="https://www.google.co.th/maps/place/%E0%B9%82%E0%B8%A3%E0%B8%87%E0%B8%9E%E0%B8%A2%E0%B8%B2%E0%B8%9A%E0%B8%B2%E0%B8%A5%E0%B9%80%E0%B8%9B%E0%B8%B2%E0%B9%82%E0%B8%A5+%E0%B9%80%E0%B8%81%E0%B8%A9%E0%B8%95%E0%B8%A3/@13.8353616,100.5720311,17z/data=!3m1!4b1!4m5!3m4!1s0x30e29cfd52166dd5:0xf057b7499ce78104!8m2!3d13.8353564!4d100.5742198"> Click to Google Map</a> ซึ่งมีค่าใช้จ่าย 890 บาท (ผู้สมัครชำระเอง) </p>
                                    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">- ตรวจได้ทุกวัน จันทร์ - อาทิตย์ เวลา 07.00 น. - 15.00 น. </p>
                                    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">- สถานที่ตรวจ อาคาร B ชั้น 1 แผนกตรวจสุขภาพ  </p>
    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">- งดน้ำงดอาหารก่อนตรวจ อย่างน้อย 8 ชม.   </p>
    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">- พักผ่อนให้เพียงพอ   </p>
    <br/>
                                    <p style="font-size: 14px; font-weight:bold;font-family: Arial, Helvetica, sans-serif;border-bottom: 1px solid #D1D1D1; padding-bottom: 13px;margin: 0; margin-bottom: 10px;"><strong>3. กำหนดการเซ็นสัญญาจ้าง </strong></p>
                                    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">- ${data.dateToSend} </p>
                                    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">- สถานที่ : สำนักงานใหญ่ไทยรัฐ อาคาร 12 ชั้น 5 เลขที่ 1 ถนนวิภาวดี-รังสิต แขวงจอมพล เขตจตุจักร กรุงเทพมหานคร 10900 <a href="https://www.google.co.th/maps/place/%E0%B8%AB%E0%B8%99%E0%B8%B1%E0%B8%87%E0%B8%AA%E0%B8%B7%E0%B8%AD%E0%B8%9E%E0%B8%B4%E0%B8%A1%E0%B8%9E%E0%B9%8C%E0%B9%84%E0%B8%97%E0%B8%A2%E0%B8%A3%E0%B8%B1%E0%B8%90+(%E0%B8%9A%E0%B8%A3%E0%B8%B4%E0%B8%A9%E0%B8%B1%E0%B8%97+%E0%B8%A7%E0%B8%B1%E0%B8%8A%E0%B8%A3%E0%B8%9E%E0%B8%A5+%E0%B8%88%E0%B8%B3%E0%B8%81%E0%B8%B1%E0%B8%94)/@13.8047918,100.5578341,18z/data=!3m1!4b1!4m8!1m2!2m1!1z4LmE4LiX4Lii4Lij4Lix4LiQ!3m4!1s0x0:0x4d68cb99c0a15e91!8m2!3d13.8047893!4d100.5588973">Click to Google Map</a>   </p>
                                    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">- ติดต่อ 02-127-1000 ต่อ 1079 คุณสุคนธ์   </p>
    <br/>
                                    <p style="font-size: 14px; font-weight:bold;font-family: Arial, Helvetica, sans-serif;border-bottom: 1px solid #D1D1D1; padding-bottom: 13px;margin: 0; margin-bottom: 10px;"><strong>4. เอกสารที่ต้องนำมาในวันเซ็นสัญญา </strong></p>
                                    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">- สำเนาบัตรประชาชน </p>
                                    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">- สำเนาทะเบียนบ้าน  </p>
                                    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">- สำเนาวุฒิการศึกษา (Transcript)   </p>
    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">- สำเนาหลักฐานการผ่านการเกณฑ์ทหาร    </p>
    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">- สำเนาเอกสารการเปลี่ยนชื่อ - นามสกุล(ถ้ามี)    </p>
    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">- ผลตรวจสุขภาพตัวจริง     </p>
    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">- สำเนาสมุดบัญชีธนาคารกรุงเทพ (เฉพาะสาขาถนนวิภาวดีรังสิต เท่านั้น) <a href="https://www.google.co.th/maps/place/%E0%B8%98%E0%B8%99%E0%B8%B2%E0%B8%84%E0%B8%B2%E0%B8%A3%E0%B8%81%E0%B8%A3%E0%B8%B8%E0%B8%87%E0%B9%80%E0%B8%97%E0%B8%9E+%E0%B8%AA%E0%B8%B2%E0%B8%82%E0%B8%B2%E0%B8%96%E0%B8%99%E0%B8%99%E0%B8%A7%E0%B8%B4%E0%B8%A0%E0%B8%B2%E0%B8%A7%E0%B8%94%E0%B8%B5%E0%B8%A3%E0%B8%B1%E0%B8%87%E0%B8%AA%E0%B8%B4%E0%B8%95/@13.8049478,100.5326245,14.32z/data=!4m8!1m2!2m1!1z4LiY4LiZ4Liy4LiE4Liy4Lij4LiB4Lij4Li44LiH4LmA4LiX4LieIOC4quC4suC4guC4siDguKfguLTguKDguLLguKfguJTguLU!3m4!1s0x0:0x9e845e250f6577b!8m2!3d13.8019495!4d100.5610371?hl=th">Click to Google Map</a></p>
    
    <br/>
                                    <p style="font-size: 14px; font-weight:bold;font-family: Arial, Helvetica, sans-serif;border-bottom: 1px solid #D1D1D1; padding-bottom: 13px;margin: 0; margin-bottom: 10px;"><strong>หมายเหตุ : </strong></p>
                                    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;"> วันเซ็นสัญญาจะมีการถ่ายรูปเพื่อทำบัตรพนักงาน ขอความร่วมมือในการแต่งกายอย่างสุภาพและเหมาะสม</p>
     <br/>     
    <br/>   
    <br/>                            
    
    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">ขอแสดงความนับถือ </p>
    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">ฝ่ายทรัพยากรบุคคล </p>
    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">Thairath Group</p>
    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">02-127-1000 ต่อ 1073</p>
    <br/>        <br/>       
                                </td>
                            </tr>
    
                            <tr>
                                <td style="background: #00B400; padding: 10px;vertical-align: middle;">
                                    <a href="https://www.thairath.co.th/home" style="display: inline-block; margin-right: 10px;vertical-align: middle;"><img style="max-width: 100%;" src="https://www.thairath.co.th/assets/member/tr-logo.png"/></a>
                                    <a href="http://www.facebook.com/Thairath" style="display: inline-block; margin-right: 10px;text-decoration: none;vertical-align: middle;"><img src="https://www.thairath.co.th/assets/member/facebook-icon.png"/></a>
                                    <a href="http://www.twitter.com/Thairath_News" style="display: inline-block; margin-right: 10px;text-decoration: none;vertical-align: middle;"><img src="https://www.thairath.co.th/assets/member/twitter-icon.png"/></a>
                                    <a href="https://www.youtube.com/user/thairathonline" style="display: inline-block; margin-right: 10px;text-decoration: none;vertical-align: middle;"><img src="https://www.thairath.co.th/assets/member/youtube-icon.png"/></a>
                                    <a href="https://www.thairath.co.th/faq/" style="display: inline-block; margin-right: 10px;text-decoration: none;vertical-align: middle; font-size: 12px; font-family: Arial, Helvetica, sans-serif; color: white;">FAQ</a>
                                    <a href="https://www.thairath.co.th/assets/thairath-privacy-policy-2018-02.pdf" style="display: inline-block; margin-right: 10px;text-decoration: none;vertical-align: middle; font-size: 12px; font-family: Arial, Helvetica, sans-serif; color: white;">นโยบายส่วนตัว</a>
                                    <a href="https://www.thairath.co.th/assets/thairath-terms-condition-2018-02.pdf" style="display: inline-block; text-decoration: none;vertical-align: middle; font-size: 12px; font-family: Arial, Helvetica, sans-serif; color: white;">เงื่อนไขข้อตกลง</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
        </body>
        </html>
    `
} 