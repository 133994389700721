import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import endpoint from '../../../config/config-api'
import Axios from 'axios'
import { propTypes } from "react-bootstrap/esm/Image"
import arrthai from "../widget/thaiword"
const axios = Axios.create({withCredentials: true})

const Other = (props) => {
        let { id } = useParams()

        const [military , setStateMilitary] = useState("-")
        const [sacked , setStateSacked] = useState("-")
        const [disability , setStateDisability] = useState("-")
        const [criminal  , setStateCriminal] = useState("-")
        const [inquire , setStateInquire] = useState("-")
        const [court , setStatecourt] = useState("-")
        const [guarantee , setStateguarantee]=useState("-")
        const [job_rotation,setStatejob_rotation]=useState("-")
        const [drug,setStatedrug]=useState("-")       
        const [shift,setStateshift]=useState("-")
        const [contagion,setStatecontagion]=useState("-")
        const [gender , setStateGender] = useState(false)
       
       

        useEffect(() => {
                fetchData()
        } ,[])
   

       

        const fetchData = async () => {
                const { data : {candidateData} } = await axios.get(`${endpoint.apiTest}/v1.1/job/cms/candidate/${id}`)
                console.log('question => ', candidateData.question)
                candidateData.information.personal ? candidateData.information.personal.sex === "male" ? setStateGender(true) : setStateGender(false) :  setStateGender(false) 
             
                setStateMilitary(candidateData.information.personal  ? candidateData.information.personal.military :" - ")
                
                setStateDisability(candidateData.question !== undefined ? candidateData.question.op01 :" - ")
                setStatecontagion(candidateData.question !== undefined ? candidateData.question.op02 :" - ")
                setStatedrug(candidateData.question !== undefined ? candidateData.question.op03 :" - ")
                setStatecourt(candidateData.question !== undefined ? candidateData.question.op04 :" - ")
                setStateCriminal(candidateData.question !== undefined ? candidateData.question.op05 :" - ")
                setStateshift(candidateData.question !== undefined ? candidateData.question.op06 :" - ")
                setStateguarantee(candidateData.question !== undefined ? candidateData.question.op07 :" - ")
                setStatejob_rotation(candidateData.question !== undefined ? candidateData.question.op08 :" - ")
                setStateSacked(candidateData.question !== undefined ? candidateData.question.op09 :" - ")
                setStateInquire(candidateData.question !== undefined ? candidateData.question.op10 :" - ")
        }

	return (
		<>
                <div className="table-style">
                        {gender === true ?
                                <table>
                                <thead>
                                        <tr>
                                                <th>สถานภาพทางทหาร</th>
                                        </tr>
                                </thead>
                                <tbody>
                                        <tr>
                                                <td>
                                                        <h5><strong>สถานะ : </strong> {military}</h5>
                                                </td>
                                        </tr>
                                </tbody>
                        </table>
                        :""}
                        <table>
                                <thead>
                                        <tr>
                                                <th colSpan="2">อื่นๆ</th>
                                        </tr>
                                </thead>
                                <tbody>
                                     
                                        <tr>
                                                <td><h5>คุณมีร่างกายทุพพลภาพ หรืออวัยวะส่วนใดส่วนหนึ่งพิการ หรือมีโรคประจำตัวเรื้อรังหรือไม่ </h5></td>
                                                <td width="200px"><h5 className="text-center">{disability==="yes"&&"มี"} {disability==="no"&&"ไม่มี"}</h5></td>
                                        </tr>
                                        <tr>
                                                <td><h5>คุณเคยป่วยหนัก และเป็นโรคติดต่อร้ายแรงหรือไม่</h5></td>
                                                <td width="200px"><h5 className="text-center">{contagion==="yes"&&"เคย" }{contagion==="no"&&"ไม่เคย"}</h5></td>
                                        </tr>
                                        <tr>
                                                <td><h5>คุณเคยติดยาเสพติด หรือเป็นโรคร้ายแรงหรือไม่</h5></td>
                                                <td width="200px"><h5 className="text-center">{drug==="yes"&&"เคย" }{drug==="no"&&"ไม่เคย"}</h5></td>
                                        </tr>
                                        <tr>
                                                <td><h5>คุณเคยต้องโทษ หรือเป็นคดีความขึ้นศาลหรือไม่</h5></td>
                                                <td width="200px"><h5 className="text-center">{court==="yes"&&"เคย" }{ court==="no"&&"ไม่เคย"}</h5></td>
                                        </tr>
                                        <tr>
                                                <td><h5>คุณเคยถูกจับ และต้องโทษคดีอาญาหรือไม่</h5></td>
                                                <td width="200px"><h5 className="text-center">{criminal==="yes"&&"เคย" }{criminal==="no"&&"ไม่เคย"}</h5></td>
                                        </tr>
                                        <tr>
                                                <td><h5>คุณสามารถปฎิบัติงานเป็นกะหมุนเวียนกันไปได้หรือไม่</h5></td>
                                                <td width="200px"><h5 className="text-center">{shift==="yes"&&"ได้" }{ shift==="no"&&"ไม่ได้"}</h5></td>
                                        </tr>
                                        <tr>
                                                <td><h5>คุณสามารถหาหลักประกันในตำแหน่งงานที่ต้องมีหลักประกันได้หรือไม่</h5></td>
                                                <td width="200px"><h5 className="text-center">{guarantee==="yes"&&"ได้" }{ guarantee==="no"&&"ไม่ได้"}</h5></td>
                                        </tr>
                                        <tr>
                                                <td><h5>ในการปฎิบัติงาน คุณยินยอมให้เปลี่ยนแปลงตำแหน่งหน้าที่ได้ตามความเหมาะสมได้หรือไม่</h5></td>
                                                <td width="200px"><h5 className="text-center">{job_rotation==="yes"&&"ยินยอม"}{ job_rotation==="no"&&"ไม่ยินยอม"}</h5></td>
                                        </tr>
                                        <tr>
                                                <td><h5>คุณเคยถูกให้ออกจากงานเนื่องจากความประพฤติ หรืองานไม่เป็นที่พอใจของนายจ้างหรือไม่</h5></td>
                                                <td width="200px"><h5 className="text-center">{sacked==="yes"&&"เคย" }{ sacked==="no"&&"ไม่เคย"}</h5></td>
                                        </tr>
                                        <tr>
                                                <td><h5>คุณจะยินดีให้เราสอบถามไปยังบริษัทที่ท่านทำงานอยู่ในขณะนี้ และในอดีตเกี่ยวกับคุณสมบัติ หรือนิสัยใจคอของท่านหรือไม่</h5></td>
                                                <td width="200px"><h5 className="text-center">{inquire==="yes"&&"ยินยอม" }{ inquire==="no"&&"ไม่ยินยอม"}</h5></td>
                                        </tr>
                                   
                                     
                                </tbody>
                        </table>
                        {/* <table>
                                <thead>
                                        <tr>
                                                <th colSpan="4">ใบขับขี่</th>
                                        </tr>
                                </thead>
                                <tbody>
                                        <tr>
                                                <td width="25%"><h5><strong>รถยนต์ : </strong> </h5></td>
                                                <td width="25%"><h5><strong>จักรยานยนต์ : </strong> </h5></td>
                                                <td width="25%"><h5><strong>พาหนะที่ใช้ : </strong> </h5></td>
                                                <td width="25%"><h5><strong>งานอดิเรก : </strong> </h5></td>
                                        </tr>
                                        <tr>
                                                <td colSpan="2"><h5><strong>กีฬา : </strong> </h5></td>
                                                <td colSpan="2"><h5><strong>ท่านทราบว่าบริษัทฯต้องการจ้างงานจาก : </strong> </h5></td>
                                        </tr>

                                </tbody>
                        </table> */}
                </div>
        </>
	)
}
export default Other
