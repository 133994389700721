import React, { Fragment } from 'react'

import Homepage from '../pages/Home/home'
import Addjobdata from '../pages/AddJob/addjob'
import CandidatePage from '../pages/Candidate/List'
import AnnouncementPage from '../pages/Announcement/List'
import EditPage from '../pages/EditJob/editjob'
import DepartmentPage from '../pages/department/departmentlist'
import EditDepartmentPage from '../pages/department/edit'
import AddDepartmentPage from '../pages/department/add'
import ShowCandidatePage from '../pages/Candidate/show'
import ChangePasswordPage from '../pages/ChangePassword/changePassword'
import PanoramaPage from '../pages/Panorama'
import FormPanoramaPage from '../pages/Panorama/Form'
import ContactPage from '../pages/contactMail'
import FormContactPage from '../pages/contactMail/Form'
import TemplateMailPage from '../pages/Template'
import FormTemplateMailPage from '../pages/Template/Form'
import JoinUsPage from '../pages/StaticPages'
import FormJoinUsPage from '../pages/StaticPages/Form'
import EditHomePage from '../pages/EditHomePage'
import ActivitiesForm from '../pages/EditHomePage/ActivitiesForm'
import TestimonialForm from '../pages/EditHomePage/TestimonialForm'

import { Route, Redirect, Switch } from 'react-router-dom'

const Job = () => {
  return <h2>job</h2>
}

const NotFound = () => {
  return <h2>Not found 404</h2>
}

const Dashboard = () => {
  return <h2>dashboard</h2>
}


const Routing = () => {
  return (
    <Switch >
      <Route exact path="/" component={Homepage} />
      <Route exact path='/EditHomePage' component={EditHomePage} />
      <Route exact path='/EditHomePage/ActivitiesForm' component={ActivitiesForm} />
      <Route exact path='/EditHomePage/ActivitiesForm/:id' component={ActivitiesForm} />
      <Route exact path='/EditHomePage/TestimonialForm' component={TestimonialForm} />
      <Route exact path='/EditHomePage/TestimonialForm/:id' component={TestimonialForm} />
      {/* <Route exact path='/EditHomePage/InternshipForm' component={InternshipForm} /> */}

      {/* <Route path="/logout" component={Logout} /> */}
      <Route path="/data" component={CandidatePage} />
      <Route path="/announcement" component={AnnouncementPage} />
      <Route path="/job" component={Job} />
      <Route path="/form" component={Addjobdata} />
      <Route path="/changePassword" component={ChangePasswordPage} />
      <Route path="/edit/:id" component={EditPage} />
      <Route path="/show/:id" component={ShowCandidatePage} />

      <Route exact path="/department" component={DepartmentPage} />
      <Route path="/department/edit/:id" component={EditDepartmentPage} />
      <Route path="/department/add" component={AddDepartmentPage} />

      <Route exact path="/panorama" component={PanoramaPage} />

      <Route exact path="/panorama/form" component={FormPanoramaPage} />
      <Route exact path="/panorama/form/:id" component={FormPanoramaPage} />

      <Route exact path="/contact" component={ContactPage} />
      <Route exact path="/contact/form" component={FormContactPage} />
      <Route exact path="/contact/form/:id" component={FormContactPage} />

      <Route exact path="/joinus" component={JoinUsPage} />
      <Route exact path="/joinus/form" component={FormJoinUsPage} />
      <Route exact path="/joinus/form/:id" component={FormJoinUsPage} />

      <Route exact path="/dashboard" component={Dashboard} />

      <Route exact path='/template' component={TemplateMailPage} />
      <Route exact path='/template/form' component={FormTemplateMailPage} />
      <Route exact path='/template/form/:id' component={FormTemplateMailPage} />
      
      <Route
        path='/404'
        component={NotFound}
      />

      <Redirect to='/404' />
    </Switch>
  )
}

export default Routing
