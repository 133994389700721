export const reject = (data) => {
    return ` <html lang="en">
    <head>
            <!-- Required meta tags -->
            <meta charset="utf-8">
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
        </head>
    <body style="margin: 0; padding:0;">
            <div style="background: #f2f2f2; height: auto;">
                <table style="max-width: 550px; margin: auto;border-collapse: collapse; background: #ffffff;">
                    <tbody>
                        <tr>
                            <td style="padding: 20px; padding-bottom: 0;">
                                <h2 style="margin: 0; color: #00B400; font-size: 18px; font-weight:bold;font-family: Arial, Helvetica, sans-serif;border-bottom: 1px solid #D1D1D1; padding-bottom: 10px; margin-bottom: 10px;">Thairath Group :: แจ้งผลการสัมภาษณ์ตำแหน่ง ${data.position}</h2>
                                <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">คุณ <i style="color:#00B400; font-style: inherit;">${data.name}</i></p>
                                <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">ตามที่บริษัทได้เชิญท่านเข้ารับการสัมภาษณ์ไปแล้วนั้น ทางบริษัทขอแจ้งให้ทราบว่า <br/><br/> ท่านยังไม่ผ่านการพิจารณา เนื่องจากขณะนี้ยังไม่มีตำแหน่งที่เหมาะสมกับคุณสมบัติของท่าน บริษัทขอขอบคุณและหวังเป็นอย่างยิ่งว่า 
    จะได้รับการพิจารณาจากท่านอีกครั้ง เพื่อเชิญเข้ามาได้รับการสัมภาษณ์ ในตำแหน่งที่เหมาะสมในลำดับต่อไป 
    ท่านสามารถคลิกที่นี่เพื่อดูตำแหน่งว่างอื่นๆ <a href="${data.url}">Link</a>
    
    </p>
                               
    <br/>                            
    
    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">ขอแสดงความนับถือ </p>
    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">ฝ่ายทรัพยากรบุคคล </p>
    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">Thairath Group</p>
    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 14px; margin-bottom: 5px;">02-127-1000 ต่อ 1073</p>
    <br/>        <br/>       
                            </td>
                        </tr>
    
                        <tr>
                            <td style="background: #00B400; padding: 10px;vertical-align: middle;">
                                <a href="https://www.thairath.co.th/home" style="display: inline-block; margin-right: 10px;vertical-align: middle;"><img style="max-width: 100%;" src="https://www.thairath.co.th/assets/member/tr-logo.png"/></a>
                                <a href="http://www.facebook.com/Thairath" style="display: inline-block; margin-right: 10px;text-decoration: none;vertical-align: middle;"><img src="https://www.thairath.co.th/assets/member/facebook-icon.png"/></a>
                                <a href="http://www.twitter.com/Thairath_News" style="display: inline-block; margin-right: 10px;text-decoration: none;vertical-align: middle;"><img src="https://www.thairath.co.th/assets/member/twitter-icon.png"/></a>
                                <a href="https://www.youtube.com/user/thairathonline" style="display: inline-block; margin-right: 10px;text-decoration: none;vertical-align: middle;"><img src="https://www.thairath.co.th/assets/member/youtube-icon.png"/></a>
                                <a href="https://www.thairath.co.th/faq/" style="display: inline-block; margin-right: 10px;text-decoration: none;vertical-align: middle; font-size: 12px; font-family: Arial, Helvetica, sans-serif; color: white;">FAQ</a>
                                <a href="https://www.thairath.co.th/assets/thairath-privacy-policy-2018-02.pdf" style="display: inline-block; margin-right: 10px;text-decoration: none;vertical-align: middle; font-size: 12px; font-family: Arial, Helvetica, sans-serif; color: white;">นโยบายส่วนตัว</a>
                                <a href="https://www.thairath.co.th/assets/thairath-terms-condition-2018-02.pdf" style="display: inline-block; text-decoration: none;vertical-align: middle; font-size: 12px; font-family: Arial, Helvetica, sans-serif; color: white;">เงื่อนไขข้อตกลง</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
    </body>
    </html>`
} 