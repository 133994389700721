import React, { useState, useEffect, useRef } from 'react'
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import { Link } from 'react-router-dom'
import 'moment-timezone'
import DateUtil from '../../lib/Dateutil'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import Axios from 'axios'
import endpoint from '../../config/config-api'
import arrthai from '../Candidate/widget/thaiword'
import Select from 'react-select'
import province_option from '../Candidate/widget/province'
import 'semantic-ui-css/semantic.min.css'
import { Pagination } from 'semantic-ui-react'
import '../../assets/css/paginationlist.css'

import {
  Button,
  Input,
  Col,
  Label,
  FormGroup,
  Container,
  Table,
  Row,
} from 'reactstrap'
import { set } from 'mongoose'

import * as moment from 'moment'
import 'moment/locale/th'
import Swal from 'sweetalert2'
const axios = Axios.create({withCredentials: true})
var ReactBsTable = require('react-bootstrap-table')
var BootstrapTable = ReactBsTable.BootstrapTable
var TableHeaderColumn = ReactBsTable.TableHeaderColumn

const List = () => {
  const [data, setData] = useState('')
  const inputRef = useRef(null)
  const [searchAll, setSearchAll] = useState('')
  const [allPage, setallPage] = useState('')
  const [isSearch, setIsSearch] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [compare, setcompare] = useState([])
  const [printStatus, setPrintStatus] = useState('')
  const [registerDateGte, setRegisterDateGte] = useState('')
  const [registerDateLt, setRegisterDateLt] = useState('')
  const [gender, setGender] = useState('')
  const [orderBy, setOrderBy] = useState('')
  const [province, setProvince] = useState('')
  const [salaryGte, setSalaryGte] = useState(0)
  const [salaryLt, setSalaryLt] = useState(0)
  const [joblist ,setJobList] = useState([])
  const [jobListValue , setJobListValue] = useState(0)

  const selectCandidate = (cell) => {
    return (
      <>
        <Link to={'show/' + cell} className="btn btn-success btn-circle btn-sm">
          <i className="fas fa-eye"></i>
        </Link>
      </>
    )
  }

  const formatFormStatus = (cell) => {
    return <div>{cell}</div>
  }

  const carlendarRegisterDateLt = (date) => {
    var d1 = new Date(date)
    var d2 = new Date(registerDateGte)
    var comparedate = d1.getTime() < d2.getTime()

    if (comparedate) {
      setRegisterDateLt(new Date())
      setRegisterDateGte(new Date())
    } else {
      setRegisterDateLt(date)
    }
  }
  const carlendarRegisterDateGte = (date) => {
    var d1 = new Date(date)
    var d2 = new Date(registerDateLt)
    var comparedate = d1.getTime() > d2.getTime()
    if (comparedate) {
      setRegisterDateGte(new Date())
    } else {
      setRegisterDateGte(date)
    }
  }
  const CompareButton = (cell) => {
    const findchecked = [...compare].find((element) => element.id === cell)
      ? true
      : false

    localstore(compare)

    return (
      <FormGroup check>
        <Input
          type="checkbox"
          name="check"
          id="exampleCheck"
          checked={findchecked}
          onChange={(e) => compareCandidate(cell, e.target.checked)}
        />
      </FormGroup>
    )
  }

  const localstore = (data) => {
    localStorage.setItem('comparedata', JSON.stringify(data))
  }

  const compareCandidate = async (cell, check) => {
    const { data } = await axios.get(
      `${endpoint.apiTest}/v1.1/job/cms/candidate/${cell}`,
    )
  
    const newObj = {
      id: data.candidateData._id,
      name:
        data.candidateData.information.general.firstname +
        ' ' +
        data.candidateData.information.general.lastname,

      position: data.candidateData.position.position1.name,
      salary: data.candidateData.position.salary,
      degree: data.candidateData.education.education_level,
      startdate: data.candidateData.position.startdate,

      image: data.candidateData.information.general.image?.url,

      phone: data.candidateData.information.general.phone,

      height: data.candidateData.information.personal?.height,
      nationality: data.candidateData.information.personal?.nationality,

      military: data.candidateData.information.personal?.military,
      address: data.candidateData.information.personal?.address.address,
      tumbon: data.candidateData.information.personal?.address.tumbon,
      amphoe: data.candidateData.information.personal?.address.amphoe,
      province: data.candidateData.information.personal?.address.province,
      postcode: data.candidateData.information.personal?.address.postcode,
      race: data.candidateData.information.personal?.race,
      religion: data.candidateData.information.personal?.religion,
      sex: data.candidateData.information.personal?.sex,

      status: data.candidateData.information.personal?.status,
      weight: data.candidateData.information.personal?.weight,

      age: data.candidateData.information.personal?.age,
      birthdate: data.candidateData.information.personal?.birthdate,

      email: data.candidateData.information.general.email,

      education: data.candidateData.education,
      language: data.candidateData.languages,

      training: data.candidateData.experiences.trainee,
      company: data.candidateData.experiences.works,
    }

    if (check === true) {
      setcompare((compare) => [...compare, newObj])
    } else {
      const index = [...compare].findIndex(
        (element) => element.id === newObj.id,
      )
      const array = [...compare]

      array.splice(index, 1)
      setcompare(array)
    }
  }
  const cleardataCompare = () => {
    setcompare([])
  }

  const datachange = (data_format, pagedata = 1) => {
    const newArr = []
    console.log('data==>');
    console.log(data_format);
    data_format.data.data.map((items, key) => {
      
      const day = new Date(items.create_date).getDate()
      const month = new Date(items.create_date).getMonth() + 1
      const year = new Date(items.create_date).getFullYear()

      const hour = new Date(items.create_date).getHours()
      const minute = new Date(items.create_date).getMinutes()
      let print_status, gender, form_status

      if (items.print_status === 'notPrint') {
        print_status = 'ยังไม่ได้พิมพ์ใบสมัคร'
      } else {
        print_status = items.print_status
      }

      if (!items.information.personal) {
        gender = '-'
      } else {
        if (items.information.personal.sex === 'male') {
          gender = 'ชาย'
        } else {
          gender = 'หญิง'
        }
      }


      switch (items.form_status) {
        case 'form1_approve':
          form_status = 'นัดเซ็นสัญญา'
          break
        case 'reject':
          form_status = 'ปฎิเสธข้อเสนองาน'
          break
        case 'interview':
          form_status = 'นัดสัมภาษณ์'
          break

        default:
          form_status = '-'
          break
      }

      const newObj = {
        _id: items._id,
        count: key + (1 + 25 * (pagedata - 1)),
        name:
          items.information.general.firstname +
          ' ' +
          items.information.general.lastname,
        gender: gender,
        position: items.position.position1.name,
        salary: items.position.salary,
        printStatus: print_status,
        formStatus: form_status,
        stepStatus: items.step_status,
        createDate: day + '/' + month + '/' + year + ' ' + hour + ':' + minute,
        editor: items.editor,
      }

      newArr.push(newObj)
    })

    setData(newArr)
   data_format.data.joblist.map((item) => {
      item['value'] = item['job_id'];
      item['label'] = item['job_title'];
      delete item['job_id'];
      delete item['job_title'];
      return item;
    })

    setJobList(data_format.data.joblist)
  }

  const getData = async () => {
    const { data } = await axios.get(
      `${endpoint.apiTest}/v1.1/job/cms/candidate/search?v=` +
        Math.random() * 1000,
    )

    setallPage(data.data.pages)
    datachange(data)
  }

  const searchButton = async () => {
    let searchQuery = ''

    if (searchAll.length > 0) {
      searchQuery += `&searchAll=${searchAll}`
    }
    if (registerDateGte.length > 0 && registerDateLt.length > 0) {
      searchQuery += `&registerDateGte=${registerDateGte}&registerDateLt=${registerDateLt}`
    }
    if (
      salaryGte > 0 &&
      salaryLt > 0 &&
      parseInt(salaryLt) > parseInt(salaryGte)
    ) {
      searchQuery += `&salaryGte=${salaryGte}&salaryLt=${salaryLt}`
    }

    if (gender !== 'all' && gender.length > 0) {
      searchQuery += `&gender=${gender}`
    }
    if (province.length > 0) {
      searchQuery += `&province=${province}`
    }

    if (jobListValue !==  0) {
      searchQuery += `&joblist=${jobListValue}`
    }


    if (printStatus !== 'all' && printStatus.length > 0) {
      searchQuery += `&printStatus=${printStatus}`
    }
    if (orderBy !== 'all' && orderBy.length > 0) {
      searchQuery += `&orderData=${orderBy}`
    }

    setIsSearch(true)
    const { data } = await axios.get(
      `${endpoint.apiTest}/v1.1/job/cms/candidate/search?${searchQuery}`,
    )
    console.log(
      `${endpoint.apiTest}/v1.1/job/cms/candidate/search?${searchQuery}`,
    )
    if (data.status === 200) {
      setallPage(data.data.pages)
      setActivePage(data.data.currentPage)
      datachange(data)
    } else {
      setData([])
    }
  }

  const pagination = async (e, pageInfo) => {
    let searchQuery = ''

    if (searchAll.length > 0) {
      searchQuery += `&searchAll=${searchAll}`
    }
    if (registerDateGte.length > 0 && registerDateLt.length > 0) {
      searchQuery += `&registerDateGte=${registerDateGte}&registerDateLt=${registerDateLt}`
    }
    if (
      salaryGte > 0 &&
      salaryLt > 0 &&
      parseInt(salaryLt) > parseInt(salaryGte)
    ) {
      searchQuery += `&salaryGte=${salaryGte}&salaryLt=${salaryLt}`
    }

    if (gender !== 'all' && gender.length > 0) {
      searchQuery += `&gender=${gender}`
    }
    if (province.length > 0) {
      searchQuery += `&province=${province}`
    }

    if (printStatus !== 'all' && printStatus.length > 0) {
      searchQuery += `&printStatus=${printStatus}`
    }
    if (orderBy !== 'all' && orderBy.length > 0) {
      searchQuery += `&orderData=${orderBy}`
    }

    setActivePage(pageInfo.activePage)

    if (isSearch !== true) {
      const { data } = await axios.get(
        `${endpoint.apiTest}/v1.1/job/cms/candidate/search?page=${pageInfo.activePage}`,
      )

      datachange(data, pageInfo.activePage)
    } else {
      const { data } = await axios.get(
        `${endpoint.apiTest}/v1.1/job/cms/candidate/search?${searchQuery}&page=${pageInfo.activePage}`,
      )

      datachange(data, pageInfo.activePage)
    }
  }

  const deleteCompare = (index) => {
    const array = [...compare]

    array.splice(index, 1)
    setcompare(array)
  }
  const handleChange = (e) => {
    setProvince(e.value)
  }

  const handleChangeJobList = (e) => {
    setJobListValue(e.value)
  }
  useEffect(() => {
    getData()

    const data = JSON.parse(localStorage.getItem('comparedata'))
    if (data !== null) {
      setcompare(data)
    } else {
      setcompare([])
    }
  }, [])

  const getAdminToken = async (data) => {
    const { _id, stepStatus } = data 
    if(stepStatus === "สมบูรณ์"){
      const { data } = await axios.post(
        `${endpoint.apiTest}/v1.1/job/cms/admin/edit/${_id}`
      )

      window.open(`${process.env.REACT_APP_WEB_URL}/job/application/?token=${data.response.admin_token}`)
    }else{
      Swal.fire({
        icon: 'error',
        title: ' ไม่สามารถแก้ไขข้อมูลได้',
        text: 'สถานะกรอกข้อมูลของผู้สมัครยังไม่ "สมบูรณ์"',
      })
    }

  }

  const selectEdit = (cell, row) => {
    return (
      <div
        className="btn btn-warning btn-circle btn-sm"
        style={{ cursor: 'pointer' }}
        onClick={() => getAdminToken(row) }
      >
        <i className="fas fa-edit"></i>
      </div>
    )
  }

  return (
    <>
      <div className="card shadow mb-4">
        <div className="card-header py-3">
          <h5 className="m-0 font-weight-bold text-primary">
            จัดการข้อมูลผู้สมัคร
          </h5>
        </div>
        <Container>
          <br />
          <Row>
            <div className="col-8 col-md-6 offset-2 ">
              <div className="input-group md-form form-sm form-2 pl-0">
                <input
                  className="form-control my-0 py-1 lime-border"
                  type="text"
                  placeholder="ค้นหา ชื่อ-นามสกุล,ตำแหน่งที่สมัคร,อีเมล"
                  aria-label="Search"
                  onChange={(e) => setSearchAll(e.target.value)}
                />
              </div>
            </div>
            <p>
              <button
                className="btn btn-success btn-icon-split"
                disabled={false}
                onClick={() => searchButton()}
              >
                <span className="icon text-white-50">
                  <i className="fas fa-search"></i>
                </span>
                <span className="text">ค้นหาข้อมูล</span>
              </button>
              &nbsp;
              <button
                className="btn btn-primary"
                type="button"
                data-toggle="collapse"
                data-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Advance
              </button>
            </p>
            <div className="container">
              <div className="collapse" id="collapseExample">
                <div className="card card-body">
                  <div className="col-2">วันที่สมัคร</div>
                  <div className="col-10">
                    <div className="row">
                      <div className="col-2">
                        <Datetime
                          closeOnSelect={true}
                          dateFormat="YYYY-MM-DD"
                          timeFormat={false}
                          value={registerDateGte}
                          onChange={(date) => {
                            carlendarRegisterDateGte(
                              moment(date).format('YYYY-MM-DD'),
                            )
                          }}
                        />
                      </div>
                      <div>ถึง</div>
                      <div className="col-2">
                        <Datetime
                          closeOnSelect={true}
                          dateFormat="YYYY-MM-DD"
                          timeFormat={false}
                          value={registerDateLt}
                          onChange={(date) => {
                            carlendarRegisterDateLt(
                              moment(date).format('YYYY-MM-DD'),
                            )
                          }}
                        />
                      </div>
                      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      <div className="form-check col-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="all"
                          value="all"
                          onChange={(e) => setPrintStatus(e.target.value)}
                        />
                        <label className="form-check-label" htmlFor="all">
                          ทั้งหมด
                        </label>
                      </div>
                      <div className="form-check  col-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="print"
                          value="พิมพ์ใบสมัครแล้ว"
                          onChange={(e) => setPrintStatus(e.target.value)}
                        />
                        <label className="form-check-label" htmlFor="print">
                          พิมพ์ใบสมัครแล้ว
                        </label>
                      </div>
                      <div className="form-check  col-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="notPrint"
                          value="ยังไม่ได้พิมพ์ใบสมัคร"
                          onChange={(e) => setPrintStatus(e.target.value)}
                        />

                        <label className="form-check-label" htmlFor="notPrint">
                          ยังไม่ได้พิมพ์ใบสมัคร
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-2">จังหวัด</div>
                  <div className="col-10">
                    <div className="row">
                      <div className="col-4">
                        <Select
                          value={province_option.find(
                            (obj) => obj.value === province,
                          )}
                          options={province_option}
                          onChange={handleChange}
                        ></Select>
                      </div>
                      <div />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                      &nbsp; &nbsp;
                      <div className="form-check col-2">
                        <input
                          className="form-check-input gender"
                          type="radio"
                          name="exampleRadios gender"
                          id="all"
                          value="all"
                          onChange={(e) => setGender(e.target.value)}
                        />
                        <label className="form-check-label" htmlFor="all">
                          ทั้งหมด
                        </label>
                      </div>
                      <div className="form-check  col-2">
                        <input
                          className="form-check-input gender"
                          type="radio"
                          name="exampleRadios gender"
                          id="male"
                          value="male"
                          onChange={(e) => setGender(e.target.value)}
                        />
                        <label className="form-check-label" htmlFor="male">
                          ชาย
                        </label>
                      </div>
                      <div className="form-check  col-2">
                        <input
                          className="form-check-input gender"
                          type="radio"
                          name="exampleRadios gender"
                          id="female"
                          value="female"
                          onChange={(e) => setGender(e.target.value)}
                        />
                        <label className="form-check-label" htmlFor="female">
                          หญิง
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-2">ตำแหน่งที่สมัคร</div>
                  <div className="col-10">
                    <div className="row">
                      <div className="col-4">
                        
                         
                        <Select
                          value={joblist.find(
                            (obj) => obj.value === jobListValue,
                          )}
                          options={joblist}
                          onChange={handleChangeJobList}
                        ></Select>

                      </div>

                      <div />
                    </div>
                  </div>
                  <div className="col-2">เงินเดือน</div>
                  <div className="col-10">
                    <div className="row">
                      <div className="col-2">
                        <input
                          className="form-control"
                          type="number"
                          min="0"
                          onChange={(e) => setSalaryGte(e.target.value)}
                        />
                      </div>
                      ถึง
                      <div className="col-2">
                        <input
                          className="form-control"
                          type="number"
                          min="0"
                          onChange={(e) => setSalaryLt(e.target.value)}
                        />
                      </div>
                      <div />
                    </div>
                    {parseInt(salaryGte) > parseInt(salaryLt) && (
                      <div className="alert alert-warning" role="alert">
                        จำนวนเงินเดือนช่องแรกต้องน้อยกว่า
                      </div>
                    )}
                  </div>
                  <div className="col-2">Order By</div>
                  <div className="col-10">
                    <div className="row">
                      <div className="col-2">
                        <div className="input-group">
                          <select
                            className="custom-select"
                            id="inputGroupSelect01"
                            onChange={(e) => setOrderBy(e.target.value)}
                          >
                            <option value="all" selected>
                              กรุณาเลือก
                            </option>
                            <option value="create_date">วันที่สมัคร</option>
                            <option value="update_date">วันที่อัพเดท</option>
                          </select>
                        </div>
                      </div>

                      <div />
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </Row>
        </Container>
        <br></br>

        {compare.length !== 0 && (
          <Container>
            <div className="table-compare">
              <table>
                <tr>
                  <th className="headcol">
                    <h5>
                      <strong>รูป</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      <img
                        style={{ height: 300, width: 300 }}
                        src={data.image}
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="headcol">
                    <h5>
                      <strong>ชื่อ-นามสกุล</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      <h5>{data.name}</h5>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="headcol">
                    <h5>
                      <strong> ตำแหน่ง</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      <h5>{data.position}</h5>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="headcol">
                    <h5>
                      <strong> เงินเดือนที่ต้องการ</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      <h5>{data.salary}</h5>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="headcol">
                    <h5>
                      <strong> เริ่มปฏิบัติงานได้</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      <h5>{DateUtil.convertEnToTh(data.startdate)}</h5>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="headcol">
                    <h5>
                      <strong>เพศ</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      <h5>{arrthai[data.sex]}</h5>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="headcol">
                    <h5>
                      <strong>อายุ</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      <h5>{data.age}</h5>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="headcol">
                    <h5>
                      <strong>สัญชาติ</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      <h5>{data.nationality}</h5>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="headcol">
                    <h5>
                      <strong>เชื้อชาติ</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      <h5>{data.race}</h5>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="headcol">
                    <h5>
                      <strong>ศาสนา</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      <h5>{data.religion}</h5>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="headcol">
                    <h5>
                      <strong>ส่วนสูง</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      <h5>{data.height}</h5>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="headcol">
                    <h5>
                      <strong>น้ำหนัก</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      <h5>{data.weight}</h5>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="headcol">
                    <h5>
                      <strong>เกิดวันที่</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      <h5>{DateUtil.convertEnToTh(data.birthdate)}</h5>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="headcol">
                    <h5>
                      <strong>ที่อยู่ปัจจุบัน</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      <h5>บ้านเลขที่: {data.address}</h5>
                      <h5>ตําบล/แขวง: {data.tumbon}</h5>
                      <h5>อําเภอ/เขต: {data.amphoe}</h5>
                      <h5>จังหวัด: {data.province}</h5>
                      <h5>รหัสไปรษณีย์: {data.postcode}</h5>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="headcol">
                    <h5>
                      <strong>Email</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      <h5>{data.email}</h5>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="headcol">
                    <h5>
                      <strong>การรับราชการทหาร</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      <h5>{data.military}</h5>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="headcol">
                    <h5>
                      <strong>โทรศัพท์</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      <h5>{data.phone}</h5>
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="headcol">
                    <h5>
                      <strong>สถานะ</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      <h5>{arrthai[data.status]}</h5>
                    </td>
                  ))}
                </tr>

                <tr>
                  <th className="headcol">
                    <h5>
                      <strong>สถานศึกษา</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      {Object.values(data.education).map((items, key) => (
                        <>
                          {items.name !== undefined && (
                            <>
                              <h5>ชื่อสถานศึกษา: {items.name}</h5>
                              <h5>สาขา: {items.faculty}</h5>
                              <h5>เริ่ม: {items.startSchoolYear}</h5>
                              <h5>จบ: {items.endSchoolYear}</h5>
                              <h5>gpa: {items.gpa}</h5>
                            </>
                          )}
                          <br />
                        </>
                      ))}
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="headcol">
                    <h5>
                      <strong>ประวัติการทำงาน</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      {data.company.map((items, key) => (
                        <>
                          <h5>ชื่อบริษัท: {items.company}</h5>
                          <h5>ตำแหน่ง: {items.position}</h5>

                          <h5>รายละเอียดงาน: {items.responsibility}</h5>
                          <h5>เหตุผลที่ออก: {items.reason}</h5>

                          <h5>
                            จาก: {DateUtil.convertEnToTh(items.startwork)}
                          </h5>
                          <h5>ถึง: {DateUtil.convertEnToTh(items.endwork)}</h5>

                          <h5>เงินเดือน: {items.salary}</h5>

                          <br />
                        </>
                      ))}
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="headcol">
                    <h5>
                      <strong>ความสามารถพิเศษ</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      {data.language.map((items, key) => (
                        <>
                          <h5>ภาษา: {items.lang}</h5>
                          <h5>พูด: {arrthai[items.speak]}</h5>
                          <h5>อ่าน: {arrthai[items.read]}</h5>
                          <h5>เขียน: {arrthai[items.write]}</h5>
                          <h5>เข้าใจ: {arrthai[items.understand]}</h5>

                          <br />
                        </>
                      ))}
                    </td>
                  ))}
                </tr>
                <tr>
                  <th className="headcol">
                    <h5>
                      <strong>ประวัติการอบรม</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      {data.training.map((items, key) => (
                        <h5>{items.description}</h5>
                      ))}
                    </td>
                  ))}
                </tr>

                <tr>
                  <th className="headcol">
                    <h5>
                      <strong>ยกเลิกเปรียบเทียบ</strong>
                    </h5>
                  </th>
                  {compare.map((data, index) => (
                    <td className="long">
                      <h5>
                        <Link onClick={() => deleteCompare(index)}>
                          <i className="fa fa-window-close" aria-hidden="true"></i>
                        </Link>
                      </h5>
                    </td>
                  ))}
                </tr>
              </table>
            </div>
            <Button
              onClick={() => cleardataCompare()}
              outline
              color="secondary"
            >
              เคลียร์ข้อมูล
            </Button>{' '}
          </Container>
        )}

        <div className="card-body">
          <br />
          {/* <Button href={"/#"} color="primary" className="custom-rigth btn btn-primary btn-sm" >
                            เพิ่มข้อมูลผู้สมัคร
                        </Button>{' '} */}
          <BootstrapTable data={data} className="table table-striped">
            <TableHeaderColumn
              ref={inputRef}
              dataAlign="center"
              dataField="count"
              width="100"
            >
              ลำดับ
            </TableHeaderColumn>
            <TableHeaderColumn ref={inputRef} dataField="name" width="150">
              ชื่อผู้สมัคร
            </TableHeaderColumn>
            <TableHeaderColumn dataField={'gender'} width="150">
              เพศ
            </TableHeaderColumn>
            <TableHeaderColumn dataField="position" width="150">
              ตำแหน่งที่สมัคร
            </TableHeaderColumn>
            <TableHeaderColumn dataField="salary" width="150">
              เงินเดือนที่ต้องการ
            </TableHeaderColumn>
            <TableHeaderColumn dataField="createDate" width="150">
              วันที่สมัคร
            </TableHeaderColumn>
            <TableHeaderColumn dataField="printStatus" width="150">
              สถานะพิมพ์ใบสมัคร
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="_id"
              width="150"
              headerAlign="left"
              dataAlign="center"
              isKey
              dataFormat={selectCandidate}
            >
              ดูข้อมูลผู้สมัคร
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField={'stepStatus'} width="150"
            >
              กรอกข้อมูล
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="formStatus"
              width="150"
              dataFormat={formatFormStatus}
            >
              สถานะการสมัคร
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="_id"
              width="150"
              headerAlign="left"
              dataAlign="center"
              dataFormat={CompareButton}
            >
              เปรียบเทียบผู้สมัคร
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="_id"
              width="150"
              headerAlign="left"
              dataAlign="center"
              dataFormat={selectEdit}
            >
              แก้ไขข้อมูล
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="editor"
              width="150"
              headerAlign="left"
            >
              ผู้แก้ไขล่าสุด
            </TableHeaderColumn>
          </BootstrapTable>
          {data.length !== 0 && (
            <Pagination
              activePage={activePage}
              onPageChange={pagination}
              totalPages={allPage}
              ellipsisItem={null}
            />
          )}
        </div>
      </div>
    </>
  )
}
export default List
