import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  Input,
  Col,
  Label,
  Form,
  FormGroup,
  Container,
  Row,
  Card,
  CardTitle,
  CardText,
  Nav,
  CardImg,
  CardBody,
  CardHeader,
  CardColumns,
  CardGroup,
  CardSubtitle,
} from 'reactstrap'
import Axios from 'axios'
import endpoint from '../../config/config-api'
import ActivitiesSection from './widget/activitiesSection'
import TestimonialSection from './widget/testimonialSection'
import InternshipSection from './widget/intermshipSection'
const ReactBsTable = require('react-bootstrap-table')
const BootstrapTable = ReactBsTable.BootstrapTable
const TableHeaderColumn = ReactBsTable.TableHeaderColumn
const axios = Axios.create({ withCredentials: true })

const EditHomePage = (props) => {
  return (
    <div className="card-body">
      {/* <Row>
        <div className="card-body">
          <Card>
            <CardHeader>Activities</CardHeader>
            <Nav>
              <div className="mb-6" style={{ margin: '20px' }}>
                <div className="  py-3">
                  <Button
                    onClick={() =>
                      props.history.push('/EditHomePage/ActivitiesForm')
                    }
                    // href={'/panorama/form'}
                    color="primary"
                    className="custom-rigth btn btn-primary btn-sm"
                  >
                    เพิ่มข้อมูลส่วน Activities
                  </Button>
                </div>
              </div>
            </Nav>
            <CardBody>
              <Row>
                <Col xs="2">
                  <FormGroup>
                    <Label for="exampleSelect">ปี</Label>
                    <Input
                      type="select"
                      name="selectYear"
                      id="selectYear"
                      value={selectYear}
                      onChange={handleSelectChangeYear}
                    >
                      {Array.from({
                        length: new Date().getFullYear() - 2022 + 1,
                      }).map((_, index) => (
                        <option value={new Date().getFullYear() - index}>
                          {new Date().getFullYear() - index}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>

                <Col xs="10">
                  <CardBody>
                    <BootstrapTable
                      data={data}
                      pagination={true}
                      className="table table-striped"
                    >
                      <TableHeaderColumn
                        dataField="imageSrc"
                        dataFormat={imageFormatter}
                        width="50"
                      >
                        ภาพปก
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField="title"
                        dataFormat={titleFormatter}
                        width="100"
                      >
                        ชื่อหัวข้อ
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        dataField="title"
                        dataFormat={titleFormatter}
                        width="150"
                      >
                        รายละเอียด
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField="_id"
                        width="50"
                        dataFormat={titleFormatter}
                      >
                        ดูแกลลอรี่
                      </TableHeaderColumn>
                      <TableHeaderColumn
                        dataField="_id"
                        width="50"
                        dataFormat={titleFormatter}
                      >
                        แก้ไข
                      </TableHeaderColumn>

                      <TableHeaderColumn
                        dataField="_id"
                        width="50"
                        // dataFormat={deletebutton}
                      >
                        Delete
                      </TableHeaderColumn>
                    </BootstrapTable>
                  </CardBody>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Row> */}
      <ActivitiesSection props={props} />
      <TestimonialSection props={props} />
      <InternshipSection props={props} />
    </div>
  )
}

export default EditHomePage
