const httpSecure = !!!process.browser ? 'http://' : 'https://'

const config = {
  server: `${httpSecure}`,
  apiTest: `${process.env.REACT_APP_API_TEST}`,
  webUrl: `${process.env.REACT_APP_WEB_URL}`,
  apiProHrs: `${process.env.REACT_APP_API_HR}`,
  apiImageHost: `${process.env.REACT_APP_IMAGE_HOST_URL}`,
  // apiTest :  `http://localhost:4000`,
  // ใช้ใน local `http://localhost:4000`
}
 
module.exports = Object.assign(config)
