import React from 'react'
import { Route, BrowserRouter, HashRouter } from 'react-router-dom'
import Routing from './route/routing'

import Sidebar from './component/layouts/Sidebar'
import Topbar from './component/layouts/Topbar'
import Footer from './component/layouts/Footer'
import './assets/css/main.min.css'
import './assets/css/main.css'
import './assets/css/table-style.css'
import './assets/css/table-compare.css'
import './assets/css/demo-wrapper.css'
import 'react-multi-email/style.css'
import User from '../src/lib/admin'
import Login from '../src/pages/Login/Login'

export default function App() {
  const user = User.getCookieData()
  if (user === false) {
    return (
      <HashRouter>
        <Login />
        <Route exact path="/login" component={Login} />
      </HashRouter>
    )
  } else {
    return (
      <HashRouter>
        <div
          id="wrapper"
          style={{
            maxWidth: '100vw',
            boxSizing: 'border-box',
          }}
        >
          <Sidebar />
          <div id="content-wrapper" component="d-flex flex-column">
            <div id="content">
              <Topbar />
              <div className="container-fluid">
                <Routing />
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </HashRouter>
    )
  }
}
