import axios from 'axios'
import endpoint from '../../config/config-api'

export const staticPageController = () => {
  const url = `${endpoint.apiTest}/v1.1`
  const config = {
    withCredentials: true,
  }

  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    return error.response;
  });

  return {
    getAll: () => {
      return axios.get(`${url}/static-page/all?v=` +
      Math.random() * 1000, config)
    },
    getById: (id) => {
      return axios.get(`${url}/static-page/${id}`, config)
    },
    create: (body) => {
      return axios.post(`${url}/static-page`, body, config)
    },
    updateById: (id, body) => {
      return axios.put(`${url}/static-page/${id}`, body, config)
    }
  }
}
