import React, { useRef, useEffect, useState } from 'react'
import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css'
import {
  Alert,
  Button,
  Input,
  Col,
  Label,
  Form,
  FormGroup,
  Container,
  Row,
} from 'reactstrap'
import endpoint from '../../config/config-api'
import Axios from 'axios'
import Swal from 'sweetalert2'
import { Gallery } from 'react-grid-gallery'

const axios = Axios.create({ withCredentials: true })
const FormHomaPage = (props) => {
  const [data, setData] = useState({
    year: new Date().getFullYear(),
    title: '',
    description: '',
    imageSrc: undefined,
    file: undefined,
    imageGallery: [],
  })

  const [sizeImage, setSizeImage] = useState({ width: 0, height: 0 })

  const [validate, setValidate] = useState(undefined)
  const [id] = useState(props.match.params.id)
  const [imageGallery, setImageGallery] = useState([])
  const [imageGalleryShow, setImageGalleryShow] = useState([])

  useEffect(() => {
    fetchData()
  }, [id])

  const fetchData = async () => {
    if (id) {
      const response = await axios.get(
        `${endpoint.apiTest}/v1.1/job/cms/activities/${id}`,
      )
      if (response.status === 200) {
        const newData = response.data.data

        setData(newData[0])

        let arr = []
        newData[0].imageGallery.map((image) => {
          arr.push({
            image_id: image.image_id,
            image_name: image.image_name,
            src: image.url,
            mimetype: image.mimetype,
            width: image.width,
            height: image.height,
          })
        })
        setImageGalleryShow(arr)
        setImageGallery(newData[0].imageGallery)
      }
    } else {
      setData(data)
    }
  }

  const setValue = (name) => (event) => {
    switch (name) {
      case 'year':
        setData({
          ...data,
          year: event.target.value,
        })

        break
      case 'title':
        setData({
          ...data,
          title: event.target.value,
        })

        break

      case 'description':
        setData({
          ...data,
          description: event.target.value,
        })

        break

      case 'file':
        let { type, size } =
          event.target.files[0] !== undefined && event.target.files[0]
        ;(async (value = event.target.files[0]) => {
          const check = await checkSizeImage(event.target.files[0])
          if (!check) {
            Swal.fire({
              icon: 'error',
              title: 'ขนาดรูปภาพเกิน 1280 x 1200',
              text: '  กรุณาเลือกรูปภาพใหม่',
            })
            setValidate({
              ...validate,
              file: 'กรุณาแนบไฟล์ตามขนาดที่กำหนด',
            })
          } else {
            if (['image/jpg', 'image/jpeg', 'image/png'].includes(type)) {
              setData({
                ...data,
                imageSrc: value.name,
                file: value,
              })
              setValidate({
                ...validate,
                file: '',
              })
            } else if (data.file !== undefined) {
              setData({
                ...data,
                imageSrc: data.imageSrc,
                file: '',
              })
              setValidate({
                ...validate,
                file: '',
              })
            } else {
              setValidate({
                ...validate,
                file: 'กรุณาแนบไฟล์เป็น .jpg .jpeg .png เท่านั้น',
              })
            }
          }
        })()
        break

      case 'imageGallery':
        let gallery =
          event.target.files[0] !== undefined && event.target.files[0]

        if (gallery) {
          if (
            data.imageGallery.length + event.target.files.length > 20 ||
            imageGalleryShow.length + event.target.files.length > 20
          ) {
            alert('จำนวนรูปเกิน 20 รูป')
            return
          }
          ;(async (value = event.target.files) => {
            const check = await checkSizeImage(gallery)
            // if (!check) {
            //   Swal.fire({
            //     icon: 'error',
            //     title: 'ขนาดรูปภาพเกิน 1280 x 720',
            //     text: '  กรุณาเลือกรูปภาพใหม่',
            //   })
            // } else {
            if (
              ['image/jpg', 'image/jpeg', 'image/png'].includes(gallery.type)
            ) {
              // setData({
              //   ...data,
              //   imageGallery: [...imageGallery, ...value],
              // })
              uploadImage([...imageGallery, ...value])
              setValidate({
                ...validate,
                imageGallery: '',
              })
            } else {
              setValidate({
                ...validate,
                imageGallery: 'กรุณาแนบไฟล์เป็น .jpg .jpeg .png เท่านั้น',
              })
            }
            // }
          })()
        }
        break

      default:
        break
    }
  }
  const checkSizeImage = (file) => {
    return new Promise((resolve) => {
      const url = URL.createObjectURL(file)
      let img = new Image()

      img.onload = function () {
        if (this.width <= 1280 && this.height <= 1200) {
          resolve(true)
        } else {
          resolve(false)
        }
      }

      img.src = url
    })
  }

  const uploadImage = async (file) => {
    let formdata = new FormData()
    let gallerySrc = []
    let gallerySrcShow = []
    let handleSelect = []
    file.map((item, index) => formdata.append('image_files', item))

    formdata.append('ref_id', Date.now())

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${endpoint.apiImageHost}/image/thairath/upload`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formdata,
    }

    axios
      .request(config)
      .then((response) => {
        response.data.data.items.map((item) => {
          gallerySrc.push({
            image_id: item.image_id,
            image_name: item.image_name,
            url: item.url,
            mimetype: item.mimetype,
            width: item.width,
            height: item.height,
          }) &&
            gallerySrcShow.push({
              image_id: item.image_id,
              image_name: item.image_name,
              src: item.url,
              mimetype: item.mimetype,
              width: item.width,
              height: item.height,
            })
        })

        setData({
          ...data,
          imageGallery: [...data.imageGallery, ...gallerySrc],
          lengthGallery: gallerySrc.length,
        })
        setImageGalleryShow([...imageGalleryShow, ...gallerySrcShow])

        setValidate({
          ...validate,
          lengthGallery: '',
        })
      })
      .catch((error) => {
        console.log(error)
        Swal.fire({
          icon: 'error',
          title: 'upload รูปไม่สำเร็จ',
        }).then((result) => {
          window.location.reload()
        })
      })
  }

  const onCheckValidate = (req) => {
    if (!req.imageSrc) {
      setValidate({
        file: !req.imageSrc && 'กรุณาเลือก',
      })
      return true
    } else if (!req.title) {
      setValidate({
        title: !req.title && 'กรุณากรอกชื่อหัวข้อ',
      })
      return true
    } else if (!req.description) {
      setValidate({
        description: !req.description && 'กรุณากรอกคำอธิบาย',
      })
      return true
    } else if (!req.file && data.imageSrc === undefined) {
      setValidate({
        file: !req.file && 'กรุณาเลือกไฟล์',
      })
      return true
    } else if (!req.lengthGallery && data.imageGallery.length === 0) {
      setValidate({
        lengthGallery: !req.lengthGallery && 'กรุณาเลือกจำนวนรูป',
      })
      return true
    } else {
      setValidate(undefined)
      return false
    }
  }

  const handleSubmit = async () => {
    const result = onCheckValidate(data)

    if (!result) {
      const formData = new FormData()
      const arr = [
        'title',
        'description',
        'imageSrc',
        'file',
        'year',
        'lengthGallery',
      ]

      for (let value of arr) {
        formData.append(value, data[value])
      }
      formData.append('imageGalleryLength', data.imageGallery.length)
      if (data.imageGallery.length > 1) {
        data.imageGallery.map((item, index) => {
          formData.append(`imageGallery`, JSON.stringify(item))
        })
      } else {
        formData.append(`imageGallery`, [JSON.stringify(data.imageGallery[0])])
      }

      if (id) {
        const result = await axios.put(
          `${endpoint.apiTest}/v1.1/job/cms/activities/update/${id}?upload=activities`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )

        if (result.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'บันทึกข้อมูลสำเร็จ',
          }).then((result) => {
            if (result.isConfirmed) {
              props.history.push('/EditHomePage')
            }
          })
        }
      } else {
        const result = await axios.post(
          `${endpoint.apiTest}/v1.1/job/cms/activities/create?upload=activities`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )

        if (result.status === 200) {
          Swal.fire({
            icon: 'success',
            title: 'บันทึกข้อมูลสำเร็จ',
          }).then((result) => {
            if (result.isConfirmed) {
              props.history.push('/EditHomePage')
            }
          })
        }
      }
    }
  }

  return (
    <Container>
      <Form noValidate onSubmit={handleSubmit}>
        <div className="card-body">
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label for="exampleAddress">ปี</Label>
                <Input
                  type="select"
                  name="year"
                  id="year"
                  className={
                    validate && validate.year
                      ? 'is-invalid form-control'
                      : 'form-control'
                  }
                  autoComplete="off"
                  value={data.year}
                  onChange={setValue('year')}
                >
                  {Array.from({ length: new Date().getFullYear() - 2019 }).map(
                    (_, index) => (
                      <option value={new Date().getFullYear() - index}>
                        {new Date().getFullYear() - index}
                      </option>
                    ),
                  )}
                </Input>

                {validate && validate.year && (
                  <Alert color="danger">{validate.year}</Alert>
                )}
              </FormGroup>
            </Col>

            <Col md={8}>
              <FormGroup>
                <Label for="exampleAddress">
                  ภาพปก: ขนาด 1280 x 1200 / นามสกุลไฟล์ .jpg, .png
                  {data ? data.imageSrc : `Example file input`}
                </Label>
                <Input
                  type="file"
                  name="image"
                  id="image"
                  accept="image/jpg, image/jpeg, image/png, video/mp4, video/quicktime"
                  onChange={setValue('file')}
                  style={{ cursor: 'pointer' }}
                />
                {validate && validate.file && (
                  <Alert color="danger">{validate.file}</Alert>
                )}
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label for="exampleAddress">Title</Label>
                <Input
                  type="text"
                  name="title"
                  id="title"
                  className={
                    validate && validate.title
                      ? 'is-invalid form-control'
                      : 'form-control'
                  }
                  value={data.title}
                  onChange={setValue('title')}
                />
                {validate && validate.title && (
                  <Alert color="danger">{validate.title}</Alert>
                )}
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label for="exampleAddress">Description</Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  className={
                    validate && validate.description
                      ? 'is-invalid form-control'
                      : 'form-control'
                  }
                  value={data.description}
                  onChange={setValue('description')}
                />
                {validate && validate.description && (
                  <Alert color="danger">{validate.description}</Alert>
                )}
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label for="exampleAddress">
                  แกลลอรี่: ขนาด 1280 x 1200 / นามสกุลไฟล์ .jpg, .png / Limit 20
                  ภาพ
                  <br />
                  <Gallery images={imageGalleryShow} />
                </Label>
                <Input
                  type="file"
                  multiple
                  name="imageGallery"
                  id="imageGallery"
                  accept="image/jpg, image/jpeg, image/png"
                  onChange={setValue('imageGallery')}
                  style={{ cursor: 'pointer' }}
                />
                {validate && validate.lengthGallery && (
                  <Alert color="danger">{validate.lengthGallery}</Alert>
                )}
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <div className="btn">
              <Button onClick={() => handleSubmit()}>บันทึก</Button>
            </div>

            <div className="btn">
              <Button onClick={() => props.history.push('/EditHomePage')}>
                ย้อนกลับ
              </Button>
            </div>
          </Row>
        </div>
      </Form>
    </Container>
  )
}

export default FormHomaPage
